import moment from 'moment';

// Add constant at the top after imports
export const DESIGNER_METRICS_KEY = 'designer_metrics';

interface DesignerMetrics {
    projects: {
        [projectId: number]: {
            designs: {
                [designId: number]: {
                    timeSpent: number;
                };
            };
        };
    };
}

/**
 * Gets the time spent (in seconds) on a specific design from local storage
 * @param projectId - The ID of the project
 * @param designId - The ID of the design
 * @returns The time spent in seconds, or 0 if no time data exists
 */
export const getDesignTimeSpent = (projectId: number | string, designId: number | string): number => {
    try {
        const metricsString = localStorage.getItem(DESIGNER_METRICS_KEY);
        if (!metricsString) return 0;

        const metrics: DesignerMetrics = JSON.parse(metricsString);

        const timeSpentSeconds = metrics.projects?.[projectId]?.designs?.[designId]?.timeSpent ?? 0;
        return timeSpentSeconds / 60; // Convert seconds to minutes
    } catch (error) {
        console.error('Error reading designer metrics from localStorage:', error);
        return 0;
    }
};

/**
 * Calculates the time (in minutes) between design creation and current time
 * @param designCreatedDate - The creation date of the design
 * @returns The time difference in minutes
 */
export const getTimeToSimulation = (designCreatedDate: moment.Moment): number => {
    try {
        if (!designCreatedDate.isValid()) return 0;
        const now = moment();
        return now.diff(designCreatedDate, 'minutes');
    } catch (error) {
        console.error('Error calculating time to simulation:', error);
        return 0;
    }
};

export function initializeDesignMetricsInLocalStorage(
    projectId: number | string,
    designId: number | string,
): DesignerMetrics {
    // Add type checking for parameters
    if (!projectId || !designId) {
        throw new Error('Project ID and Design ID are required');
    }

    // Safely parse localStorage data with error handling
    let designMetrics: DesignerMetrics;
    try {
        const storedData = localStorage.getItem(DESIGNER_METRICS_KEY);
        designMetrics = storedData ? JSON.parse(storedData) : { projects: {} };
    } catch (error) {
        // Reset to default if parsing fails
        designMetrics = { projects: {} };
    }

    // Ensure the projects object exists
    designMetrics.projects = designMetrics.projects || {};

    // Initialize project if it doesn't exist
    if (!designMetrics.projects[projectId]) {
        designMetrics.projects[projectId] = {
            designs: {},
        };
    }

    // Initialize design if it doesn't exist
    if (!designMetrics.projects[projectId].designs[designId]) {
        designMetrics.projects[projectId].designs[designId] = {
            timeSpent: 0,
        };
    }

    // Save back to localStorage
    localStorage.setItem(DESIGNER_METRICS_KEY, JSON.stringify(designMetrics));

    return designMetrics;
}

/**
 * Updates the time spent on a design in localStorage
 * @param projectId - The ID of the project
 * @param designId - The ID of the design
 * @param timeSpentSeconds - The time spent in seconds to add
 */
export function updateDesignTimeInLocalStorage(
    projectId: number | string,
    designId: number | string,
    timeSpentSeconds: number,
): void {
    try {
        const metricsString = localStorage.getItem(DESIGNER_METRICS_KEY);
        if (!metricsString) return;

        const metrics: DesignerMetrics = JSON.parse(metricsString);

        // Only update if project and design already exist
        if (metrics.projects?.[projectId]?.designs?.[designId]) {
            const currentTimeSpent = metrics.projects[projectId].designs[designId].timeSpent;
            metrics.projects[projectId].designs[designId].timeSpent = Number(
                (currentTimeSpent + timeSpentSeconds).toFixed(2),
            );
            localStorage.setItem(DESIGNER_METRICS_KEY, JSON.stringify(metrics));
        }
    } catch (error) {
        console.error('Error updating design time in localStorage:', error);
    }
}
