import _ from 'lodash';
import * as THREE from 'three';

import {
    removeInstance,
    makeRawGeometry,
    makeShaderMaterialInstance,
    applySolidInstanceStandardOptions,
    applyWireInstanceStandardOptions,
    makeTextTextureGeometry,
} from './GLHelpers';

class PrimitiveBase {
    constructor(glRenderer) {
        this.glRenderer = glRenderer;
        this.renderOptions = {};
    }

    clearInstances() {
        if (this.glInstance) {
            this.glInstance = removeInstance(this.glInstance);
        }

        if (this.renderOptions.texture && this.renderOptions.texture._owned) {
            this.renderOptions.texture.dispose();
        }
    }

    setRenderOptions() {
        throw new Error('not implemented');
    }

    makeInstances() {
        throw new Error('not implemented');
    }

    applyInteractData(interactData) {
        this.glInstance.userData = { interactData };
        if (!interactData || interactData.noRaycast) {
            this.glInstance.userData = { noRaycast: true };
        }
    }

    getInteractData() {
        if (this.glInstance && this.glInstance.userData) {
            return this.glInstance.userData.interactData;
        }

        return null;
    }

    setRenderScale(scale) {
        if (this.glInstance) {
            this.glInstance.scale.copy(scale);
            this.glInstance.updateMatrixWorld(true);
        }
    }

    setRenderRotation(rotation) {
        if (this.glInstance) {
            this.glInstance.rotation.copy(rotation);
            this.glInstance.updateMatrixWorld(true);
        }
    }

    setRenderPosition(position) {
        if (this.glInstance) {
            this.glInstance.position.copy(position);
            this.glInstance.updateMatrixWorld(true);
        }
    }

    worldMatrix() {
        if (this.glInstance) {
            return this.glInstance.matrixWorld;
        }

        return null;
    }

    getCamera(customCamera, isScreenSpace) {
        if (customCamera) {
            return customCamera;
        }

        if (isScreenSpace) {
            return this.glRenderer.screenSpaceCamera;
        }

        return null;
    }
}

const MESH_DEFAULT_OPTIONS = {
    fillColor: '#ffffff',
    fillOpacity: 1.0,
    strokeColor: '#ffffff',
    strokeOpacity: 1.0,
    opacity: 1.0,
    alpha: 1.0,
    depthOffset: 0.0,
};

export class PrimitiveMeshStroke extends PrimitiveBase {
    setRenderOptions(options) {
        if (this.glInstance) {
            applyWireInstanceStandardOptions(this.glInstance, options);
            this.glInstance.renderOrder = options.renderOrder || 0;
            this.glInstance.material.transparent = true;
        }

        this.renderOptions = _.assign({}, MESH_DEFAULT_OPTIONS, options);
    }

    makeInstances() {
        const uniforms = {
            color: { value: new THREE.Color(0xdddddd) },
            clientSize: { value: this.glRenderer.clientSize },
            depthOffset: { value: this.renderOptions.depthOffset || 0 },
            weight: { value: 1.0 },
            alpha: { value: 1.0 },
            textureMap: { value: this.renderOptions.texture },
        };

        const camera = this.getCamera(this.renderOptions.customCamera, this.renderOptions.screenSpace);
        this.glRenderer.assignCameraUniforms(uniforms, camera);

        _.assign(uniforms, this.renderOptions.customUniforms || {});

        const instance = makeShaderMaterialInstance(this.renderOptions.geometry, uniforms, this.renderOptions.material);
        this.glInstance = instance;
        this.glInstance.renderOrder = this.renderOptions.renderOrder || 0;
        this.renderOptions.scene.add(instance);

        this.applyInteractData(this.renderOptions.selectionData);
        this.setRenderOptions(this.renderOptions);
    }
}

export class PrimitiveMeshFill extends PrimitiveBase {
    setRenderOptions(options) {
        if (this.glInstance) {
            applySolidInstanceStandardOptions(this.glInstance, options);
            this.glInstance.renderOrder = options.renderOrder || 0;
            this.glInstance.material.transparent = true;
        }

        this.renderOptions = _.assign({}, MESH_DEFAULT_OPTIONS, options);
    }

    makeInstances() {
        const uniforms = {
            lightDir: { value: this.glRenderer.lightDir },
            depthOffset: { value: this.renderOptions.depthOffset || 0 },
            color: { value: new THREE.Color(0xdddddd) },
            alpha: { value: 1.0 },
            textureMap: { value: this.renderOptions.texture },
        };

        const camera = this.getCamera(this.renderOptions.customCamera, this.renderOptions.screenSpace);
        this.glRenderer.assignCameraUniforms(uniforms, camera);

        _.assign(uniforms, this.renderOptions.customUniforms || {});

        this.glInstance = makeShaderMaterialInstance(
            this.renderOptions.geometry,
            uniforms,
            this.renderOptions.material,
        );
        this.glInstance.position.set(0, 0, 0);
        this.glInstance.renderOrder = this.renderOptions.renderOrder || 0;
        this.renderOptions.scene.add(this.glInstance);

        this.applyInteractData(this.renderOptions.selectionData);
        this.setRenderOptions(this.renderOptions);
    }
}

export class PrimitiveVirtualLineSegments extends PrimitiveBase {
    // hacky, exclusively for hit testing, should perhaps revisit
    setRenderOptions(options) {
        this.renderOptions = _.assign({}, options);
    }

    makeInstances() {
        const geometry = makeRawGeometry(this.renderOptions.points);
        this.glInstance = new THREE.LineSegments(geometry, this.renderOptions.material);
        this.glInstance.renderOrder = this.renderOptions.renderOrder || 0;
        this.renderOptions.scene.add(this.glInstance);

        this.applyInteractData(this.renderOptions.selectionData);
        this.setRenderOptions(this.renderOptions);
    }
}

const DEFAULT_TEXT_OPTIONS = {
    fillColor: '#ffffff',
    fillOpacity: 1.0,
    opacity: 1.0,
    scale: 1.0,
};

export class PrimitiveTextTexture extends PrimitiveBase {
    setRenderOptions(options) {
        if (this.glInstance) {
            applySolidInstanceStandardOptions(this.glInstance, options);
            this.glInstance.renderOrder = options.renderOrder || 0;
            this.glInstance.material.transparent = true;
        }

        this.renderOptions = _.assign({}, DEFAULT_TEXT_OPTIONS, options);
        this.selectionData = this.renderOptions.selectionData;
    }

    makeInstances() {
        const geometry = makeTextTextureGeometry(
            this.renderOptions.text,
            this.renderOptions.font,
            this.renderOptions.texture,
            this.renderOptions.scale || 1.0,
        );

        this.boundingBox = geometry.boundingBox;

        const uniforms = {
            color: { value: new THREE.Color(0xffffff) },
            depthOffset: { value: this.glRenderer.tinyZOffset },
            textureMap: { value: this.renderOptions.texture },
            alpha: { value: 1.0 },
        };

        const camera = this.getCamera(this.renderOptions.customCamera, this.renderOptions.screenSpace);
        this.glRenderer.assignCameraUniforms(uniforms, camera);

        _.assign(uniforms, this.renderOptions.customUniforms || {});

        this.glInstance = makeShaderMaterialInstance(
            geometry,
            uniforms,
            this.glRenderer.inlineShaderMaterial('vertexShaderFontTexture', 'fragmentShaderFontTexture'),
        );
        this.glInstance.scale.set(1, -1, 1);
        this.glInstance.renderOrder = this.renderOptions.renderOrder || 0;
        this.renderOptions.scene.add(this.glInstance);

        this.applyInteractData(this.renderOptions.selectionData);
        this.setRenderOptions(this.renderOptions);
    }
}
