/* global angular:true, _:true */
import _ from 'lodash';

import { initializeDesignMetricsInLocalStorage } from 'reports/modules/project/utils/designerMetrics';

import * as analytics from 'helioscope/app/utilities/analytics';
import { $rootScope } from 'helioscope/app/utilities/ng';

const mod = angular.module('helioscope.projects.controllers',
    ['helioscope.projects.controllers.scenarios',
        'helioscope.projects.controllers.sketchup',
        'helioscope.projects.controllers.reports',
        'helioscope.projects.controllers.designs',
        'helioscope.services',
        'helioscope.projects.services',
        'ui.bootstrap',
        'ngCookies']);

mod.controller('ProjectCtrl', ['$scope', 'Messager', '$log', function ($scope, messager, $log) {
    $scope.update = function (project, items) {
        angular.extend(project, items);

        const notification = messager.load('Updating project status');
        project.$update(() => {
            notification.success('Successfully updated project');
        }, (resp) => {
            notification.error('Could not updated project');
            $log.warn(resp);
        });
    };
}]);

mod.controller('RecentProjectCtrl', ($scope, Project, $log, Messager, $cookies) => {
    $scope.searchOptions = {
        query: undefined,
        showSharedProjects: ($cookies.getObject('showSharedProjects') === undefined ? true : $cookies.getObject('showSharedProjects')),
        showArchivedProjects: false,
    };

    $scope.projects = [];

    function queryProjects() {
        $scope.querying = true;
        const filter = {
            q: $scope.searchOptions.query,
            include_archived: $scope.searchOptions.showArchivedProjects,
        };

        if (!$scope.searchOptions.showSharedProjects) {
            filter.creator_id = $scope.user().user_id;
        }

        return Project.query(angular.extend({ limit: 25 }, filter)).$promise.then(
            (projects) => {
                $scope.projects = projects;
            }
        ).finally(() => {
            $scope.querying = false;
        });
    }

    const debounceQuery = _.debounce(queryProjects, 300);
    queryProjects();

    $scope.$watch('searchOptions.query', (newVal, oldVal) => {
        if (newVal !== undefined && newVal !== oldVal) {
            $scope.querying = true;
            debounceQuery();
        }
    });

    $scope.$watchGroup(['searchOptions.showSharedProjects', 'searchOptions.showArchivedProjects'],
        (newVal, oldVal) => {
            if (newVal !== undefined && newVal !== oldVal) {
                $cookies.putObject('showSharedProjects', newVal);
                $scope.querying = true;
                debounceQuery();
            }
        }
    );

    $scope.update = function (project, items, $event) {
        angular.extend(project, items);

        const notification = Messager.load('Updating project status');
        project.$update(() => {
            notification.success('Successfully updated project');
        }, (resp) => {
            notification.error('Could not update project');
            $log.warn(resp);
        });

        // stop icon updates from propagating to select the project
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        if ($event.preventDefault) {
            $event.preventDefault();
        }
        $event.cancelBubble = true;
        $event.returnValue = false;
    };
});

mod.controller('ProjectListCtrl', ['$scope', '$location', 'projects', 'newProjectDlg', function ($scope, $location, projects, newProjectDlg) {
    $scope.projects = projects;
    $scope.newProject = () => newProjectDlg.openDialog($scope);

    $scope.detailedList = true;
    $scope.pageLength = 10;
    $scope.currentPage = parseInt(($location.search()).page, 10) || 1;
    $scope.filteredProjects = [];

    $scope.totalItems = function () {
        return $scope.filteredProjects.length;
    };

    $scope.searchOptions = {
        query: '',
        favorited: true,
        archived: false,
    };

    function filterProjects(filter) {
        const filtered = [];
        const query = filter.query.toLowerCase();

        angular.forEach($scope.projects, (project) => {
            if (!(!filter.favorited && project.favorited) &&
                    !(!filter.archived && project.archived) &&
                    (!filter.query.length || (project.name.toLowerCase().indexOf(query) !== -1 ||
                                                project.description.toLowerCase().indexOf(query) !== -1 ||
                                                project.address.toLowerCase().indexOf(query) !== -1))) {
                filtered.push(project);
            }
        });

        return filtered;
    }

    $scope.$watch('searchOptions', () => {
        $scope.filteredProjects = filterProjects($scope.searchOptions);
    }, true);


    $scope.setPage = function () {
        $location.search('page', $scope.currentPage);
    };

    $scope.setPage($location.search('page') !== undefined ? $location.search('page') : 1);
}]);

mod.controller('ProjectDetailCtrl', ['$scope', 'project', 'EditProjectDlg', 'Design', 'newDesignDlg', 'Messager', 'Scenario', 'pusher', 'Simulation', '$log', function ($scope, project, editProjectDlg, Design, newDesignDlg, messager, Scenario, pusher, Simulation, $log) {
    $scope.project = project;
    $scope.editProject = editProjectDlg(project);
    $scope.projectData = {};


    $scope.betaPage = `projects/${project.project_id}`;

    $scope.newDesign = function () {
        newDesignDlg($scope.project).result.then(
            (design) => {
                if (design !== undefined) {
                    $scope.projectData.designs.push(design);
                    initializeDesignMetricsInLocalStorage($scope.project.project_id, design.design_id);
                    analytics.track('design.new', {
                        location: {
                            latitude: project.location.latitude,
                            longitude: project.location.longitude,
                        },
                        project_id: $scope.project.project_id,
                        design_id: design.design_id,
                        team_id: $rootScope.user().team_id,
                    });
                }
            }
        );
    };

    $scope.deleteDesign = function (design) {
        if ($scope.projectData.designs.indexOf(design) === -1) {
            messager.info('Could not delete design');
            return;
        }

        const notification = messager.load(`Deleting design ${design.description}`);

        design.to_delete = true;
        design.$update(() => {
            notification.success('Flagged this design for deletion. Design will be deleted permanently in 5 minutes.');
        }, (response) => {
            notification.error(`Could not delete ${design.description}`);
            $log.warn(response);
        });
    };

    $scope.deleteScenario = function (scenario) {
        if ($scope.projectData.scenarios.indexOf(scenario) === -1) {
            messager.info('Could not delete Condition Set');
            return;
        }

        const notification = messager.load(`Deleting condition set ${scenario.description}`);
        scenario.$delete(() => {
            notification.success('Successfully deleted condition set');
            const index = $scope.projectData.scenarios.indexOf(scenario);
            $scope.projectData.scenarios.splice(index, 1);
        }, (response) => {
            notification.error(`Could not delete ${scenario.description}`);
            $log.warn(response);
        });
    };

    $scope.removeSimulation = (simulationId) => {
        $scope.projectData.simulations = _.filter(
            $scope.projectData.simulations, ({ simulation_id }) => simulation_id !== simulationId);
    };

    $scope.getSimulation = function (designId, scenarioId) {
        let sim = _.find($scope.projectData.simulations, (s) => s.design_id === designId && s.scenario_id === scenarioId);

        if (!sim) {
            sim = new Simulation({ design_id: designId, scenario_id: scenarioId, status: 'empty' });

            if ($scope.projectData.simulations) {
                $scope.projectData.simulations.push(sim);
            }
        }
        return sim;
    };

    const refreshSimulations = async () => {
        Simulation.query({ project_id: project.project_id }).$promise.then((fetchedSims) => {
            for (const fetchedSim of fetchedSims) {
                const oldSim = $scope.getSimulation(fetchedSim.design_id, fetchedSim.scenario_id);
                const justCompleted = oldSim.status !== 'completed' && fetchedSim.status === 'completed';

                $.extend(true, oldSim, fetchedSim);

                if (justCompleted && fetchedSim.design) {
                    messager.success(`Simulation completed for Design: ${fetchedSim.design.description}`);
                }
            }
        });
    };

    const channel = pusher(`project@${project.project_id}`);
    let progress;

    // Initialize simulation data
    Simulation.query({project_id: $scope.project.project_id}).$promise.then((simulations) => {
        $scope.projectData.simulations = [];

        for (const simulation of simulations) {
            $.extend(true, $scope.getSimulation(simulation.design_id, simulation.scenario_id), simulation);
        };

        // Watch for simulation progress updates once we have the initial data
        progress = channel.watch('progress', _.debounce(refreshSimulations, 500));
    });

    const deleteDesign = channel.watch('deleteDesign', (data) => {
        $log.log('Received design delete confirmation');

        const design = Design.cached(data.design_id);
        const index = $scope.projectData.designs.indexOf(design);
        design.$deregister();

        if (index === -1) {
            return;
        }

        $scope.projectData.designs.splice(index, 1);
    });

    const designRenderComplete = channel.watch('render.complete', (data) => {
        $log.log(`Received design render success ${data.design_id}`);

        const design = Design.cached(data.design_id);
        if (!design) return;

        design.render_url = data.render_url;
        design.currently_rendering = false;
    });

    const designRenderFail = channel.watch('render.error', (data) => {
        $log.log(`Received design render error ${data.design_id}`);

        const design = Design.cached(data.design_id);
        if (!design) return;

        design.render_url = null;
        design.currently_rendering = false;
    });


    const initialScenario = channel.watch('createdInitialScenario', (data) => {
        $log.log('Received notice of initial scenario creation');
        const scenarios = $scope.projectData.scenarios;
        if (scenarios != null && scenarios.every(s => s.scenario_id !== data.scenario.scenario_id)) {
            scenarios.push(new Scenario(data.scenario));
        }
    });

    $scope.$on('$destroy', () => {
        if (progress) {
            // Unsubscribe from simulation progress updates
            progress();
        }
        deleteDesign();
        initialScenario();
        designRenderComplete();
        designRenderFail();
        channel.unsubscribe();
    });
}]);

mod.controller('ProjectUsersCtrl', ['$scope', 'Messager', 'ShareProjectDlg', '$log', function ($scope, messager, shareProjectDlg, $log) {
    $scope.removeUser = function (user) {
        $scope.project.$removeUser({ project_id: $scope.project.project_id, user_id: user.user_id }).then(
            () => {
                messager.info('User removed successfully');
                const idx = $scope.project.users.indexOf(user);
                if (idx !== -1) {
                    $scope.project.users.splice(idx, 1);
                }
            },
            (response) => {
                $log.warn(response);
                let extra;
                if (response.data.project_user) {
                    extra = `: ${response.data.project_user[0]}`;
                }
                messager.error(`Could not remove this user${extra}`);
            }
        );
    };

    $scope.shareProject = shareProjectDlg($scope.project);
    $scope.teamHasProjectSharing = $scope.user().team.share_projects;
}]);


mod.controller('ProjectShareCtrl', ['$scope', 'project', function ($scope, project) {
    $scope.project = project;
}]);


mod.controller('ProjectAddUserCtrl', ($scope, Messager, $log) => {
    $scope.$watch('searchUserId', userId => {
        if (userId) {
            if (_.find($scope.project.users, (u) => u.user_id === userId)) {
                Messager.info('That already has permissions on this project');
                return;
            }

            $scope.project.$addUser({ project_id: $scope.project.project_id, user_id: userId }).then(() => {
                Messager.success('User Added successfully');
            }, (response) => {
                $log.warn(response);
                let extra = '';
                if (response.data.error) {
                    extra = `: ${response.data.error}`;
                }
                Messager.error(`Could not add this user${extra}`);
            });
        }

        $scope.searchUserId = null;
    });
});
