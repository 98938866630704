/* eslint camelcase: 0 */

import _ from 'lodash';
import * as THREE from 'three';
import Logger from 'js-logger';

import * as analytics from 'helioscope/app/utilities/analytics';
import { PubSub } from 'helioscope/app/designer/events';
import { Vector, Bounds } from 'helioscope/app/utilities/geometry';
import { loadImage } from 'helioscope/app/utilities/io';
import { $http } from 'helioscope/app/utilities/ng';
import { makeChannel } from 'helioscope/app/utilities/pusher';

import { AABB2 } from '../utilities/geometry/geo2';
import { makePointGeometry, makeTriangleListGeometrySolid, makeTextureFromImage } from './GLHelpers';
import { PrimitiveMeshStroke, PrimitiveMeshFill } from './Primitives';

const logger = Logger.get('LidarHelper');

export const SOURCES = {
    AURORA_LIDAR: 'au_lidar',
    SUNROOF: 'au_sunroof',
};

export const SOURCE_CFG = {
    [SOURCES.AURORA_LIDAR]: {
        endpointName: 'lidar',
        fullName: 'Aurora LIDAR',
    },
    [SOURCES.SUNROOF]: {
        endpointName: 'sunroof',
        fullName: 'Google Sunroof',
    },
};

export const LOAD_STATUSES = {
    SUCCESS: 'success',
    SUCCESS_CLIPPED: 'success_clipped',
    FAILURE_SWITCH_SOURCE: 'failure_switch_source',
    FAILURE: 'failure',
};

export const LOAD_TOAST_MESSAGES = {
    [LOAD_STATUSES.SUCCESS]: () => 'LIDAR loaded',
    [LOAD_STATUSES.SUCCESS_CLIPPED]: ({ max }) => `LIDAR data loaded, but exceeded max area of ${max} x ${max}`,
    [LOAD_STATUSES.FAILURE]: () => 'Issue loading LIDAR. Try again later.',
    [LOAD_STATUSES.FAILURE_SWITCH_SOURCE]: ({ dataSourceName }) => `Could not load ${dataSourceName}`,
};

export const DISPLAY_MODES = {
    TRIANGLES: 'triangles',
    TEXTURED: 'textured',
    POINTS: 'points',
};

// Roughly 2 meters at the equator
export const BOUNDS_CHECK_TOLERANCE = 0.00002;

export function boundsContainedByWithinTol(knownBounds, requestedBounds) {
    const minLatContained = requestedBounds.min_lat >= knownBounds.min_lat - BOUNDS_CHECK_TOLERANCE;
    const minLngContained = requestedBounds.min_lng >= knownBounds.min_lng - BOUNDS_CHECK_TOLERANCE;
    const maxLatContained = requestedBounds.max_lat <= knownBounds.max_lat + BOUNDS_CHECK_TOLERANCE;
    const maxLngContained = requestedBounds.max_lng <= knownBounds.max_lng + BOUNDS_CHECK_TOLERANCE;

    return minLatContained && minLngContained && maxLatContained && maxLngContained;
}

function adjustPointsByOffset(points, offset) {
    return points.map((p) => p.clone().add(offset));
}

export class LidarHelper extends PubSub {
    constructor(dRenderer) {
        super();
        this.dRenderer = dRenderer;
        this.visibility = false;
        this.lidarOffsetEditMode = false;
        this.lidarData = null;
        this.sourceAvailability = {
            [SOURCES.AURORA_LIDAR]: null,
            [SOURCES.SUNROOF]: null,
        };
        this.updateData = _.debounce((force) => this._updateData(force), 500);
    }

    hasLidarData() {
        return this.lidarData !== null;
    }

    getLidarLoading() {
        return this.lidarLoading;
    }

    forceRedraw() {
        this.redrawLidar = true;
        this.dRenderer.dirtyFrame();
    }

    setVisibility(visibility) {
        this.visibility = visibility;
        this.publish('visibilitySet', visibility);
        this.forceRedraw();
    }

    getVisibility() {
        return this.visibility;
    }

    lidarTick() {
        if (this.textureRectVal) {
            const { minx, maxx, miny, maxy } = this.dRenderer.tileHelper.coverageRect;
            this.textureRectVal.set(minx, maxx, miny, maxy);
        }

        this.updateData();

        if (this.redrawLidar) {
            this.redrawLidar = false;

            if (this.lidarPrim) {
                this.lidarPrim.clearInstances();
                this.lidarPrim = null;
                this.textureRectVal = null;
            }

            if (this.visibility && this.lidarData && !this.lidarPrim) {
                const { display_mode } = this.dRenderer.design.geometry.lidar_settings;
                if (display_mode === DISPLAY_MODES.TEXTURED) {
                    this.renderTextured();
                } else if (display_mode === DISPLAY_MODES.POINTS) {
                    this.renderPoints();
                } else {
                    this.renderTriangles();
                }

                this.dRenderer.dirtyFrame();
            }
        }
    }

    _updateData(force) {
        // (90 + 10) * 2 = 200m, which is roughly 90th percentile project size
        const AREA_BUFFER = 10.0;
        const MIN_BUFFER = 40.0;
        const DEFAULT_BUFFER = 90.0;

        const projPoints = _.flatten(this.dRenderer.design.zoomPath());

        if (!projPoints.length) {
            projPoints.push(new Vector(-DEFAULT_BUFFER, -DEFAULT_BUFFER));
            projPoints.push(new Vector(DEFAULT_BUFFER, DEFAULT_BUFFER));
        }

        const projBounds = new Bounds(projPoints);
        const offset = this.lidarOffset();

        // If we only have a small keepout or field segment in the design,
        // expand the bounds to a minimum buffer size
        projBounds.expand(
            Math.max(MIN_BUFFER - projBounds.width / 2, 0.0),
            Math.max(MIN_BUFFER - projBounds.height / 2, 0.0),
        );

        projBounds.expand(AREA_BUFFER, AREA_BUFFER);

        projBounds.translate(new Vector(-offset.x, -offset.y));

        if (force || !this.lidarBounds || !this.lidarBounds.equals(projBounds)) {
            this.lidarBounds = projBounds;
            this.loadBounds();
        }
    }

    loadBounds() {
        const dataSource = this.dRenderer.design.geometry.lidar_settings.data_source;
        this.lidarLoading = true;
        this.publish('lidarLoadStart');

        this.loadAsync()
            .then(({ data, clippedToMax }) => {
                this.lidarData = data;
                this.lidarLoading = false;
                this.setSourceAvailability(dataSource, true);
                this.publish('lidarLoadEnd', {
                    success: true,
                    status: clippedToMax ? LOAD_STATUSES.SUCCESS_CLIPPED : LOAD_STATUSES.SUCCESS,
                });
                this.forceRedraw();
            })
            .catch((e) => {
                logger.warn('Error loading LIDAR data');
                logger.warn(e);
                this.attemptSourceSwitch(dataSource);
            });
    }

    setSourceAvailability(dataSource, availability) {
        this.sourceAvailability[dataSource] = availability;
    }

    getSourceAvailability(dataSource) {
        return this.sourceAvailability[dataSource];
    }

    resetSourceAvailabilities() {
        this.sourceAvailability[SOURCES.AURORA_LIDAR] = null;
        this.sourceAvailability[SOURCES.SUNROOF] = null;
    }

    attemptSourceSwitch(dataSource) {
        const otherSource = dataSource === SOURCES.AURORA_LIDAR ? SOURCES.SUNROOF : SOURCES.AURORA_LIDAR;
        if (this.getSourceAvailability(otherSource) === false) {
            this.lidarData = null;
            this.lidarLoading = false;
            this.dRenderer.design.geometry.lidar_settings.data_source = otherSource;
            // Reset in case the user wants to try loading LIDAR again
            this.resetSourceAvailabilities();
            this.publish('autoSourceSwitch', otherSource);
            this.publish('lidarLoadEnd', {
                success: false,
                status: LOAD_STATUSES.FAILURE,
            });
            this.forceRedraw();
        } else {
            this.setSourceAvailability(dataSource, false);
            this.dRenderer.design.geometry.lidar_settings.data_source = otherSource;
            const prevDataSourceName = SOURCE_CFG[dataSource].fullName;
            this.publish('autoSourceSwitch', otherSource);
            this.publish('lidarLoadEnd', {
                success: false,
                status: LOAD_STATUSES.FAILURE_SWITCH_SOURCE,
                dataSourceName: prevDataSourceName,
            });
            this.loadBounds();
        }
    }

    async downloadAndExtractAuroraLidarData(providedDataUrls) {
        // Returns data in the form that LidarHelper expects as its lidarData attribute
        const lidarGeometryURL = providedDataUrls.lidar.resources.geometry.url;

        /* eslint-disable no-undef */
        const geoResp = await fetch(lidarGeometryURL);
        if (geoResp.status === 403) {
            return { error: true, errorType: 'staleJSON' };
        } else if (geoResp.status >= 400) {
            return { error: true, errorType: 'requestError' };
        }
        const geoRespJson = await geoResp.json();
        const image = false;
        const points = this.extractPoints(geoRespJson);

        return { image, points, triangles: geoRespJson.triangles };
    }

    async downloadAndExtractSunroofData(providedDataUrls) {
        const sunroofGeometryURL = providedDataUrls.sunroof.resources.geometry.url;
        const sunroofImageURL = providedDataUrls.sunroof.resources.image.url;

        /* eslint-disable no-undef */
        const geoResp = await fetch(sunroofGeometryURL);
        if (geoResp.status === 403) {
            return { error: true, errorType: 'staleJSON' };
        } else if (geoResp.status >= 400) {
            return { error: true, errorType: 'requestError' };
        }
        const geoRespJson = await geoResp.json();
        const image = await loadImage(sunroofImageURL);
        const points = this.extractPoints(geoRespJson);

        return { image, points, triangles: geoRespJson.triangles };
    }

    extractPoints(geoRespJson) {
        const d = this.dRenderer.toXY({ latitude: geoRespJson.center_lat, longitude: geoRespJson.center_lng });

        const raw = geoRespJson.points;
        const points = new Array(raw.length / 3);
        for (let i = 0; i < points.length; ++i) {
            const j = i * 3;
            points[i] = new THREE.Vector3(raw[j] + d.x, raw[j + 1] + d.y, raw[j + 2]);
        }
        return points;
    }

    fitsAuroraLidarBounds(cachedLidarData, bounds) {
        if (cachedLidarData && cachedLidarData.lidar) {
            return boundsContainedByWithinTol(cachedLidarData.lidar.parameters.bounds, bounds);
        }
        return false;
    }

    fitsSunroofBounds(cachedLidarData, bounds) {
        if (cachedLidarData && cachedLidarData.sunroof) {
            return boundsContainedByWithinTol(cachedLidarData.sunroof.parameters.bounds, bounds);
        }
        return false;
    }

    async fetchLidar(source, location, bounds) {
        const sourceEndpoint = SOURCE_CFG[source].endpointName;
        const sourceName = SOURCE_CFG[source].fullName;
        analytics.track('lidar.fetchLidar', {
            source: sourceName,
            rawWidthMeters: this.lidarBounds.width,
            rawHeightMeters: this.lidarBounds.height,
            project_id: this.dRenderer.design.project_id,
            design_id: this.dRenderer.design.design_id,
            team_id: this.dRenderer.dispatcher.team_id,
        });
        const apiUrl = '/api/lidar/'.concat(sourceEndpoint);

        const asyncResponse = await $http.post(apiUrl, {
            parameters: {
                bounds,
                origin: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                },
                elevation: 0.0,
            },
            design_id: this.dRenderer.design.design_id,
        });
        const asyncRespJson = asyncResponse.data;

        await this.awaitResp(asyncRespJson.channel);
        // Reload the design now that it's been updated on the backend
        await this.dRenderer.design.$update();
    }

    async awaitResp(id) {
        return new Promise((resolve, reject) => {
            this.fetchPusherChannel = makeChannel(id);
            this.fetchPusherChannel.watch('success', (data) => resolve(data));
            this.fetchPusherChannel.watch('failure', () => reject());
        });
    }

    // 400m is chosen to be less than the (as of 10/18/22) max size of a Sunroof request
    // Aurora LIDAR supports up to 1.4km, but we're being conservative here due to browser rendering
    // and download time limitations
    MAX_BOUNDS_SIZE_METERS = 400.0;

    /**
     * Makes square bounds that contain the passed in bounds and are centered on them
     * This is needed because Aurora's Sunroof API expects the bounds to be square.
     * @param {Bounds} projBounds
     * @returns {object} where "bounds" are square bounds and "clippedToMax" is a boolean
     *                   that is true when a dimension of projBounds is greater than MAX_BOUNDS_SIZE_METERS
     */
    toSquareBounds(projBounds) {
        const squareBounds = projBounds.clone();

        const largerDimension = Math.max(projBounds.width, projBounds.height);
        const size = Math.min(this.MAX_BOUNDS_SIZE_METERS, largerDimension);
        const clippedToMax = this.MAX_BOUNDS_SIZE_METERS < largerDimension;
        const midpoint = projBounds.midpoint;
        squareBounds.resetVector2(
            new Vector(midpoint.x - size * 0.5, midpoint.y - size * 0.5),
            new Vector(midpoint.x + size * 0.5, midpoint.y + size * 0.5),
        );

        return { squareBounds, clippedToMax };
    }

    /**
     * Converts from project coordinate space to lat/long coordinate space
     * and reformats into Aurora LIDAR bounds format
     * @param {Bounds} bounds bounds in the project coordinate space
     * @returns An object in the lat/long coordinate space formatted as
     * an Aurora LIDAR bounds object
     */
    toAuroraLatLngBounds(bounds) {
        const { minX, maxX, minY, maxY } = bounds;

        const min = this.dRenderer.toLatLng({ x: minX, y: minY });
        const max = this.dRenderer.toLatLng({ x: maxX, y: maxY });

        return {
            min_lat: min.latitude,
            min_lng: min.longitude,
            max_lat: max.latitude,
            max_lng: max.longitude,
        };
    }

    async loadAsync() {
        const projBounds = this.toAuroraLatLngBounds(this.lidarBounds);
        const { squareBounds: squarifiedProjectBounds, clippedToMax } = this.toSquareBounds(this.lidarBounds);
        const squareBounds = this.toAuroraLatLngBounds(squarifiedProjectBounds);

        const { location } = this.dRenderer.design.project;
        const dataSource = this.dRenderer.design.geometry.lidar_settings.data_source;

        const currLidarData = this.dRenderer.design.lidar_data;

        if (this.fetchPusherChannel) {
            // If a channel already exists, there is an ongoing fetch for a different data source.
            //  We want to ignore the result of this fetch when it finishes.
            this.fetchPusherChannel.unsubscribe();
        }

        // Check to see if we have any lidar data cached for this (source, bounds) combo
        if (dataSource === SOURCES.AURORA_LIDAR) {
            if (
                !this.fitsAuroraLidarBounds(currLidarData, squareBounds) &&
                !this.fitsAuroraLidarBounds(currLidarData, projBounds)
            ) {
                await this.fetchLidar(SOURCES.AURORA_LIDAR, location, squareBounds);
            }
            let extractedData = await this.downloadAndExtractAuroraLidarData(this.dRenderer.design.lidar_data);
            if (extractedData.error) {
                if (extractedData.errorType === 'staleJSON') {
                    await this.fetchLidar(SOURCES.AURORA_LIDAR, location, squareBounds);
                    extractedData = await this.downloadAndExtractAuroraLidarData(this.dRenderer.design.lidar_data);
                    if (extractedData.error) {
                        throw new Error('Failed to refetch LIDAR JSON');
                    }
                } else {
                    throw new Error('Failed to download LIDAR data');
                }
            }
            return {
                data: extractedData,
                clippedToMax,
            };
        } else {
            if (
                !this.fitsSunroofBounds(currLidarData, squareBounds) &&
                !this.fitsSunroofBounds(currLidarData, projBounds)
            ) {
                await this.fetchLidar(SOURCES.SUNROOF, location, squareBounds);
            }
            let extractedData = await this.downloadAndExtractSunroofData(this.dRenderer.design.lidar_data);
            if (extractedData.error) {
                if (extractedData.errorType === 'staleJSON') {
                    await this.fetchLidar(SOURCES.SUNROOF, location, squareBounds);
                    extractedData = await this.downloadAndExtractSunroofData(this.dRenderer.design.lidar_data);
                    if (extractedData.error) {
                        throw new Error('Failed to refetch Sunroof JSON');
                    }
                } else {
                    throw new Error('Failed to download Sunroof data');
                }
            }
            return {
                data: extractedData,
                clippedToMax,
            };
        }
    }

    lidarOffset() {
        const { geometry } = this.dRenderer.design;
        if (geometry.lidar_settings) {
            return new Vector(
                geometry.lidar_settings.lidar_offset_x || 0.0,
                geometry.lidar_settings.lidar_offset_y || 0.0,
                geometry.lidar_settings.lidar_offset_z || 0.0,
            );
        }

        return new Vector(0.0, 0.0);
    }

    setLidarEditOffset(x, y, z) {
        this.lidarEditOffsetX = x;
        this.lidarEditOffsetY = y;
        this.lidarEditOffsetZ = z;
    }

    lidarAdjustedOffset() {
        const offset = this.isLidarOffsetEditMode() ? this.lidarEditOffset() : this.lidarOffset();
        return offset;
    }

    lidarEditOffset() {
        return new Vector(this.lidarEditOffsetX || 0.0, this.lidarEditOffsetY || 0.0, this.lidarEditOffsetZ || 0.0);
    }

    isLidarOffsetEditMode() {
        return this.lidarOffsetEditMode;
    }

    setLidarOffsetEditMode(mode) {
        this.lidarOffsetEditMode = mode;
    }

    getOffsetAdjustedPoints() {
        return adjustPointsByOffset(this.lidarData.points, this.lidarAdjustedOffset());
    }

    renderPoints() {
        const points = this.getOffsetAdjustedPoints();
        const geometry = makePointGeometry(points);
        const material = this.dRenderer.inlineShaderMaterial('vertexShaderPointLidar', 'fragmentShaderPointLidar');

        const options = {
            geometry,
            material,
            scene: this.dRenderer.physicalSurfaceLayer,
            strokeWeight: 4.0,
            strokeColor: '#ffffff',
            texture: this.dRenderer.graphicResourceCache.rainbowMap,
        };

        this.lidarPrim = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, options);
        this.dRenderer.dirtyFrame();
    }

    renderTriangles() {
        const { triangles } = this.lidarData;
        const points = this.getOffsetAdjustedPoints();
        const params = new Array(triangles.length / 3);
        for (let i = 0; i < params.length; ++i) {
            params[i] = {
                vertex1: points[triangles[i * 3]],
                vertex2: points[triangles[i * 3 + 1]],
                vertex3: points[triangles[i * 3 + 2]],
            };
        }

        const geometry = makeTriangleListGeometrySolid(params);
        const material = this.dRenderer.inlineShaderMaterial(
            'vertexShaderHeightMapColored',
            'fragmentShaderHeightMapColored',
        );

        const options = {
            geometry,
            material,
            scene: this.dRenderer.physicalSurfaceLayer,
            texture: this.dRenderer.graphicResourceCache.rainbowMap,
        };
        this.lidarPrim = this.dRenderer.renderPrimitive(PrimitiveMeshFill, options);
    }

    renderTextured() {
        const { triangles } = this.lidarData;
        const points = this.getOffsetAdjustedPoints();
        const params = new Array(triangles.length / 3);

        if (this.lidarData.image) {
            const aabb = new AABB2().fromPoints(points);
            const uvs = points.map(
                (i) =>
                    new THREE.Vector2(
                        (i.x - aabb.xMin) / (aabb.xMax - aabb.xMin),
                        (i.y - aabb.yMin) / (aabb.yMax - aabb.yMin),
                    ),
            );

            for (let i = 0; i < params.length; ++i) {
                params[i] = {
                    vertex1: points[triangles[i * 3]],
                    vertex2: points[triangles[i * 3 + 1]],
                    vertex3: points[triangles[i * 3 + 2]],
                    uv1: uvs[triangles[i * 3]],
                    uv2: uvs[triangles[i * 3 + 1]],
                    uv3: uvs[triangles[i * 3 + 2]],
                };
            }

            const geometry = makeTriangleListGeometrySolid(params, true);
            const material = this.dRenderer.inlineShaderMaterial(
                'vertexShaderHeightMapTextured',
                'fragmentShaderHeightMap',
            );

            const options = {
                geometry,
                material,
                texture: makeTextureFromImage(this.lidarData.image),
                scene: this.dRenderer.physicalSurfaceLayer,
            };
            this.lidarPrim = this.dRenderer.renderPrimitive(PrimitiveMeshFill, options);
        } else if (this.dRenderer.tileHelper.coverageRect) {
            const material = this.dRenderer.inlineShaderMaterial(
                'vertexShaderHeightMapImagery',
                'fragmentShaderHeightMap',
            );

            for (let i = 0; i < params.length; ++i) {
                params[i] = {
                    vertex1: points[triangles[i * 3]],
                    vertex2: points[triangles[i * 3 + 1]],
                    vertex3: points[triangles[i * 3 + 2]],
                };
            }

            const geometry = makeTriangleListGeometrySolid(params);
            const { minx, maxx, miny, maxy } = this.dRenderer.tileHelper.coverageRect;
            this.textureRectVal = new THREE.Vector4(minx, maxx, miny, maxy);

            const options = {
                geometry,
                material,
                scene: this.dRenderer.physicalSurfaceLayer,
                texture: this.dRenderer.tileHelper.bufferLayer.userData.renderTarget.texture,
                customUniforms: {
                    textureRect: { value: this.textureRectVal },
                },
            };
            this.lidarPrim = this.dRenderer.renderPrimitive(PrimitiveMeshFill, options);
        }
    }
}
