import { $log, Messager } from 'helioscope/app/utilities/ng';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { createUniqueDescription } from 'helioscope/app/utilities/helpers';

export function actionPremadeCreate({ dispatcher, geometry, cloneSource = null } = {}) {
    let description;
    if (cloneSource == null) {
        description = `Spherical Tree ${dispatcher.design.entity_premades.length + 1}`;
    } else {
        description = createUniqueDescription(cloneSource.description, dispatcher.design.entity_premades);
    }

    const premade = new EntityPremade({
        design_id: dispatcher.design.design_id,
        geometry,
        description,
    });

    dispatcher.stateHandler.createObject(premade, premadeChangeConfig(dispatcher, premade, cloneSource !== null));
}

export function actionPremadeDelete({ dispatcher, premade } = {}) {
    dispatcher.stateHandler.deleteObject(premade, premadeChangeConfig(dispatcher, premade));
}

export function actionPremadeMove({ dispatcher, premade, position } = {}) {
    dispatcher.createSinglePropertyChange({
        resource: premade,
        path: 'geometry.parameters.position',
        oldVal: premade.geometry.parameters.position,
        newVal: position,
        mergeable: false,
        loadMessage: `Move ${premade}`,
        rollbackMessage: `Undo ${premade} move`,
    });
}

export function premadeChangeConfig(dispatcher, premade, isClone = false) {
    return {
        create: {
            text: `Create Premade: ${premade}`,
            preflight: () => {
                delete premade.entity_premade_id;
                dispatcher.designManager.premadeCallback(premade, 'geometry');
                dispatcher.renderer.renderPremade(premade, { renderOptions: {} });
            },
            onSuccess: (pm) => {
                Messager.success(`Successfully created ${pm}`);
                dispatcher.publish('entityPremadesChanged', { created: pm, isClone });
            },
            onError: (err) => {
                Messager.error(`Error creating ${premade}`);
                dispatcher.designManager.removeEntities([premade]);
                dispatcher.publish('entityPremadesChanged', { error: premade });
                $log.warn(err);
            },
        },
        delete: {
            text: `Remove Premade: ${premade}`,
            preflight: () => {},
            onSuccess: (pm) => {
                Messager.success(`Successfully deleted ${pm}`);
                dispatcher.designManager.removeEntities([pm]);
                dispatcher.publish('entityPremadesChanged', { deleted: pm });
            },
            onError: (err) => {
                Messager.error(`Error deleting ${premade}`, { error: premade });
                $log.warn(err);
            },
        },
    };
}

export function actionPremadePaste({ dispatcher, premade, position } = {}) {
    const geometry = _.cloneDeep(premade.geometry);
    geometry.parameters.position = position;
    actionPremadeCreate({ dispatcher, geometry, cloneSource: premade });
}
