import * as analytics from 'helioscope/app/utilities/analytics';

import { $state, $filter, $rootScope } from 'helioscope/app/utilities/ng';
import { makeIframeAwareUrl } from 'helioscope/app/utilities/url';
import { KEY } from 'helioscope/app/utilities/helpers';
import { FieldSegmentMapControls } from '../field_segment';

import { WiringZoneActionMixin } from './actions';
import { makeWiringZone } from './services';
import { estimateWireLoss } from '../components/summary';

import * as tooltips from './tooltips';
import { StringingError } from '../stringing';

export class WiringZoneCtrl extends WiringZoneActionMixin {
    constructor(design, dispatcher, $scope, profile) {
        'ngInject';

        super(dispatcher);

        this.design = design;
        this.fsMapControls = new FieldSegmentMapControls(dispatcher, $scope);
        this.profile = profile;

        this.sortableOptions = {
            disabled: design.locked,
            connectWith: '.children',
            dropOnEmpty: true,
            forcePlaceholderSize: true,
            placeholder: 'placeholder',
            stop: (evt, ui) => this.updateWiringRelationships(evt, ui),
        };

        this.createWiringGroups();

        const endListener = dispatcher.subscribe('WiringZone:updateRelationships', () => this.createWiringGroups());

        $scope.$on('$destroy', endListener);
    }

    create() {
        return this.createWiringZone({ wiringZone: makeWiringZone(this.design, this.profile) });
    }

    highlight(wiringZone, highlight) {
        for (const fieldSegment of wiringZone.field_segments) {
            this.dispatcher.renderer.highlightFieldSegment(fieldSegment, highlight);
        }
    }

    /**
     * the ui-sortable table needs the wiring groups in a particular format,
     * it's also best to make a copy to compare to current results
     */
    createWiringGroups() {
        const wiringGroups = this.design.wiring_zones.map((wz) => ({
            wiringZone: wz,
            fieldSegments: _.sortBy(wz.field_segments, 'wiring_priority'),
        }));

        this.wiringGroups = wiringGroups;
    }

    updateWiringRelationships(_evt, ui) {
        // The angular "UI Sortable" library updates the wiring group model in response to drag/drop events, then it
        // calls this callback. ui-sortable provides data in "ui.item.sortable" about the drag/drop event:
        // https://www.npmjs.com/package/angular-ui-sortable#canceling
        const { dropindex: newWiringPriority, model: fieldSegment } = ui.item.sortable;

        // newWiringPriority is undefined if the field segment is dropped in the starting location (no change)
        if (newWiringPriority != null) {
            // Ui-sortable does not give us a way to directly look up the drop target's wiring group/zone,
            // so we have to use the fieldSegment to look up which wiring group the field segment was moved to.
            const newWiringGroup = this.wiringGroups.find((wg) => wg.fieldSegments.includes(fieldSegment));
            const newWiringZone = newWiringGroup.wiringZone;

            if (newWiringPriority !== fieldSegment.wiring_priority || newWiringZone !== fieldSegment.wiring_zone) {
                this.updateWiringPriority(fieldSegment, newWiringZone, newWiringPriority);
            }
        }
    }
}

export class WiringZoneDetailCtrl extends WiringZoneActionMixin {
    constructor(dispatcher, wiringZone, wires, $document, $scope, acConfigs) {
        'ngInject';

        super(dispatcher, wiringZone);

        this.wires = wires;
        this.filteredWires = $filter('wireFilter')(wires);

        this.syncInverterView();
        this.configurePopovers($document, $scope);

        this.acConfigs = acConfigs;

        this.generateComponentsRoute = () => makeIframeAwareUrl('/components', '/library/components');

        this.unwiredModulesDetectedMessage = '';
        this.haveUnwiredModules = false;

        this.dcPowerUsageToolTipText = `The first value represents the DC nameplate of the currently wired modules,
                        while the second value indicates the maximum potential if all modules are wired.`;

        this.updateUnwiredModuleText();

        const endListener = dispatcher.subscribe('resourceUpdated', () => this.updateCache());
        const wiringUpdateListener = dispatcher.subscribe('WiringUpdated', () => this.updateCache());

        $scope.$on('$destroy', endListener);
        $scope.$on('$destroy', wiringUpdateListener);
        this.updateCache();
    }

    updateCache() {
        // making the cache use the same mutated object meanst that ng-init in the view will work
        // with dirty checking
        this.cache = this.cache || {};
        delete this.cache.errors;

        this.cache.inverter = this.wiringZone.inverter;
        this.cache.inputConfig = this.wiringZone.inputConfiguration();
        this.cache.inverterPower = this.wiringZone.inverterPower(this.cache.inputConfig);
        this.cache.wiredDcPowerUsageValue = this.wiringZone.wiredDcPowerUsage();

        try {
            const stringBounds = this.wiringZone.stringBounds(this.cache.inputConfig);
            this.cache.stringBounds = stringBounds;
            this.cache.stringTooltips = tooltips.stringingTooltips(this.wiringZone, stringBounds);
            this.updateUnwiredModuleText();
        } catch (error) {
            if (error instanceof StringingError) {
                if (!error.data.solaredgeOptimizer) {
                    this.showOptimizerSelect = true;
                }

                this.cache.stringBounds = {
                    errors: error.data,
                };
            }
        }
    }

    updateUnwiredModuleText() {
        const unwiredCount = this.wiringZone.getUnwiredModuleCount();
        this.haveUnwiredModules = unwiredCount > 0;
        if (this.haveUnwiredModules) {
            analytics.track('designer-detected-unwired_modules', {
                numberOfUnwiredModules: unwiredCount,
                project_id: this.wiringZone.design.project_id,
                design_id: this.wiringZone.design.design_id,
                team_id: $rootScope.user().team_id,
            });
        }
        this.unwiredModulesDetectedMessage = `${unwiredCount} unwired ${
            unwiredCount > 1 ? 'modules' : 'module'
        } detected,`;
    }

    trackLearnMoreClick() {
        analytics.track('designer-clicked-wiring_learn_more', {
            refferer: 'Wiring Zone',
            project_id: this.wiringZone.design.project_id,
            design_id: this.wiringZone.design.design_id,
            team_id: $rootScope.user().team_id,
        });
    }

    wireSummary(tier, wire) {
        const summary = this.wiringZone.wiringSummary();
        const tierSummary = summary[tier];
        if (!tierSummary) {
            return wire;
        }

        const { inputPower } = tierSummary;
        const powerLoss = estimateWireLoss(tierSummary, wire);
        const lossPercentage = _.round((100 * powerLoss) / inputPower, 1).toFixed(1);
        return `${wire}, ${lossPercentage}%`;
    }

    /**
     * if the inverter type changes, adjust the view accordingly.  The same logic can also
     * be used to handle the redirect when entering the state
     */
    syncInverterView() {
        const inverterIsMicro = this.wiringZone.inverter && this.wiringZone.inverter.microinverter;

        if (this._inverterIsMicro !== inverterIsMicro) {
            if (inverterIsMicro) {
                $state.go('designer.design.wiring_zones.detail.ac');
            } else if ($state.is('designer.design.wiring_zones.detail')) {
                $state.go('designer.design.wiring_zones.detail.dc');
            }
        }

        this._inverterIsMicro = inverterIsMicro;
    }

    setBus(busId = 1) {
        this.dispatcher.createSinglePropertyChange({
            resource: this.wiringZone,
            path: 'bus_id',
            oldVal: this.wiringZone.trunk_id,
            newVal: busId,
            mergeable: true,
        });
    }

    setTrunk(trunkId = 1) {
        this.dispatcher.createSinglePropertyChange({
            resource: this.wiringZone,
            path: 'trunk_id',
            oldVal: this.wiringZone.trunk_id,
            newVal: trunkId,
            mergeable: true,
        });
    }

    setAcRun(acRunId = 1) {
        this.dispatcher.createSinglePropertyChange({
            resource: this.wiringZone,
            path: 'ac_run_id',
            oldVal: this.wiringZone.ac_run_id,
            newVal: acRunId,
            mergeable: true,
        });
    }

    setInputConfigManually(toggle) {
        if (toggle) {
            const { parallel, series } = this.wiringZone.inputConfiguration(true).getLargestConfig();

            this.updateModuleInputConfig({
                parallel,
                series,
            });
        } else {
            this.updateModuleInputConfig({
                parallel: null,
                series: null,
            });
        }
    }

    setStringsManually() {
        this.updateStringBounds({ bounds: this.wiringZone.stringBounds() });
    }

    setStringsAutomatically() {
        this.updateStringBounds({ bounds: undefined });
    }

    /**
     * Returns all the wires sorted by resistivity and material
     * @param wireId (optional) if passed, include the existing wire in the wire list.
     * This is a hack since the list is filtered, so once the user changes the wire, the previous wire may "vanish"
     * fron the list.
     */
    allWires(wireId = null) {
        const wire = _.find(this.wires, (wr) => wr.wire_gauge_id === wireId);

        /* eslint-disable no-nested-ternary */
        const comp = (x, y) =>
            x.material === y.material ? x.resistivity - y.resistivity : x.material < y.material ? 1 : -1;
        /* eslint-enable no-nested-ternary */

        let wireList = this.filteredWires;
        if (wire && this.filteredWires.includes(wire) === false) {
            wireList = [wire].concat(wireList);
        }

        return wireList.sort(comp);
    }

    /**
     * get the maximum current flowing through combiners at a given tier for
     * the purposes of code calculation (this is why it uses max possible
     * current through a combiner (based on module Isc) rather than calculated
     * current totals
     *
     * Caveat 1: this will not provide correct answers for recombiners as
     * Caveat 2: this assumes that field segments use modules homogenously
     */
    combinerCurrent(tier = 'bus_combiner') {
        const summary = this.wiringZone.wiringSummary();
        const tierSummary = summary[tier];

        if (!tierSummary) {
            return { maxInputs: 0, iSc: 0 };
        }

        const { maxInputs } = tierSummary;
        return { maxInputs, iSc: _.get(this.wiringZone, 'field_segments[0].module_characterization.i_sc') * maxInputs };
    }

    /**
     * Popover jank
     */

    configurePopovers($document, $scope) {
        this.hidePopovers();

        $document.on('keydown.WiringZoneDetailCtrl', (evt) => {
            if (evt.keyCode === KEY.ESC) {
                this.hidePopovers();
                $scope.$apply();
            }
        });

        $scope.$on('$destroy', () => {
            $document.off('keydown.WiringZoneDetailCtrl');
        });
    }

    /**
     * toggle the given key, and hide everything else
     */
    updatePopovers(key) {
        this.popovers = {
            showDcAcRatio: key === 'showDcAcRatio' ? !this.popovers[key] : false,
        };
    }

    hidePopovers() {
        this.popovers = {
            showDcAcRatio: false,
        };
    }
}

export class DcAcRatioPopoverCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;

        $scope.$on('$destroy', () => {
            this.cancel();
            this.dispatcher.renderUpdater.updateWiringDeferred();
        });
    }

    init(wiringZone, dispatcher) {
        this.wiringZone = wiringZone;
        this.dispatcher = dispatcher;

        wiringZone.max_dc_ac_ratio = wiringZone.max_dc_ac_ratio || 1.25;
        this.backupInverterCount = wiringZone.inverter_count;
        wiringZone.inverter_count = null;
        this.dispatcher.renderUpdater.updateWiringDeferred();
    }

    cancel() {
        this.wiringZone.inverter_count = this.backupInverterCount;

        this.$scope.wzDetailCtrl.hidePopovers();
    }

    save() {
        this.wiringZone.inverter_count = null;

        this.dispatcher.createSinglePropertyChange({
            resource: this.wiringZone,
            path: 'inverter_count',
            oldVal: this.backupInverterCount,
            newVal: this.wiringZone.inverter_count,
            mergeable: true,
        });

        this.backupInverterCount = null;
        this.$scope.wzDetailCtrl.hidePopovers();
    }
}
