/**
 * Optimization Strategy?
 *
 * Find Modules that are hardest to match and group them into a string, letting difficulty to match
 * be the tiebreaker
 *
 * Calculate number of allowable orphans?
 *
 * Find the nearest module to continue orphans
 *
 *
 * every rack segment can have multiple children and multiple parents
 *
 * should be clusters
 * balance clusters for divisability
 *
 * Current algorithm (per wiring zone):
 *
 * 1.   Group Modules into groups for each inverter (number of modules required by
 *      the inverter wiring schedule)
 * 2.   For each set of inverter modules:
 * 2.1. Group Modules by the phsyical rack they belong to
 * 2.2. Greedy sort the racks for this inverter
 * 2.2.1 Starting from an arbitrary rack (the last one, in this case); pick an
 *       arbitrary corner and
 * 2.2.2 Based on this corner, we know the exit corner due to the rack geometry
 *       (number of rows/cols) and the wiring strategy (along/updown)
 * 2.2.3 Find the nearest corner of the other racks to start the next batch of modules
 * 2.2.4 Repeat starting 2.2.1 until no racks left for this Inverter
 * 2.3   For each rack, wire the modules according to the wiring strategy and start corner
 *
 * http://comopt.ifi.uni-heidelberg.de/software/TSPLIB95/
 * https://web.tuke.sk/fei-cit/butka/hop/htsp.pdf
 * https://github.com/GordyD/js-aco
 * https://github.com/tzmartin/Google-Maps-TSP-Solver/blob/master/BpTspSolver.js
 * https://louisville.edu/speed/faculty/sheragu/Research/Intelligent/tsp.PDF
 * http://www.seas.gwu.edu/~simhaweb/champalg/tsp/tsp.html
 * https://heuristicswiki.wikispaces.com/Travelling+salesman+problem
 */

import { Bounds } from 'helioscope/app/utilities/geometry';

// Note: important to keep those as numbers in 0..3 sequence
const TOP_LEFT = 0;
const TOP_RIGHT = 1;
const BOTTOM_RIGHT = 2;
const BOTTOM_LEFT = 3;

const DIRECTIONS = {
    [TOP_LEFT]: { xDirection: 1, yDirection: -1 },
    [TOP_RIGHT]: { xDirection: -1, yDirection: -1 },
    [BOTTOM_RIGHT]: { xDirection: -1, yDirection: 1 },
    [BOTTOM_LEFT]: { xDirection: 1, yDirection: 1 },
};

// for odd number of rows/columns, exit corner is the same for along and up/down wiring
const entryToExitOdd = {
    [TOP_LEFT]: BOTTOM_RIGHT,
    [TOP_RIGHT]: BOTTOM_LEFT,
    [BOTTOM_RIGHT]: TOP_LEFT,
    [BOTTOM_LEFT]: TOP_RIGHT,
};

const entryToExitEvenAlong = {
    [TOP_LEFT]: BOTTOM_LEFT,
    [TOP_RIGHT]: BOTTOM_RIGHT,
    [BOTTOM_RIGHT]: TOP_RIGHT,
    [BOTTOM_LEFT]: TOP_LEFT,
};

const entryToExitEvenUpDown = {
    [TOP_LEFT]: TOP_RIGHT,
    [TOP_RIGHT]: TOP_LEFT,
    [BOTTOM_RIGHT]: BOTTOM_LEFT,
    [BOTTOM_LEFT]: BOTTOM_RIGHT,
};

/**
 * snake modules within a rack structure based on the x- and y- direction for
 * how the wiring should start
 */
function orderRackModulesAlong(rack, xDirection, yDirection) {
    const groupedModules = _.groupBy(rack.modules, (mod) => Math.round(mod.topLeft.y * 100));
    return _(groupedModules)
        .keys() // unique y-values
        .sortBy((x) => x * yDirection) // reverse sorted
        .map((key, idx) =>
            _.sortBy(groupedModules[key], (mod) => (((idx + 1) % 2) * 2 - 1) * xDirection * mod.topLeft.x),
        )
        .flatten()
        .value();
}

function orderRackModulesUpDown(rack, xDirection, yDirection) {
    const groupedModules = _.groupBy(rack.modules, (mod) => Math.round(mod.topLeft.x * 100));
    return _(groupedModules)
        .keys() // unique x-values
        .sortBy((x) => x * xDirection) // reverse sorted
        .map((key, idx) =>
            _.sortBy(groupedModules[key], (mod) => (((idx + 1) % 2) * 2 - 1) * yDirection * mod.topLeft.y),
        )
        .flatten()
        .value();
}

function weightedDistance(vec1, vec2) {
    const dx = vec1.x - vec2.x;
    const dy = vec1.y - vec2.y;
    const dz = vec1.z - vec2.z;
    // only used for comparison so doesn't need to be an actual sqrt() distance
    //
    // Y-distance is weighted at more heavily to bias the algorithm towards
    // not skipping rows, because the algorithm is currently limited to only
    // evaluate corners of racking so we want to bias against this jump, making
    // it more expensive:
    //                       _______________
    //                       |             |
    //                       *-------------
    //  _____________________V___________________________
    //  |                    V                          |
    // ----------------------V---------------------------
    //                       V______________
    //                       |             |
    //                       --------------
    return dx * dx + 2 * dy * dy + dz * dz;
}

// find rack closest to a location based on shortest distance to rack's 4 corners
// returns rack and which rack corner is closest
function findNearestRack(location, racking) {
    let min = null;
    for (let idx = 0; idx < racking.length; idx++) {
        const rack = racking[idx];
        // check distance to all 4 corners: TOP_LEFT, TOP_RIGHT, BOTTOM_RIGHT, BOTTOM_LEFT
        // we rely on the fact that those are defined as integers from 0 to 3
        for (let corner = 0; corner <= 3; corner++) {
            const dist = weightedDistance(location, rack.path[corner]);
            if (min === null) {
                min = { idx, dist, corner };
            } else if (dist < min.dist) {
                // perf: update the object in place (instead of creating a new one)
                min.dist = dist;
                min.idx = idx;
                min.corner = corner;
            }
        }
    }
    return {
        idx: min.idx,
        rack: racking[min.idx],
        entryCorner: min.corner,
    };
}

// like findNearestRack but also removes closest rack from racking
function popNearestRack(location, racking) {
    const res = findNearestRack(location, racking);
    racking.splice(res.idx, 1);
    return res;
}

// /**
//  * calculate the total jumper distance in a path
//  */
// function totalDistance(fieldSegment, racking) {
//     const exitCorner = getExitCorners(fieldSegment);

//     let distance = 0;
//     let lastLocation;

//     for (const [rack, entryCorner] of racking) {
//         if (lastLocation !== undefined) {
//             distance += weightedDistance(lastLocation, rack.path[entryCorner]);
//         }

//         lastLocation = exitCorner(rack, entryCorner);
//     }

//     return distance;
// }

/**
 * take an ordered rack set and create an ordered module list
 */
function createModules(orderedRacks, isAlong) {
    const sortedModules = [];

    for (const [rack, entryCorner] of orderedRacks) {
        const { xDirection, yDirection } = DIRECTIONS[entryCorner];
        let modules;
        if (isAlong) {
            modules = orderRackModulesAlong(rack, xDirection, yDirection);
        } else {
            modules = orderRackModulesUpDown(rack, xDirection, yDirection);
        }
        sortedModules.push(...modules);
    }

    return sortedModules;
}

// /**
//  * swap two edges on a path, whil keeping the rest of the edges intact
//  */
// function pathSwap(path, edge, otherEdge) {
//     // https://en.wikipedia.org/wiki/2-opt

//     // 2optSwap(route, i, k) {
//     //     1. take route[1] to route[i-1] and add them in order to new_route
//     //     2. take route[i] to route[k] and add them in reverse order to new_route
//     //     3. take route[k+1] to end and add them in order to new_route
//     //     return new_route;
//     // }
//     return path.slice(0, edge)
//         .concat(path.slice(edge, otherEdge + 1).reverse())
//         .concat(path.slice(otherEdge + 1, path.length + 1));
// }

// /**
//  * naive implementaiton of the 2-opt path optimization (https://en.wikipedia.org/wiki/2-opt)
//  * which should make any pairwise changes necessary to improve the path
//  *
//  * this does not adjust entry/exit points, so does not improve results right now, though a modified
//  * opt2 algorithm is generally recommended for reasonably optimal, but performant results
//  */
// function opt2Algorithm(fieldSegment, racking) {
//     const length = racking.length;

//     let myRacks = racking.slice();
//     let bestSolution = totalDistance(fieldSegment, myRacks);
//     let change = 0;

//     do {
//         change = 0;
//         for (let i = 0; i < length - 1; i++) {
//             for (let j = i + 1; j < length; j++) {
//                 const newSolution = pathSwap(myRacks, i, j);
//                 const newSolDist = totalDistance(fieldSegment, newSolution);

//                 if (newSolDist < bestSolution) {
//                     myRacks = newSolution;
//                     change = newSolDist - bestSolution;
//                     // console.log('Saving Swap ', i, j, change);
//                     bestSolution = newSolDist;
//                     break;
//                 }
//             }
//             if (change < 0) {
//                 break;
//             }
//         }
//     } while (change < 0);

//     return myRacks;
// }

/**
 * order the racks in a field segment based on always opportunistically finding the shortest
 * jumper from one corner to any other corner on the array
 */
function greedySortRacks(racking, last = undefined) {
    if (racking.length === 0) {
        return [];
    }
    const sortedRacks = [];
    const rackingCopy = racking.slice(); // don't mutate the passed in rack
    // the last racking unit should be the highest in Y (this should be the top left point)
    let lastLocation = last || rackingCopy[rackingCopy.length - 1].path[0];

    while (rackingCopy.length > 0) {
        const { rack, entryCorner } = popNearestRack(lastLocation, rackingCopy);
        sortedRacks.push([rack, entryCorner]);
        const exitCorner = rack.entryToExitCornerMap[entryCorner];
        lastLocation = rack.path[exitCorner];
    }

    return sortedRacks;
}

// when we traverse modules, we zig-zag rows (for along stringing) or columns
// for (up/down stringing). We need to know how many turns we make along
// each direction, which is number number of rows (for along) or columns
// (technically number of turns is -1, but numbers are easier to verify when
// using number of rows/columns).
// To calculate number of rows/columns we "bucket" y/x positions and count
// number of unique position.
function numberOfTurns(modules, isAlong) {
    const unique = {};
    for (let i = 0; i < modules.length; i++) {
        const pos = modules[i].topLeft;
        const n = isAlong ? pos.y : pos.x;
        unique[Math.round(n * 100)] = true;
    }
    return Object.keys(unique).length;
}

function getModulesBounds(modules) {
    const bounds = new Bounds();
    for (let i = 0; i < modules.length; i++) {
        const path = modules[i].path;
        // only use TOP_LEFT corner because rack transformation only
        // updates topLeft point of its modules
        bounds.extend(path[TOP_LEFT]);
    }
    return bounds.path;
}

// sort by both y and x to make it stable
function sortRackCmp(e1, e2) {
    const p1 = e1.path[0];
    const p2 = e2.path[0];
    const dy = p1.y - p2.y;
    if (dy !== 0) {
        return dy;
    }
    return p1.x - p2.x;
}

// assumes module batch is already transformed into a consisten coordinate system
function orderModules(rackModules, isAlong, lastPreviousModule = null) {
    if (rackModules.length === 0) {
        return [];
    }
    const racking = [];
    for (const [rack, modules] of rackModules) {
        // pick a map for choosing exit corner given entry corner given stringing
        // type and rack structure (row count or column count)
        let entryToExitCornerMap = entryToExitOdd;
        const nTurns = numberOfTurns(modules, isAlong);
        if (nTurns % 2 === 0) {
            entryToExitCornerMap = isAlong ? entryToExitEvenAlong : entryToExitEvenUpDown;
        }

        const el = {
            modules,
            entryToExitCornerMap,
            path: getModulesBounds(modules),
            // we keep those for debugging
            rackPath: rack.path,
            nTurns,
        };
        racking.push(el);
    }
    racking.sort(sortRackCmp);

    // find the most efficient hop between last module from previous order
    // and a new racking
    let startPoint = null;
    if (lastPreviousModule) {
        const { rack, entryCorner } = findNearestRack(lastPreviousModule.topLeft, racking);
        startPoint = rack.path[entryCorner];
    }

    // connect the rows. this is where we actually use the rack geometry
    const sortedRacks = greedySortRacks(racking, startPoint);
    // console.log("Prelim Distance", totalDistance(fieldSegment, sortedRacks));
    // // sortedRacks = _.shuffle(sortedRacks);
    // // console.log("Shuffled Distance", totalDistance(fieldSegment, sortedRacks));
    // sortedRacks = opt2Algorithm(fieldSegment, sortedRacks);
    // console.log("Final Distance", totalDistance(fieldSegment, sortedRacks));

    return createModules(sortedRacks, isAlong);
}

const RACK_IDX = 0;
const MODULES_IDX = 1;

function appendModuleToRack(rackToModules, rack, module) {
    const n = rackToModules.length;
    for (let i = 0; i < n; i++) {
        // looking from the end should be faster since they are originally ordered
        // by rack, so the one added last is most likely to be same as new one
        const idx = n - 1 - i;
        const rack2 = rackToModules[idx][RACK_IDX];
        if (rack === rack2) {
            rackToModules[idx][MODULES_IDX].push(module);
            return;
        }
    }
    rackToModules.push([rack, [module]]);
}

function isValidRotation(rot) {
    return rot === 0 || rot === 180;
}

const SORT_ORDER_IDX = 0;
const RACK_MODS_IDX = 1;

/*
 groupedRacks is:
 [
   [sortOrder1, [
        [rack1, [module1, module2]],
        [rack2, [module1, module2]]
   ],

   [sortOrder2, [
       [rack2, [module1, module2]],
   ]
]
This is 2 level sorting:
- first we sort by rotation/field segment's wiring zone, which is cleverly
  encapsulated in a single number: wiring_priotity * 1000 + rotation
  This sorts first by field segment and then by rotation within field segment
  wirign_priority is 0...n, rotation is 0 or 180
- then we group modules by rack within sort order
*/
function groupRackModule(groupedRacks, rack, module) {
    const rotation = module.rotation;
    if (!isValidRotation(rotation)) {
        return;
    }
    const wp = module.fieldSegment.wiring_priority;
    // 1000 is somewhat arbitrary, must be > 360 i.e. max possible rotation
    const sortOrder = wp * 1000 + rotation;

    const n = groupedRacks.length;
    for (let i = 0; i < n; i++) {
        const sortOrder2 = groupedRacks[i][SORT_ORDER_IDX];
        if (sortOrder === sortOrder2) {
            const rackToModules = groupedRacks[i][RACK_MODS_IDX];
            appendModuleToRack(rackToModules, rack, module);
            return;
        }
    }
    groupedRacks.push([sortOrder, [[rack, [module]]]]);
}

/**
 * order the racks in a field segments based on always opportunistically finding
 * the shortest jump from one corner to any other corner on the array
 */
export function sortModuleBatch(wiringZone, moduleTupleBatch) {
    const isAlong = wiringZone.stringing_strategy === 'along';

    const groupedRacks = [];
    for (const { rack, module } of moduleTupleBatch) {
        groupRackModule(groupedRacks, rack, module);
    }
    if (groupedRacks.length === 0) {
        return [];
    }

    groupedRacks.sort((el1, el2) => el1[SORT_ORDER_IDX] - el2[SORT_ORDER_IDX]);

    const rtn = [];

    let lastModule;
    for (let i = 0; i < groupedRacks.length; i++) {
        const rackToModules = groupedRacks[i][RACK_MODS_IDX];
        // pass in batches that have the same rotation and field segment
        const ordered = orderModules(rackToModules, isAlong, lastModule);
        lastModule = _.last(ordered);
        rtn.push(...ordered);
    }
    return rtn;
}
