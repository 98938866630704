/**
 * Basic electrical calculations
 */

export const ZERO_POWER = {
    voltage: 0,
    current: 0,
    power: 0,
};

export const DEFAULT_AC_CONFIG = {
    phase: 3,
    voltage: 480,
    ac_circuit: 'wye',
};

export function symmetricalSeriesAggregator(operatingPoint, count) {
    // assumes all the input components are identical
    const { current, voltage, power } = operatingPoint;

    return { current, voltage: voltage * count, power: power * count };
}

export function parallelAggregator(operatingPoints) {
    if (operatingPoints.length === 0) {
        return ZERO_POWER;
    }

    let totalPower = 0;
    let totalCurrent = 0;

    for (const { current, voltage } of operatingPoints) {
        totalPower += current * voltage;
        totalCurrent += current;
    }

    return {
        voltage: totalPower / totalCurrent,
        current: totalCurrent,
        power: totalPower,
    };
}

/**
 * given a wire resistivity, single-direction length, and voltage info return details on
 * the power loss through the wire
 *
 * The length needs to be 1-way, and then this will use the phases to apply the
 * appropriate scale-up for two directions.
 */
export function calculateWireLoss(resistivity, length, { voltage, current, phase = 1 }) {
    let resistance = resistivity * length;

    if (phase !== 3) {
        resistance *= 2;
    }

    const outputVoltage = voltage - current * resistance;

    return {
        current,
        voltage: outputVoltage,
        power: current * outputVoltage,
        phase,
    };
}

export const identityTransformation = (_component, inputPower) => inputPower;

// specific to component tree structure
export const singleInput = ({ child }) => child.outputPower();
export const seriesConnection = ({ children }) =>
    symmetricalSeriesAggregator(children[0].outputPower(), children.length);

export const parallelConnection = ({ children }) => {
    // this is a simplification, flattening incoming children for inverter
    // configurations with dual mppt. this is correct for aggregating power,
    // but not necessarily voltage and current; because Inverters always output
    // at their AC Config levels, this is still mathematically correct in
    // aggregate (and combiners do not have individual MPPT anyway)
    const childPower = _(children).flatten().invokeMap('outputPower').value();

    return parallelAggregator(childPower);
};

export const wireLossTransformation = ({ wire, length }, inputPower) =>
    calculateWireLoss(wire.resistivity, length, inputPower);

/**
 * create a decorator to mixin an object of methods to an ES6 class
 *
 * based on http://raganwald.com/2015/06/26/decorators-in-es7.html
 */
function mixin(behaviour, sharedBehaviour = {}) {
    const instanceKeys = Reflect.ownKeys(behaviour);
    const sharedKeys = Reflect.ownKeys(sharedBehaviour);
    const typeTag = Symbol('isa');

    function _mixin(clazz) {
        for (const property of instanceKeys) {
            Object.defineProperty(clazz.prototype, property, {
                value: behaviour[property],
                writable: true,
            });
        }
        Object.defineProperty(clazz.prototype, typeTag, { value: true });
        return clazz;
    }

    for (const property of sharedKeys) {
        Object.defineProperty(_mixin, property, {
            value: sharedBehaviour[property],
            // eslint-disable-next-line no-prototype-builtins
            enumerable: sharedBehaviour.propertyIsEnumerable(property),
        });
    }

    Object.defineProperty(_mixin, Symbol.hasInstance, {
        value: (i) => !!i[typeTag],
    });

    return _mixin;
}

export function powerComponentMixin({ source, outputTransformation = identityTransformation } = {}) {
    return mixin({
        inputPower() {
            return source(this);
        },
        outputPower(inputPower = this.inputPower()) {
            return outputTransformation(this, inputPower);
        },
        power() {
            const input = this.inputPower();
            return { input, output: this.outputPower(input) };
        },
    });
}
