import Logger from 'js-logger';

import _ from 'lodash';
import { Vector } from 'helioscope/app/utilities/geometry';
import { computeOverlayPath3D, computeOverlayPoint } from './GeometryHelpers';
import { makeQuadTexturedGeometryFromSize, makeWireGeometry } from './GLHelpers';
import { PrimitiveMeshStroke, PrimitiveMeshFill } from './Primitives';

const logger = Logger.get('RenderableWiringTree');

export class RenderableWiringTree {
    constructor(renderer, design) {
        this.renderer = renderer;
        this.design = design;

        this.subRenderables = [];
        this.deferredFunctions = [];
        this.strokeWeightScalar = 1;
    }

    clearRenderable(removeChildren = true) {
        if (removeChildren) {
            this.clearChildRenderables();
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }

        this.renderer.dirtyFrame();
    }

    // Under perspective projection, strokeWeight needs to higher in order to visually see the strokes.
    setRenderableOptions(options) {
        this.strokeWeightScalar = options.projection === 'Perspective' ? 1000 : 1;
    }

    renderRenderable(options) {
        this.clearRenderable();

        this.preframeFn = () => {
            this.updateRenderable();
        };
        this.removeUpdate = this.renderer.registerPreFrameCallback(this.preframeFn, true);

        const interconnectOptions = _.assign(
            {
                texture: this.renderer.graphicResourceCache.interconnectIconTexture,
                size: new Vector(2, 3.35664),
                zOrder: 2,
            },
            options,
        );

        const inverterOptions = _.assign(
            {
                texture: this.renderer.graphicResourceCache.inverterIconTexture,
                size: new Vector(3, 3),
                zOrder: 2,
            },
            options,
        );

        const combinerOptions = _.assign(
            {
                texture: this.renderer.graphicResourceCache.combinerIconTexture,
                size: new Vector(1, 1.3),
                zOrder: 1,
            },
            options,
        );

        const acpanelOptions = _.assign(
            {
                texture: this.renderer.graphicResourceCache.panelIconTexture,
                size: new Vector(1, 1.3),
                zOrder: 1,
            },
            options,
        );

        // cleanest probably to render in separate pass on top of main scene (no occlusion)
        // in any case, component.path might need to be revisited since it's 2d only atm

        // computing 3d wiring overlay is expensive so we defer processing to maintain interactivity
        for (const component of this.design.getFlattenedComponents()) {
            switch (component.component_type) {
                case 'interconnect':
                    if (options && options.interconnect === false) break;
                    {
                        const renderable = new RenderableWiringIcon(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable(interconnectOptions);
                    }
                    break;
                case 'inverter':
                    if (options && options.inverters === false) break;
                    if (component.inverter && component.inverter.microinverter !== true) {
                        const renderable = new RenderableWiringIcon(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable(inverterOptions);
                    }
                    break;
                case 'combiner':
                    if (options && options.combiners === false) break;
                    {
                        const renderable = new RenderableWiringIcon(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable(combinerOptions);
                    }
                    break;
                case 'ac_panel':
                    if (options && options.combiners === false) break;
                    {
                        const renderable = new RenderableWiringIcon(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable(acpanelOptions);
                    }
                    break;
                case 'bus':
                    if (options && options.wiring === false) break;
                    this.deferredFunctions.push(() => {
                        const renderable = new RenderableWiringPath(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable({
                            strokeColor: '#88ff88',
                            strokeWeight: 2.0 * this.strokeWeightScalar,
                            strokeOpacity: 0.5,
                        });
                    });
                    break;
                case 'string':
                    if (options && options.wiring === false) break;
                    this.deferredFunctions.push(() => {
                        const renderable = new RenderableWiringPath(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable({
                            strokeColor: '#88ff88',
                            strokeWeight: 1.0 * this.strokeWeightScalar,
                            strokeOpacity: 0.5,
                        });
                    });
                    break;
                case 'ac_run':
                    if (options && options.wiring === false) break;
                    this.deferredFunctions.push(() => {
                        const renderable = new RenderableWiringPath(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable({
                            strokeColor: '#ffaa44',
                            strokeWeight: 3.0 * this.strokeWeightScalar,
                            strokeOpacity: 0.5,
                        });
                    });
                    break;
                case 'ac_branch':
                    if (options && options.wiring === false) break;
                    this.deferredFunctions.push(() => {
                        const renderable = new RenderableWiringPath(this.renderer, this.design, component);
                        this.addSubRenderables(renderable);
                        renderable.renderRenderable({
                            strokeColor: '#ffaa44',
                            strokeWeight: 1.0 * this.strokeWeightScalar,
                            strokeOpacity: 0.5,
                        });
                    });
                    break;
                case 'optimizer':
                case 'series_connection':
                case 'parallel_connection':
                    // don't render optimizers & related subcomponents
                    break;
                case 'module':
                    break;
                default:
                    logger.debug('Did not understand', component.component_type);
                    break;
            }
        }

        this.renderer.dirtyFrame();
    }

    // This funciton is janky due to accomodating to the old renderRenderable code
    // Moving to New Designer soon.
    renderSubRenderables(componentType, wiring = false) {
        let options = {
            interconnect: false,
            inverter: false,
            combiner: false,
            ac_panel: false,
            bus: false,
            string: false,
            ac_run: false,
            ac_branch: false,
            optimizer: false,
            modules: false,
        };

        const newOption = {};
        if (wiring) {
            newOption['wiring'] = true;
        } else {
            newOption['wiring'] = false;
        }

        if (componentType === 'combiner') {
            newOption['combiners'] = true;
        }

        newOption[componentType] = true;
        options = Object.assign(options, newOption);
        this.renderRenderable(options);
    }

    clearSubRenderables(componentType) {
        for (let i = 0; i < this.subRenderables.length; i++) {
            if (this.subRenderables[i]) {
                if (this.subRenderables[i].component.component_type === componentType) {
                    this.subRenderables[i].clearRenderable();
                }
            }
        }
    }

    clearChildRenderables() {
        for (const renderable of this.subRenderables) {
            renderable.clearRenderable();
        }

        this.subRenderables = [];
        this.deferredFunctions = [];
    }

    updateRenderable() {
        this.renderDeferred();
    }

    addSubRenderables(renderable) {
        this.subRenderables.push(renderable);
        this.renderer.registerRenderable(renderable.component, renderable);
    }

    renderDeferred() {
        if (!this.deferredFunctions || !this.deferredFunctions.length) return;

        const cutoff = 1000.0 / 30.0;
        const start = performance.now();
        while (this.deferredFunctions.length) {
            const fn = this.deferredFunctions.pop();
            fn();
            const now = performance.now();
            if (now - start > cutoff) {
                break;
            }
        }

        if (this.deferredFunctions.length) {
            this.renderer.dirtyFrame();
        }
    }
}

export class RenderableWiringPath {
    constructor(renderer, design, obj) {
        this.renderer = renderer;
        this.design = design;
        this.component = obj;
    }

    clearRenderable() {
        if (this.visualPrimitive) {
            this.visualPrimitive.clearInstances();
            this.visualPrimitive = null;
        }

        this.renderer.dirtyFrame();
    }

    renderRenderable(options) {
        this.clearRenderable();

        const paths = computeOverlayPath3D(this.component.path, this.design);
        const pts = [];
        for (const path of paths) {
            for (let i = 0; i < path.length - 1; i++) {
                pts.push(path[i]);
                pts.push(path[i + 1]);
            }
        }

        // TODO: MT: have a zoom scaled stroke weight option
        const geometry = makeWireGeometry(pts);
        const material = this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');

        const visualOptions = _.assign(
            {
                geometry,
                material,
                scene: this.renderer.wiringLayer,
                renderOrder: 0,
                depthOffset: 0,
            },
            options,
        );
        this.visualPrimitive = this.renderer.renderPrimitive(PrimitiveMeshStroke, visualOptions);
        this.renderer.dirtyFrame();
    }
}

export class RenderableWiringIcon {
    constructor(renderer, design, obj) {
        this.renderer = renderer;
        this.design = design;
        this.component = obj;
    }

    clearRenderable() {
        if (this.visualPrimitive) {
            this.visualPrimitive.clearInstances();
            this.visualPrimitive = null;
        }

        if (this.selectPrimitive) {
            this.selectPrimitive.clearInstances();
            this.selectPrimitive = null;
        }

        this.renderer.dirtyFrame();
    }

    renderRenderable(options) {
        this.clearRenderable();

        const { component } = this;

        const surfaces = _.filter(this.design.physicalSurfaces(), (ps) => ps.containsPoint(component.location));
        const overlayPoint = computeOverlayPoint(component.location, surfaces);

        this.overlayPoint = overlayPoint;

        const selectionData = {
            type: 'WiringComponent',
            selectionPriority: 99 - options.zOrder,
            draggableComponent: true,
            component,
            overlayPoint,
        };

        const visualOptions = {
            geometry: makeQuadTexturedGeometryFromSize(options.size),
            material: this.renderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
            scene: this.renderer.wiringLayer,
            texture: options.texture,
            renderOrder: options.zOrder,
            depthOffset: options.zOrder * this.renderer.tinyZOffset,
        };

        this.visualPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, visualOptions);
        this.visualPrimitive.setRenderPosition(overlayPoint);

        const selectOptions = _.assign({}, visualOptions, {
            scene: this.renderer.selectionLayerScene,
            selectionData: options.enableEditing ? selectionData : null,
        });

        this.selectPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, selectOptions);
        this.selectPrimitive.setRenderPosition(overlayPoint);

        this.renderer.dirtyFrame();
    }

    offsetRenderablePosition(delta) {
        if (this.visualPrimitive) {
            const pos = this.overlayPoint.add(delta);
            this.visualPrimitive.setRenderPosition(pos);
            this.renderer.dirtyFrame();
        }
    }
}
