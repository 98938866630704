/* eslint no-unused-vars: 0 */
import { powerComponentMixin } from 'helioscope/app/designer/components/electrical';
import { DEFAULT_CHARACTERIZATION } from './common';

export class RackingStructure {
    constructor(path, frames) {
        this.racking_structure_id = RackingStructure.uniqueId();

        this.path = path;
        this.frames = frames;

        for (const module of this.getModules()) {
            module.racking_structure_id = this.racking_structure_id;
        }
    }

    transform(matrix) {
        matrix.transformVectorArrayInPlace(this.path);

        for (let i = 0; i < this.frames.length; i++) {
            const frame = this.frames[i];
            frame.transform(matrix);
        }

        return this;
    }

    /**
     * remove frames from this structure until atleast enough modules have been removed
     * and return the number of removedModules
     */
    trimModules(modulesToRemove) {
        let removedModules = 0;
        while (modulesToRemove - removedModules > 0 && this.frameCount > 0) {
            const lastFrame = this.frames.pop();
            removedModules += lastFrame.moduleCount;
        }

        return removedModules;
    }

    get frameCount() {
        return this.frames.length;
    }

    get moduleCount() {
        return _.sumBy(this.frames, 'moduleCount');
    }

    getModules({ includeRemoved = false } = {}) {
        const modules = [];
        for (const frame of this.frames) {
            modules.push(...frame.modules);
            if (includeRemoved) {
                modules.push(...frame.removedModules);
            }
        }

        return modules;
    }

    // need to create unique IDs for each rack
    static _currentUniqueId = 0;
    static uniqueId() {
        return ++RackingStructure._currentUniqueId;
    }
}

export class RackingFrame {
    constructor(path, modules = []) {
        this.path = path;
        this.frame_index = RackingFrame.uniqueId();
        this.removedModules = [];
        this.setModules(modules);
    }

    setModules(modules) {
        this.modules = [];

        for (const module of modules) {
            this.addModule(module);
        }
    }

    addModule(module) {
        module.frame_index = this.frame_index;
        this.modules.push(module);
    }

    transform(matrix) {
        matrix.transformVectorArrayInPlace(this.path);

        for (let i = 0; i < this.modules.length; i++) {
            const module = this.modules[i];
            module.transform(matrix);
        }

        for (let i = 0; i < this.removedModules.length; i++) {
            const module = this.removedModules[i];
            module.transform(matrix);
        }

        return this;
    }

    removeModule(module) {
        const idx = this.modules.indexOf(module);
        if (idx === -1) {
            throw new RangeError("Can't find module in frame");
        }

        module.removed = true;
        this.modules.splice(idx, 1);
        this.removedModules.push(module);
    }

    restoreModule(module) {
        const idx = this.removedModules.indexOf(module);
        if (idx === -1) {
            throw new RangeError("Can't find removed module in frame");
        }

        delete module.removed;
        this.removedModules.splice(idx, 1);
        this.modules.push(module);
    }

    get moduleCount() {
        return this.modules.length;
    }

    // need to create unique IDs for each rack
    static _currentUniqueId = 0;
    static uniqueId() {
        return ++RackingFrame._currentUniqueId;
    }
}

@powerComponentMixin({
    source: (fieldModule) => {
        const char = fieldModule.fieldSegment.module_characterization || DEFAULT_CHARACTERIZATION;
        const { i_mp: current, v_mp: voltage } = char;

        return {
            power: current * voltage,
            current,
            voltage,
        };
    },
})
export class Module {
    constructor(fieldSegment, topLeft, { rotation = 0, ...rest } = {}) {
        this.fieldSegment = fieldSegment;
        /**
         * @member {Vector} topLeft The top left is one of the four points that forms the module path. We store only
         * the top left point in the component tree, and we can regenerate the rest of the path
         * (see {@link createPath} below). If we consider the tilted module in racking space,
         * the top left is the point in the module path that is farthest in the -X, +Y, and +Z directions.
         * Looking top down and considering the bottom points in the path
         * (those that are in contact with the field segment surface for fixed tilt),
         * the points are in the following order counter-clockwise: bottom left, bottom right, top right, top left.
         * See racking_v3.js for a discussion of racking space.
         * */
        this.topLeft = topLeft;
        this.rotation = rotation;

        _.assign(this, rest);
    }

    // eslint-disable-next-line camelcase
    component_type = 'module';

    get location() {
        return this.topLeft;
    }

    createPath(pathCreator = this.fieldSegment.layoutEngine().modulePathCreator()) {
        this.path = pathCreator(this);
    }

    transform(matrix) {
        this.topLeft.transformSelf(matrix);

        if (this.trackerPoint) this.trackerPoint.transformSelf(matrix);

        return this;
    }

    toJSON() {
        return {
            field_segment_id: this.fieldSegment.field_segment_id,
            wiring_zone_id: this.fieldSegment.wiring_zone_id,
            x: this.topLeft.x,
            y: this.topLeft.y,
            height: this.topLeft.z,
            component_type: this.component_type,
            rotation: this.rotation,
            racking_structure_id: this.racking_structure_id,
            frame_index: this.frame_index,
            tracker_point_x: this.trackerPoint ? this.trackerPoint.x : null,
            tracker_point_y: this.trackerPoint ? this.trackerPoint.y : null,
            tracker_point_height: this.trackerPoint ? this.trackerPoint.z : null,
            manual_orientation: this.manual_orientation,
        };
    }
}
