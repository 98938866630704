import * as THREE from 'three';
import * as GLH from '../GLHelpers';
import { PrimitiveMeshStroke } from '../Primitives';

export class RenderableParcel {
    constructor(renderer, parcel) {
        this.parcel = parcel;
        this.renderer = renderer;
        this.primitives = [];
        this.scene = null;
    }

    clearRenderable() {
        this.primitives.forEach((prim) => {
            prim.clearInstances();
        });

        if (this.scene) {
            GLH.removeInstance(this.scene);
            this.scene = null;
        }
        this.primitives = [];
        this.renderer.dirtyFrame();
    }

    renderRenderable() {
        this.internalRenderRenderable();
    }

    internalRenderRenderable() {
        this.clearRenderable();

        this.scene = new THREE.Scene();
        this.renderer.gridLayer.add(this.scene);

        this.renderParcel();

        this.scene.visible = this.parcel.isVisible;
    }

    renderParcel() {
        this.parcel.coordinates.forEach(({ boundary, holes }) => {
            this._createPolygon(boundary);

            if (holes.length > 0) {
                holes.forEach((hole) => {
                    this._createPolygon(hole);
                });
            }
        });
    }

    _translatePoint(point = []) {
        const [longitude, latitude] = point;
        const vec = this.renderer.toXY({ latitude, longitude });
        return new THREE.Vector3(vec.x, vec.y, 0);
    }

    _translatePath(path) {
        return path.map((p) => this._translatePoint(p));
    }

    _createPolygon(path) {
        const outerPaths = [this._translatePath(path)];

        this.primitives.push(
            this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                geometry: GLH.makeWireGeometry(GLH.pathToPolygonPoints(outerPaths[0])),
                material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                strokeColor: '#00ffff',
                strokeOpacity: 1.0,
                strokeWeight: 1,
                scene: this.scene,
            }),
        );
    }
}
