import { Bounds, Vector } from 'helioscope/app/utilities/geometry';

function boundsFromPathTransform(path, tform) {
    const pt1 = path[0].transform(tform);
    let minX = pt1.x;
    let maxX = minX;
    let minY = pt1.y;
    let maxY = minY;

    for (let i = 1; i < path.length; ++i) {
        const pt = path[i].transform(tform);

        if (pt.x < minX) minX = pt.x;
        else if (pt.x > maxX) maxX = pt.x;

        if (pt.y < minY) minY = pt.y;
        else if (pt.y > maxY) maxY = pt.y;
    }

    const minPt = new Vector(minX, minY);
    const maxPt = new Vector(maxX, maxY);

    const bounds = new Bounds();
    bounds.resetVector2(minPt, maxPt);

    return { bounds, minPt, maxPt };
}

class AABB2DNodeBV {
    constructor(nodes, depth = 0) {
        this.buildNode(nodes, depth);
    }

    buildNode(nodes, depth = 0) {
        const maxDepth = 12;
        const minNodes = 10;

        this.bounds = new Bounds();
        for (let i = 0; i < nodes.length; ++i) {
            const node = nodes[i];
            this.bounds.extendBounds(node.bounds);
        }

        if (nodes.length <= minNodes || depth >= maxDepth) {
            this.leafNodes = nodes;
            return;
        }

        this.leafNodes = [];

        const splitDim = this.bounds.width > this.bounds.height ? 0 : 1;
        const splitValue = splitDim === 0 ? this.bounds.midpoint.x : this.bounds.midpoint.y;

        const leftNodes = [];
        const rightNodes = [];

        for (let i = 0; i < nodes.length; ++i) {
            const node = nodes[i];
            if (node.centroid.element(splitDim) < splitValue) {
                leftNodes.push(node);
            } else {
                rightNodes.push(node);
            }
        }

        this.leftChild = new AABB2DNodeBV(leftNodes, depth + 1);
        this.rightChild = new AABB2DNodeBV(rightNodes, depth + 1);
    }

    findByPoint(point) {
        let found = [];

        if (this.bounds.contains(point)) {
            // either has child BV's or leaf modules but not both right now
            if (this.leftChild) {
                const leftFound = this.leftChild.findByPoint(point);
                const rightFound = this.rightChild.findByPoint(point);

                found = found.concat(leftFound).concat(rightFound);
            } else {
                for (let i = 0; i < this.leafNodes.length; ++i) {
                    const node = this.leafNodes[i];
                    if (node.bounds.contains(point)) {
                        found.push(node);
                    }
                }
            }
        }

        return found;
    }

    findByBound(bound) {
        let found = [];

        if (this.bounds.intersects(bound)) {
            // either has child BV's or leaf modules but not both right now
            if (this.leftChild) {
                const leftFound = this.leftChild.findByBound(bound);
                const rightFound = this.rightChild.findByBound(bound);

                found = found.concat(leftFound).concat(rightFound);
            } else {
                for (let i = 0; i < this.leafNodes.length; ++i) {
                    const node = this.leafNodes[i];
                    if (node.bounds.intersects(bound)) {
                        found.push(node);
                    }
                }
            }
        }

        return found;
    }
}

class AABB2DNodeModule {
    // TODO: MT: generalize this for non-90-degree rotations and 3d queries

    constructor(module, frame, rack, tform) {
        this.module = module;
        this.frame = frame;
        this.rack = rack;

        const { bounds, minPt, maxPt } = boundsFromPathTransform(this.module.path, tform);
        this.bounds = bounds;
        this.centroid = minPt.add(maxPt).scale(0.5);
    }
}

class RackingModuleCache {
    constructor(racking, transforms) {
        this.racking = racking;
        this.transforms = transforms;

        this.buildCache();
    }

    buildCache() {
        const { transformMatrix } = this.transforms;

        this.leafNodes = [];

        if (this.racking) {
            for (let i = 0; i < this.racking.length; ++i) {
                const rack = this.racking[i];
                for (let j = 0; j < rack.frames.length; ++j) {
                    const frame = rack.frames[j];
                    for (let k = 0; k < frame.modules.length; ++k) {
                        this.leafNodes.push(new AABB2DNodeModule(frame.modules[k], frame, rack, transformMatrix));
                    }
                    for (let k = 0; k < frame.removedModules.length; ++k) {
                        this.leafNodes.push(
                            new AABB2DNodeModule(frame.removedModules[k], frame, rack, transformMatrix),
                        );
                    }
                }
            }
        }

        this.buildBVH();
    }

    buildBVH() {
        // since we assume modules are uniformly sized and spaced, use simple mean splitting
        this.bvhRoot = new AABB2DNodeBV(this.leafNodes);
    }

    findByPoints(locations, { findRemoved = false, removedModuleLocations = null } = {}) {
        if (!locations || !locations.length) return [];

        const { transformMatrix } = this.transforms;
        const locationsT = _.map(locations, (pt) => pt.transform(transformMatrix));

        let foundNodes = [];

        for (const pt of locationsT) {
            foundNodes = foundNodes.concat(this.bvhRoot.findByPoint(pt));
        }

        return this.filterResults(foundNodes, findRemoved, removedModuleLocations);
    }

    findByPaths(paths, { findRemoved = false, removedModuleLocations = null } = {}) {
        if (!paths || !paths.length) return [];

        const { transformMatrix } = this.transforms;
        const boundsT = _.map(paths, (i) => {
            const { bounds } = boundsFromPathTransform(i, transformMatrix);
            return bounds;
        });

        let foundNodes = [];

        for (const bound of boundsT) {
            foundNodes = foundNodes.concat(this.bvhRoot.findByBound(bound));
        }

        return this.filterResults(foundNodes, findRemoved, removedModuleLocations);
    }

    findByBox(pt1, pt2, { findRemoved = false, removedModuleLocations = null } = {}) {
        const { transformMatrix } = this.transforms;

        const pt1t = Vector.fromObject(pt1).transform(transformMatrix);
        const pt2t = Vector.fromObject(pt2).transform(transformMatrix);
        const bound = new Bounds([pt1t, pt2t]);

        const foundNodes = this.bvhRoot.findByBound(bound);
        return this.filterResults(foundNodes, findRemoved, removedModuleLocations);
    }

    filterResults(foundNodes, findRemoved, removedModuleLocations) {
        const { transformMatrix } = this.transforms;

        if (!findRemoved) {
            foundNodes = _.filter(foundNodes, (node) => !node.module.removed);
        }

        if (removedModuleLocations) {
            const removedT = _.map(removedModuleLocations, (pt) => pt.transform(transformMatrix));
            return _.map(foundNodes, (node) => ({
                rack: node.rack,
                frame: node.frame,
                module: node.module,
                removedModuleLocationIdx: node.bounds.findPoint(removedT),
            }));
        } else {
            return _.map(foundNodes, (node) => ({
                rack: node.rack,
                frame: node.frame,
                module: node.module,
                removedModuleLocationIdx: -1,
            }));
        }
    }
}

class GlobalModuleCache {
    constructor() {
        this.rackingMap = new WeakMap();
    }

    getRackCache(racking, transforms) {
        if (!this.rackingMap.get(racking)) {
            this.rackingMap.set(racking, new RackingModuleCache(racking, transforms));
        }

        return this.rackingMap.get(racking);
    }
}

export const moduleCache = new GlobalModuleCache();
