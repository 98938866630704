import { $state, Messager, $log } from 'helioscope/app/utilities/ng';

import { MapConfig } from 'helioscope/app/designer/MapConfig';
import { WiringZone } from 'helioscope/app/designer/wiring_zone/WiringZone';

import { StateDelta } from '../persistence';
import { flattenComponentTree } from '../components';

export class WiringZoneActionMixin {
    constructor(dispatcher, wiringZone) {
        this.wiringZone = wiringZone;
        this.dispatcher = dispatcher;
    }

    openDetail(wiringZone) {
        $state.go('designer.design.wiring_zones.detail', wiringZone);
    }

    panTo({ wiringZone = this.wiringZone, dispatcher = this.dispatcher, select = false } = {}) {
        dispatcher.renderer.zoom(wiringZone.field_segments);

        if (select === true) {
            this.openDetail(wiringZone);
        }
    }

    _updateFieldSegmentPriority = (fieldSegment, wiringZone, priority) => {
        const dispatcher = this.dispatcher;
        const data = {
            wiring_zone_id: wiringZone.wiring_zone_id,
            field_segment_id: fieldSegment.field_segment_id,
            new_wiring_priority: priority,
        };
        // Rather than calling newWiringZone.updateFieldSegmentPriority(), we call "update" as a
        // class method, so that we can send a custom form containing the FS ID and priority.
        return WiringZone.updateFieldSegmentPriority(data).$promise.then(() => {
            dispatcher.renderUpdater.updateWiringDeferred();
            dispatcher.publish('WiringZone:updateRelationships');
            Messager.success('Updated wiring order');
        });
    };

    /**
     * update the wiring priority for a batch of field segments by creating a joined change
     * that includes several requests to batch together.  Note, this is actually changing field
     * segments exclusively, not wiring zones
     */
    updateWiringPriority(fieldSegment, newWiringZone, newPriority) {
        const oldWiringZone = fieldSegment.wiring_zone;
        const oldPriority = fieldSegment.wiring_priority;
        const loadFn = () => this._updateFieldSegmentPriority(fieldSegment, newWiringZone, newPriority);
        const rollbackFn = () => this._updateFieldSegmentPriority(fieldSegment, oldWiringZone, oldPriority);

        const description = fieldSegment.description;
        const delta = new StateDelta({
            loadText: `Update wiring order (${description})`,
            loadFn,
            rollbackText: `Undo wiring order update (${description})`,
            rollbackFn,
        });

        const stateHandler = this.dispatcher.stateHandler;

        return stateHandler.updateQueue
            .flush()
            .then(loadFn)
            .then(() => {
                stateHandler.addDelta(delta);
            });
    }

    changeConfig({ wiringZone = this.wiringZone, dispatcher = this.dispatcher } = {}) {
        return {
            create: {
                text: `Create Wiring Zone: ${wiringZone}`,
                preflight: () => {
                    delete wiringZone.wiring_zone_id;
                },
                onSuccess: (wz) => {
                    Messager.success(`Successfully created ${wz.description}`);
                    dispatcher.renderUpdater.updateWiring();
                    dispatcher.publish('WiringZone:updateRelationships');
                },
                onError: (err) => {
                    Messager.error(`Error creating ${wiringZone}`);
                    dispatcher.renderer.clearSubpolygons(wiringZone.design);
                    dispatcher.publish('WiringZone:updateRelationships');
                    $log.warn(err);
                },
            },
            delete: {
                text: `Remove Wiring Zone: ${wiringZone}`,
                onSuccess: (wz) => {
                    Messager.success(`Successfully deleted ${wz.description}`);
                    dispatcher.renderer.clearSubpolygons(wiringZone.design);
                    dispatcher.publish('WiringZone:updateRelationships');
                    // $state.go('designer.design.wiring_zones');
                },
                onError: (err) => {
                    Messager.error(`Error deleting ${wiringZone}`);
                    $log.warn(err);
                    dispatcher.renderUpdater.updateWiring();
                    dispatcher.publish('WiringZone:updateRelationships');
                },
            },
        };
    }

    updateModuleInputConfig({ wiringZone = this.wiringZone, dispatcher = this.dispatcher, series, parallel } = {}) {
        const stateHandler = dispatcher.stateHandler;
        const callback = stateHandler.getCallback(wiringZone);

        const { module_config_parallel: oldParallel, module_config_series: oldSeries } = wiringZone;

        const loadFn = () => {
            wiringZone.module_config_series = series;
            wiringZone.module_config_parallel = parallel;
            callback(wiringZone, 'module_config_parallel', parallel, oldParallel);
            stateHandler.updateQueue.schedule(wiringZone);
        };

        const rollbackFn = () => {
            wiringZone.module_config_series = oldSeries;
            wiringZone.module_config_parallel = oldParallel;
            callback(wiringZone, 'module_config_parallel', oldParallel, parallel);
            stateHandler.updateQueue.schedule(wiringZone);
        };

        const setBounds = _.isNumber(series) || _.isNumber(parallel);
        const baseMessage = setBounds
            ? `Set input configuration to a max of ${parallel} modules in parallel and ${series} in series`
            : 'Set module input configuration automatically';
        const delta = new StateDelta({
            loadText: baseMessage,
            loadFn,
            rollbackText: `Undo ${_.lowerCase(baseMessage)}`,
            rollbackFn,
        });

        loadFn();
        dispatcher.stateHandler.addDelta(delta);
    }

    updateStringBounds({ wiringZone = this.wiringZone, dispatcher = this.dispatcher, bounds = {} } = {}) {
        const { min, max } = bounds;
        const { string_size_min: oldMin, string_size_max: oldMax } = wiringZone;

        // Note:  I hate using this API to create a change, we need to have a
        // better story for registering multiple deltas at once
        const stateHandler = dispatcher.stateHandler;
        const callback = stateHandler.getCallback(wiringZone);

        const loadFn = () => {
            wiringZone.string_size_min = min;
            wiringZone.string_size_max = max;
            callback(wiringZone, 'string_size_min', min, oldMin);
            stateHandler.updateQueue.schedule(wiringZone);
        };

        const rollbackFn = () => {
            wiringZone.string_size_min = oldMin;
            wiringZone.string_size_max = oldMax;
            callback(wiringZone, 'string_size_min', oldMin, min);
            stateHandler.updateQueue.schedule(wiringZone);
        };

        const setBounds = _.isNumber(min) || _.isNumber(max);
        const baseMessage = setBounds
            ? `Set inverter string range to ${min}-${max} modules`
            : 'Set stringing automatically';
        const delta = new StateDelta({
            loadText: baseMessage,
            loadFn,
            rollbackText: `Undo ${_.lowerCase(baseMessage)}`,
            rollbackFn,
        });

        loadFn();
        dispatcher.stateHandler.addDelta(delta);
    }

    deleteWiringZone({ wiringZone = this.wiringZone, dispatcher = this.dispatcher } = {}) {
        return dispatcher.stateHandler.deleteObject(wiringZone, this.changeConfig({ wiringZone, dispatcher }));
    }

    /**
     * create a field segment and add it to the queue, delayFirstUpdate is a hack for when cloning
     * eliminate early setup until after the first move
     */
    createWiringZone({ wiringZone, dispatcher = this.dispatcher } = {}) {
        return dispatcher.stateHandler.createObject(wiringZone, this.changeConfig({ wiringZone, dispatcher }));
    }
}

export const wiringZoneActions = new WiringZoneActionMixin();

const modulesFilter = {
    module: true,
};

export const COMPONENT_MAP_ACTIONS = {
    'Inverter:dragend': (dispatcher, { inverter, location }) => {
        dispatcher.createSinglePropertyChange({
            resource: inverter,
            path: 'location',
            oldVal: inverter.location,
            newVal: location,
            mergeable: false,
            loadMessage: 'Move inverter',
            rollbackMessage: 'Undo inverter move',
            persistChanges: false,
        });
    },
    'Inverter:dragstart': (dispatcher, { inverter }) => {
        const subModules = flattenComponentTree([inverter], modulesFilter);

        dispatcher.renderer.setModuleRenderOptions(subModules, MapConfig.module.active);

        const listener = dispatcher.subscribe('Inverter:dragend', (disp, context) => {
            if (inverter === context.inverter) {
                dispatcher.renderer.setModuleRenderOptions(
                    subModules,
                    _.assign({}, MapConfig.module.base, MapConfig.module.wired),
                );
                listener();
            }
        });
    },
    'Combiner:dragend': (dispatcher, { combiner, location }) => {
        dispatcher.createSinglePropertyChange({
            resource: combiner,
            path: 'location',
            oldVal: combiner.location,
            newVal: location,
            mergeable: false,
            loadMessage: 'Move combiner',
            rollbackMessage: 'Undo combiner move',
            persistChanges: false,
        });
    },
    'Combiner:dragstart': (dispatcher, { combiner }) => {
        const subModules = flattenComponentTree([combiner], modulesFilter);

        dispatcher.renderer.setModuleRenderOptions(subModules, MapConfig.module.active);

        const listener = dispatcher.subscribe('Combiner:dragend', (disp, context) => {
            if (combiner === context.combiner) {
                dispatcher.renderer.setModuleRenderOptions(
                    subModules,
                    _.assign({}, MapConfig.module.base, MapConfig.module.wired),
                );
                listener();
            }
        });
    },
};
