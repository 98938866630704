import Logger from 'js-logger';

import { shortMonths } from 'helioscope/app/config';
import { objectFromKeys } from 'helioscope/app/utilities/helpers';

import { $filter, $rootScope, Messager } from 'helioscope/app/utilities/ng';
import { flMap } from 'helioscope/app/utilities/containers';

import { applyRemoveModules, getOverlappedModules } from 'helioscope/app/apollo/InteractComponent';

import { NewShadeCalculator } from './new_shade_calculator';

const logger = Logger.get('shade_optimizer');

export class ShadeOptimizationCtrl {
    shortMonths = shortMonths;

    constructor(design, weatherSources, dispatcher, $scope) {
        'ngInject';

        this.$scope = $scope; // need scope to use async/await :(
        this.design = design;
        this.dispatcher = dispatcher;
        this.keepouts = _.filter(design.keepouts, (ko) => ko.referenceHeight > 0);
        this.distanceFilter = $filter('hsDistance');

        this.cutoff = 100;
        this.maxCutoff = 100;
        this.numberFilter = $filter('number');

        this.weatherDatasets = ShadeOptimizationCtrl.processWeatherSources(weatherSources, design.project.location);
        this.weatherDataset = _.minBy(this.weatherDatasets, (ds) => ds.distance);

        this.ignoreRowToRow = false;

        this.nameplateLimit = $rootScope.user().nameplate_limit;
        this.designNameplate = this.calculateNameplate();
        this.isShadingAllowed = this.isShadingAllowed();
    }

    calculateNameplate() {
        return this.dispatcher.design.field_segments.reduce((sum, fs) => {
            if (fs.data && fs.data.power) {
                return sum + (fs.data.power || 0);
            }

            return sum;
        }, 0);
    }

    isShadingAllowed() {
        if (this.designNameplate === 0) {
            return false;
        }

        // Null is a special case for unlimited nameplate
        if (this.nameplateLimit === null) {
            return true;
        }

        return this.designNameplate <= this.nameplateLimit;
    }

    weatherName(dataset) {
        return `${dataset.weather_source}, ${this.distanceFilter(dataset.distance, 1, true)}`;
    }

    setPreSimulationState() {
        this.results = false;
        this.simulating = true;
    }

    setPostSimulationState() {
        this.results = true;
        this.simulating = false;
        this.$scope.$apply();
    }

    async generateShadeData() {
        if (this.isShadingAllowed) {
            this.setPreSimulationState();
            this.shadeCalculator = new NewShadeCalculator(this.design, this.weatherDataset, null, {
                ignoreRowToRow: this.ignoreRowToRow,
            });
            this.analyzeModuleEnergy();
        }
    }

    async analyzeModuleEnergy() {
        let fsModuleResults;
        try {
            fsModuleResults = await this.shadeCalculator.getModuleResults();
        } catch (err) {
            Messager.error('Error calculating shade results');
            logger.error('Error calculating Shade');
            logger.error(err);
            this.simulating = false;
            this.$scope.$apply();
            return;
        }

        this.moduleResults = _(fsModuleResults).map('moduleResults').flatten().value();

        this.idealResults = this.summarizeResults(this.moduleResults, 1);
        this.filteredResults = this.summarizeResults();
        this.cutoff = Math.ceil(this.idealResults.maxShadedPercent * 100);
        this.maxCutoff = this.cutoff;

        this.renderModuleColors();
        this.setPostSimulationState();
    }

    clearResults() {
        const renderer = this.dispatcher.renderer;

        for (const { module } of this.moduleResults) {
            renderer.setModuleRenderOptions(module, null);
        }

        this.moduleResults = null;
        this.results = false;
    }

    /**
     * apply the module filters to the energy calculations, done in a debouce to tie it to the slider
     * in the UI
     */
    filterModules = _.debounce(() => {
        if (this.results) {
            this.filteredResults = this.summarizeResults();
            this.renderModuleColors();

            this.$scope.$apply();
        }
    }, 50);

    /**
     * summarize the module results table into an overall number
     */
    summarizeResults(moduleResults = this.moduleResults, cutoff = this.cutoff / 100) {
        const summary = {
            nameplate: 0,
            poaIrradiance: 0,
            acEnergy: 0,
            shadedIrradiance: 0,
            shadedAcEnergy: 0,

            filteredModules: new Set(),

            maxEnergyLoss: 0,
            maxIrradianceLoss: 0,
            maxEnergy: 0,
            maxPoaIrradiance: 0,
            maxShadedPercent: 0,

            monthly: objectFromKeys(_.range(1, 13), () => ({ shadedIrradiance: 0, poaIrradiance: 0 })),
        };

        const _incrementMonthlyIrradiance = (row, idx) => {
            summary.monthly[idx].poaIrradiance += row.poaIrradiance;
            summary.monthly[idx].shadedIrradiance += row.shadedIrradiance;
        };

        for (const res of moduleResults) {
            if (cutoff >= 1 - res.shadedAcEnergy / res.acEnergy) {
                summary.nameplate += res.module.fieldSegment.module_characterization.power;
                summary.poaIrradiance += res.poaIrradiance;
                summary.acEnergy += res.acEnergy;
                summary.shadedIrradiance += res.shadedIrradiance;
                summary.shadedAcEnergy += res.shadedAcEnergy;

                _.forEach(res.monthly, _incrementMonthlyIrradiance);
            } else {
                summary.filteredModules.add(res.module);
            }

            summary.maxEnergyLoss = Math.max(summary.maxEnergyLoss, res.acEnergy - res.shadedAcEnergy);
            summary.maxIrradianceLoss = Math.max(summary.maxIrradianceLoss, res.poaIrradiance - res.shadedIrradiance);
            summary.maxEnergy = Math.max(summary.maxEnergy, res.acEnergy);
            summary.maxPoaIrradiance = Math.max(summary.maxPoaIrradiance, res.poaIrradiance);

            summary.maxShadedPercent = Math.max(summary.maxShadedPercent, 1 - res.shadedAcEnergy / res.acEnergy);
        }

        summary.totalEnergyLoss = summary.acEnergy - summary.shadedAcEnergy;
        summary.totalIrradianceLoss = summary.poaIrradiance - summary.shadedIrradiance;

        return summary;
    }

    /**
     * parse weather sources from server into weather datasets to choose (with distance).
     * the results should only be TMY files, which is why there is exactly one dataset
     * per source
     */
    static processWeatherSources(weatherSources, center) {
        const allDatasets = [];

        for (const weatherSource of weatherSources) {
            weatherSource.distance = weatherSource.location.distance(center);
            const datasets = weatherSource.weather_datasets;

            if (datasets.length > 0) {
                datasets[0].distance = weatherSource.distance;
                allDatasets.push(datasets[0]);
            }
        }

        return allDatasets;
    }

    /**
     * color modules based on how shaded they are.  We probably need to upgrade the algorithm
     * for how to color them, because very little art was put into it
     */
    renderModuleColors(moduleResults = this.moduleResults) {
        const renderer = this.dispatcher.renderer;

        for (const { module, acEnergy, shadedAcEnergy } of moduleResults) {
            let fillColor;
            if (this.filteredResults.filteredModules.has(module)) {
                fillColor = 'rgba(0,100,100,.25)';
            } else {
                const scalar = Math.sqrt(
                    Math.sqrt((acEnergy - shadedAcEnergy) / (this.idealResults.maxEnergyLoss || 1)),
                );
                const red = Math.round(255 * scalar);
                const blue = 255 - red;
                fillColor = `rgba(${red},0,${blue},0.8)`;
            }

            renderer.setModuleRenderOptions(module, { fillColor });
        }
    }

    /**
     * remove the filtered modules from the field segments and create undo events to match
     */
    removeModules() {
        if (this.filteredResults.filteredModules.size === 0) {
            return;
        }

        const fsRemovedModules = flMap(() => []);

        for (const module of this.filteredResults.filteredModules) {
            fsRemovedModules.get(module.fieldSegment).push(module);
        }

        for (const [fieldSegment, modules] of fsRemovedModules) {
            this._addRemoveModuleEvent(fieldSegment, modules);
        }

        this.clearResults();
    }

    /**
     * right now this will create  delta for each field segment, but could combine with as in
     *  WiringZoneActionMixin.updateWiringPriority
     */
    _addRemoveModuleEvent(fieldSegment, modules) {
        const manual = _.filter(modules, (i) => i.manual);
        if (!manual.length) {
            applyRemoveModules(this.dispatcher, fieldSegment, modules);
        } else {
            const opts = manual.map((i) => fieldSegment.racking.manual_modules[i.manual_idx]);
            const { autoModules } = getOverlappedModules(fieldSegment, opts, { findRemoved: true });
            applyRemoveModules(this.dispatcher, fieldSegment, modules.concat(autoModules));
        }
    }
}
