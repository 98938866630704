import _ from 'lodash';
import * as THREE from 'three';
import Logger from 'js-logger';
import Q from 'q';

import {
    initService,
    MAP_TILE_LAYERS,
    showLoginRequiredDialog,
    showRestrictedRegionDialog,
} from 'helioscope/app/utilities/maps';
import { Vector, Bounds } from 'helioscope/app/utilities/geometry';
import { MapConfig } from 'helioscope/app/designer/MapConfig';
import { THE_GROUND, FieldSegment } from 'helioscope/app/designer/field_segment';
import { Keepout } from 'helioscope/app/designer/keepout';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { Overlay } from 'helioscope/app/designer/overlays/overlays';
import { isKMLExtension, isImageExtension } from 'helioscope/app/designer/overlays/helpers';

import * as assets from './assets';

import { GLRenderer } from './GLRenderer';
import { makePointGeometry, makeWireGeometry } from './GLHelpers';

import { RenderableSurface, RenderableRacking } from './RenderableSurface';
import { RenderableWiringTree } from './RenderableWiringTree';
import { PrimitiveMeshStroke } from './Primitives';
import { RenderableImageOverlay, RenderableKMLOverlay } from './overlay';
import { RenderableParcel } from './parcel';
import { RenderablePremadeTreeSphere } from './RenderablePremade';
import { TileHelper } from './TileHelper';
import { LidarHelper } from './LidarHelper';
import { InteractToolCameraPanZoomSelect, InteractToolCameraZoomSelect } from './InteractView';
import { InteractToolCreatePhysicalSurface, InteractToolCreatePremade } from './InteractGeometry';
import { InteractToolManualModuleAdd, InteractToolManualModuleEdit } from './InteractComponent';
import {
    containerPoint,
    bestParentSurfaceRay,
    interactRay,
    rayGroundPlaneIntersect,
    raySurfaceIntersect,
} from './InteractHelpers';
import { InfoHelperGrid, InfoHelperAxisWidget } from './InfoHelpers';
import { RendererOptions } from './RendererOptions';
import { CursorConfig } from '../designer/CursorConfig';
import { InteractToolBoxSelect } from './InteractSelection';
import { Design } from '../designer/Design';
import { InteractToolRotation } from './InteractToolRotation';

const logger = Logger.get('Design3DRenderer');

class CreatePathInput {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
    }

    getPolygon() {
        const promise = Q.defer();

        this.tool = new InteractToolCreatePhysicalSurface(this.dRenderer, promise);
        this.dRenderer.activeTool.deactivateTool();
        this.dRenderer.activeTool = this.tool;

        return promise.promise;
    }

    clear() {
        if (this.tool) {
            this.tool.clearCreation();
        }
    }

    cancel() {
        if (this.dRenderer.activeTool === this.tool) {
            this.dRenderer.activateInteractTool();
            this.tool = null;
        }
    }
}

const SelectionState = {
    UNSELECTED: 'base',
    SELECTED: 'active',
    SELECTED_DRAGGABLE: 'selectedDraggable',
};

export class Design3DRenderer extends GLRenderer {
    constructor(dispatcher, options) {
        super();
        this.options = options;
        this.objectRenderMap = new WeakMap();

        this.deferredRenderModule = [];

        this.listenerRemovers = [];

        this.keyDownListeners = [];

        this.overrides = {};

        this.registerDispatcher(dispatcher);

        this.preframeUpdate = this.preframeUpdate.bind(this);

        this.options.enableEditing = dispatcher.settings.designMutable && !dispatcher.design.locked;

        this.tileHelperReady = this.getTileHelperPromise();
        this.prevUnwiredModules = [];
    }

    getTileHelperPromise() {
        return Q.defer();
    }

    loadAssets() {
        const loadFn = () => {
            this.requiredInitTasks--;
        };

        const failFn = () => {
            this.initFailed = true;
            throw Error('required resource failed to load');
        };

        const handleTask = (task) => {
            this.requiredInitTasks++;
            task.then(loadFn).catch(failFn);
        };

        for (const [name, font] of Object.entries(assets.fonts)) {
            if (font.type === 'texture') {
                const { texture } = font;

                handleTask(this.loadFontJSON(name, font.data));
                handleTask(this.loadTexture(texture.name, texture.url));
            } else {
                throw new Error(`Font type ${font.type} unsupported`);
            }
        }

        for (const [name, url] of Object.entries(assets.icons)) {
            handleTask(this.loadTexture(name, url));
        }
    }

    initRenderer(container, teamId, designId, canvasSettings = undefined) {
        this.initGLRenderer(container, teamId, designId, canvasSettings);

        this.registerPreFrameCallback(this.preframeUpdate);

        this.loadAssets();
        this.imageryLayer = this.addPrimaryRenderLayer();
        this.gridLayer = this.addPrimaryRenderLayer(); // Layer contains parcels.
        this.overlayLayer = this.addPrimaryRenderLayer();
        this.physicalSurfaceLayer = this.addPrimaryRenderLayer(); // Layer contains Lidar.
        this.wiringLayer = this.addPrimaryRenderLayer();
        this.editSurfaceLayer = this.addPrimaryRenderLayer();
        this.interactLayer = this.addScreenRenderLayer();
        this.uiWidgetLayer = this.addScreenRenderLayer();

        this.imageryLayer.name = 'imageryLayer';
        this.gridLayer.name = 'gridLayer';
        this.overlayLayer.name = 'overlayLayer';
        this.physicalSurfaceLayer.name = 'physicalSurfaceLayer';
        this.wiringLayer.name = 'wiringLayer';
        this.editSurfaceLayer.name = 'editSurfaceLayer';
        this.interactLayer.name = 'interactLayer';
        this.uiWidgetLayer.name = 'uiWidgetLayer';

        if (this.options.enableInteractionTools) {
            this.addEventListener(
                container,
                'mousedown',
                (event) => {
                    if (event.button === 0 && !event.ctrlKey) {
                        this.onRendererMouseDownLeft(event);
                    }
                },
                false,
            );
            this.addEventListener(
                container,
                'mouseup',
                (event) => {
                    this.onRendererMouseUp(event);
                },
                false,
            );
            this.addEventListener(
                container,
                'mousemove',
                (event) => {
                    this.onRendererMouseMove(event);
                },
                false,
            );
            this.addEventListener(
                container,
                'mouseout',
                (event) => {
                    this.onRendererMouseOut(event);
                },
                false,
            );
            this.addEventListener(
                container,
                'wheel',
                (event) => {
                    this.onRendererMouseWheel(event);
                },
                false,
            );
            this.addEventListener(
                container,
                'dblclick',
                (event) => {
                    this.onRendererDblClick(event);
                },
                false,
            );
            this.addEventListener(
                container,
                'contextmenu',
                (event) => {
                    let handleRightClick = true;

                    if (this._overrideRightClickFn !== undefined) {
                        handleRightClick = !this._overrideRightClickFn(event);
                    }

                    if (handleRightClick) {
                        this.onRendererMouseDownRight(event);
                    }

                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                },
                false,
            );

            this.addEventListener(
                document,
                'mouseup',
                (event) => {
                    this.onDocumentMouseUp(event);
                },
                true,
            );
            this.addEventListener(
                document,
                'mousemove',
                (event) => {
                    this.onDocumentMouseMove(event);
                },
                true,
            );
            this.addEventListener(
                document,
                'keydown',
                (event) => {
                    this.onDocumentKeyDown(event);
                },
                true,
            );

            this.activeTool = new InteractToolCameraPanZoomSelect(this);
        }

        this.renderDesign(this.dispatcher.design, true);
        this.renderFrame();

        if (this.options.enableLidar) {
            this.lidarHelper = new LidarHelper(this);
        }

        this.setImagerySource(this.dispatcher.design.geometry.tile_layer || 'google_satellite');

        this.infoHelperGrid = new InfoHelperGrid(this);
        this.infoHelperAxisWidget = new InfoHelperAxisWidget(this);
        this.renderHighlights = new Set();
        this.renderSelection = null;
    }

    shutdownRenderer() {
        if (this.tileHelper) {
            this.tileHelper.clearAllData();
        }
        for (const unsub of this.dispatcherUnsub) {
            unsub();
        }

        this.removeEventListeners();
        this.shutdownGLRenderer();
    }

    activateInteractTool(options) {
        let newTool = null;
        if (options) {
            if (options.tool === 'ToolAddPremade') {
                newTool = new InteractToolCreatePremade(this);
            } else if (options.tool === 'ManualModule:Add:MultiHorz') {
                newTool = new InteractToolManualModuleAdd(this, { orientation: 'horizontal' });
            } else if (options.tool === 'ManualModule:Add:MultiVert') {
                newTool = new InteractToolManualModuleAdd(this, { orientation: 'vertical' });
            } else if (options.tool === 'ManualModule:Add:SingleHorz') {
                newTool = new InteractToolManualModuleAdd(this, { orientation: 'horizontal', single: true });
            } else if (options.tool === 'ManualModule:Add:SingleVert') {
                newTool = new InteractToolManualModuleAdd(this, { orientation: 'vertical', single: true });
            } else if (options.tool === 'ManualModule:Edit') {
                newTool = new InteractToolManualModuleEdit(this);
            } else if (options.tool === 'DesignRender:Zoom') {
                newTool = new InteractToolCameraZoomSelect(this);
            } else if (options.tool === 'InteractToolBoxSelect') {
                newTool = new InteractToolBoxSelect(this);
            } else if (options.tool === 'InteractToolRotation') {
                newTool = new InteractToolRotation(this);
            }

            if (newTool) {
                newTool.toolName = options.tool;
                newTool.toolEscapeHandler = options.escapeHandler;
            }
        }

        if (!newTool) newTool = new InteractToolCameraPanZoomSelect(this);

        if (this.activeTool) this.activeTool.deactivateTool();
        this.activeTool = newTool;
    }

    exitRotateTool() {
        if (this.activeTool instanceof InteractToolRotation) {
            this.activateInteractTool(null);
        }
    }

    addEventListener(obj, evt, fn, options) {
        obj.addEventListener(evt, fn, options);
        const removeFn = () => {
            obj.removeEventListener(evt, fn, options);
        };
        this.listenerRemovers.push(removeFn);
    }

    removeEventListeners() {
        for (const fn of this.listenerRemovers) {
            fn();
        }
        this.listenerRemovers = [];
    }

    registerDispatcher(dispatcher) {
        this.dispatcher = dispatcher;

        this.dispatcherUnsub = [];
        this.dispatcherUnsub.push(
            this.dispatcher.subscribe('entitySelectionChanged', () => {
                this.handleSelectionChanged();
            }),
        );
        this.dispatcherUnsub.push(
            this.dispatcher.subscribe('entityHighlightChanged', () => {
                this.handleHighlightChanged();
            }),
        );

        this.onReady().then(() => {
            this.handleHighlightChanged();
            this.handleSelectionChanged();
        });
    }

    registerKeyDownListener(fn) {
        this.keyDownListeners.push(fn);

        const unsub = () => {
            _.remove(this.keyDownListeners, (i) => i === fn);
        };

        return unsub;
    }

    saveCameraState() {
        const camera = _.assign(
            {},
            {
                cameraCenter: new THREE.Vector3().copy(this.cameraCenter),
                cameraTheta: this.cameraTheta,
                cameraPhi: this.cameraPhi,
                viewportScale: this.viewportScale,
            },
        );

        return camera;
    }

    restoreCameraState(camera) {
        _.assign(this, camera);
        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }

    async setCameraState(camera) {
        this.cameraTheta = camera.cameraTheta;
        this.cameraPhi = camera.cameraPhi;
        this.viewportScale = camera.viewportScale;
        this.recomputePrimaryCamera();
        this.dirtyFrame();
        return this.cameraUpdateCompleted();
    }

    setRenderOverrides(preferences) {
        if (preferences) {
            this.overrides = _.assign({}, preferences);
        } else {
            this.overrides = {};
        }
    }

    renderDesign(design, forceBase = false) {
        this.design = design;
        this.needsUpdateDesign = true;
        this.updateDesignForceBase = forceBase;
        this.dirtyFrame();
    }

    clearDesign() {}

    async renderingCompleted() {
        const overlayRenderPromises = [];
        for (const overlay of this.design.project.overlays) {
            if (overlay.visible) {
                const renderable = this.objectRenderMap.get(overlay);
                overlayRenderPromises.push(renderable.ready());
            }
        }
        await Promise.all(overlayRenderPromises);
        const tileHelper = await Promise.resolve(this.tileHelperReady.promise);

        await tileHelper.visibleTilesRendered();

        return true;
    }

    updateForScreenshot(design, settings) {
        // 1. modules
        for (const fs of design.field_segments) {
            if (settings.modules) {
                this.renderModules(fs);
            } else {
                const fsRenderer = this.objectRenderMap.get(fs);
                if (fsRenderer.rackingRenderable) fsRenderer.rackingRenderable.clearRenderable();
            }
        }

        // Force rendering wiring if there is none
        let wiringRenderable = this.objectRenderMap.get(design);
        if (!wiringRenderable) {
            this.renderWiringTree(design);
            wiringRenderable = this.objectRenderMap.get(design);
        }

        //
        wiringRenderable.setRenderableOptions({ projection: this.primaryCameraType });

        // 2. Wiring
        if (settings.wiring) {
            wiringRenderable.renderSubRenderables('wiring');
        } else {
            wiringRenderable.clearSubRenderables('string');
        }

        // 3. Inverters
        if (settings.inverters) {
            wiringRenderable.renderSubRenderables('inverter', settings.wiring);
        } else {
            wiringRenderable.clearSubRenderables('inverter', settings.wiring);
        }

        // 4. Interconnect
        if (settings.interconnect) {
            wiringRenderable.renderSubRenderables('interconnect', settings.wiring);
        } else {
            wiringRenderable.clearSubRenderables('interconnect', settings.wiring);
        }

        // 5. Combiners
        if (settings.combiners) {
            wiringRenderable.renderSubRenderables('combiner', settings.wiring);
            wiringRenderable.renderSubRenderables('ac_panel', settings.wiring);
        } else {
            wiringRenderable.clearSubRenderables('combiner', settings.wiring);
            wiringRenderable.clearSubRenderables('ac_panel', settings.wiring);
        }

        // 6. Field Segments and Modules
        for (const fs of design.field_segments) {
            if (settings.field_segments) {
                const config = this.options.renderConfig;
                config.shadow.opacity = 0;
                this.renderFieldSegment(fs, config);
            } else {
                const fsRenderer = this.objectRenderMap.get(fs);
                fsRenderer.clearRenderable();
            }
        }

        // 7. Keepouts
        for (const ko of design.keepouts) {
            if (settings.keepouts) {
                // remove shadows
                const config = this.options.renderConfig;
                config.shadow.opacity = 0;
                this.renderSurface(ko, config);
            } else {
                const renderable = this.objectRenderMap.get(ko);
                renderable.clearRenderable();
            }
        }

        // 8. Overlays
        for (const overlay of design.project.overlays) {
            const renderable = this.objectRenderMap.get(overlay);
            const hasOverlay = _.get(settings, 'overlays', true);
            if (hasOverlay) {
                renderable.renderRenderable();
            } else {
                renderable.clearRenderable();
            }
        }

        // 9. Premades (ex: Trees)
        for (const premade of design.entity_premades) {
            const renderable = this.objectRenderMap.get(premade);
            const hasPremade = _.get(settings, 'premades', true);
            if (hasPremade) {
                renderable.renderRenderable();
            } else {
                renderable.clearRenderable();
            }
        }

        // clear the shadows from field_segments no matter what
        for (const fs of design.field_segments) {
            const fsRendererable = this.objectRenderMap.get(fs);
            fsRendererable.clearShadows();
        }
    }

    updateForPdf(design, settings) {
        // 1. Module
        for (const fs of design.field_segments) {
            const fsRenderer = this.objectRenderMap.get(fs);
            if (fsRenderer.rackingRenderable) fsRenderer.rackingRenderable.clearRenderable();
        }

        if (settings.modules) {
            for (const fs of design.field_segments) {
                const wiredModules = design.getFlattenedComponents({ module: true });
                const renderable = new RenderableRacking(this, fs);
                renderable.renderRenderable(MapConfig.module, wiredModules);
            }
        }

        // force wiring generation is there isn't one
        const wiringRenderable = this.objectRenderMap.get(design);
        if (
            !wiringRenderable &&
            (settings.inverters || settings.combiners || settings.wiring || settings.interconnect)
        ) {
            this.renderWiringTree(design);
        }

        // 2. Inverter
        if (!settings.inverters) {
            const renderable = this.objectRenderMap.get(design);
            renderable.clearSubRenderables('inverter');
        }

        // 3. Combiners
        if (!settings.combiners) {
            const renderable = this.objectRenderMap.get(design);
            renderable.clearSubRenderables('combiner');
            renderable.clearSubRenderables('ac_panel');
        }

        // 4. Wiring
        if (!settings.wiring) {
            const renderable = this.objectRenderMap.get(design);
            renderable.clearRenderable(false);
        }

        // 5. Interconnect
        if (!settings.interconnect) {
            const renderable = this.objectRenderMap.get(design);
            renderable.clearSubRenderables('interconnect');
        }

        // 6. Field Segments & Module
        if (!settings.field_segments) {
            for (const fs of design.field_segments) {
                const fsRenderer = this.objectRenderMap.get(fs);
                fsRenderer.clearRenderable();
            }
        }

        // clear the shadows from field_segments no matter what
        for (const fs of design.field_segments) {
            const fsRendererable = this.objectRenderMap.get(fs);
            fsRendererable.clearShadows();
        }

        // 7. Keepouts
        if (!settings.keepouts) {
            for (const ko of design.keepouts) {
                const renderable = this.objectRenderMap.get(ko);
                renderable.clearRenderable();
            }
        }
        // 8. Overlay
        if (!_.get(settings, 'overlays', true)) {
            for (const overlay of design.project.overlays) {
                const renderable = this.objectRenderMap.get(overlay);
                renderable.clearRenderable();
            }
        }

        // 9. Premade (ex: tree)
        if (!_.get(settings, 'premades', true)) {
            for (const premade of design.entity_premades) {
                const renderable = this.objectRenderMap.get(premade);
                renderable.clearRenderable();
            }
        }

        const groundRenderer = this.objectRenderMap.get(THE_GROUND);
        groundRenderer.clearRenderable();
    }

    updateProjection(projection) {
        if (this.primaryCameraType === projection) {
            return;
        }

        this.primaryCameraType = projection;
        this.tileHelper.clearAllData();

        if (projection === 'Perspective') {
            this.primaryCamera = new THREE.PerspectiveCamera();
            this.screenSpaceCamera = new THREE.PerspectiveCamera();
        } else {
            this.primaryCamera = new THREE.OrthographicCamera();
            this.screenSpaceCamera = new THREE.OrthographicCamera();
        }

        this.screenSpaceCamera.near = -9999;
        this.screenSpaceCamera.far = 9999;
        this.screenSpaceCamera.lookAt(new THREE.Vector3(0, 0, 0));
        this.screenSpaceCamera.position.z = 1;

        this.tileHelper.renderMapTiles();
    }

    broadcastCameraSettings() {
        const cameraSettings = {
            cameraCenter: this.cameraCenter,
            cameraTheta: this.cameraTheta,
            cameraPhi: this.cameraPhi,
            viewportScale: this.viewportScale,
        };

        this.dispatcher.publish('cameraChange', cameraSettings, false);
    }

    zoom(obj) {
        if (Array.isArray(obj)) {
            const combined = _.flatten(_.map(obj, (i) => i.zoomPath()));
            const projBounds = new Bounds(combined);
            this.fitBoundsXY(projBounds);
        } else {
            const projBounds = new Bounds(obj.zoomPath());
            this.fitBoundsXY(projBounds);
        }
    }

    zoomCenter(delta) {
        this.viewportScale = _.clamp(
            this.viewportScale * 1.05 ** (delta * 0.01),
            RendererOptions.viewOptions.minZoom,
            RendererOptions.viewOptions.maxZoom,
        );
        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }

    getProjectBounds() {
        const projPoints = this.design.zoomPath(this.dispatcher.showWiring);
        if (!projPoints.length) {
            projPoints.push(new Vector(-50, -50));
            projPoints.push(new Vector(50, 50));
        }

        return new Bounds(projPoints);
    }

    fitProjectView(minBuffer = 4) {
        this.fitBoundsXY(this.getProjectBounds(), minBuffer);
    }

    fitBoundsXY(bounds, minBuffer = 4) {
        if (bounds.isNaN() || !bounds.isFinite()) {
            return;
        }

        const projMidpoint = bounds.midpoint;
        this.cameraCenter = new THREE.Vector3(projMidpoint.x, projMidpoint.y, 0);
        this.cameraTheta = 0;
        this.cameraPhi = 0;
        this.viewportScale = this.getViewportScale(bounds, minBuffer);

        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }

    getViewportScale(bounds = this.getProjectBounds(), minBuffer = 4) {
        const xScale = (bounds.width + minBuffer * 2) / this.container.clientWidth;
        const yScale = (bounds.height + minBuffer * 2) / this.container.clientHeight;
        return _.clamp(
            Math.max(xScale, yScale),
            RendererOptions.viewOptions.minZoom,
            RendererOptions.viewOptions.maxZoom,
        );
    }

    zoomScene(minBuffer = 4) {
        const matrixWorldInverse = new THREE.Matrix3().setFromMatrix4(this.primaryCamera.matrixWorldInverse);

        const camBounds = new Bounds(
            this.design.zoomPath(this.dispatcher.showWiring).map((pt) => pt.toThree().applyMatrix3(matrixWorldInverse)),
        );

        this.viewportScale = this.getViewportScale(camBounds, minBuffer);
        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }

    overrideRightClick(fn) {
        // TODO: MT: when 2d goes away, rework module toggling, this is gross
        if (this._overrideRightClickFn !== undefined) {
            logger.warn('Already have right click handler defined');
        }

        this._overrideRightClickFn = (evt) => {
            const data = {};
            const clientPt = containerPoint(this, evt);
            const ray = interactRay(this, clientPt);
            const ps = bestParentSurfaceRay(null, ray, this.design.physicalSurfaces());
            const intersect = ps ? raySurfaceIntersect(ps, ray) : rayGroundPlaneIntersect(ray);
            data.location = new Vector(intersect.x, intersect.y);
            return fn(data);
        };

        const unsub = () => {
            delete this._overrideRightClickFn;
        };

        return unsub;
    }

    lidarAvailable() {
        const lidarHelper = this.lidarHelper;
        if (!lidarHelper) return false;
        return lidarHelper.lidarData && lidarHelper.lidarData.points && lidarHelper.lidarData.points.length;
    }

    lidarPoints() {
        return this.lidarHelper && this.lidarHelper.lidarData && this.lidarHelper.getOffsetAdjustedPoints();
    }

    toXY(latLng) {
        if (Array.isArray(latLng)) {
            return latLng.map((ll) => this.toXY(ll));
        }

        return this.design.project.location.gridOffsets(latLng);
    }

    toLatLng(point) {
        if (Array.isArray(point)) {
            return point.map((pt) => this.toLatLng(pt));
        }

        return this.design.project.location.offsetVector(point);
    }

    registerRenderable(entity, renderable) {
        if (!renderable) this.renderHighlights.delete(entity);

        const prev = this.objectRenderMap.get(entity);
        if (prev && prev !== renderable) {
            if (prev.clearChildRenderables) prev.clearChildRenderables();
            prev.clearRenderable();
        }

        this.objectRenderMap.set(entity, renderable);

        this.dirtyFrame();
    }

    renderWiringTree(design) {
        const renderable = new RenderableWiringTree(this, design);

        this.registerRenderable(design, renderable);
        this.setUnwiredModuleColors(design);
        renderable.renderRenderable(_.assign({}, this.options, this.overrides));
    }

    clearEntity(entity) {
        if (entity instanceof FieldSegment || entity instanceof Keepout || entity instanceof EntityPremade) {
            this.registerRenderable(entity, null);
        }
    }

    clearOverlay(overlay) {
        const renderer = this.objectRenderMap.get(overlay);
        if (!renderer) {
            logger.warn(`Attempted to clear ${overlay}, but it has no renderer`);
            return;
        }
        renderer.clearRenderable();
        this.objectRenderMap.delete(overlay);
        this.dispatcher.overlaysDirty = true;
        this.dirtyFrame();
    }

    clearSubpolygons(obj) {
        // TODO: MT: this weirdness is because the design-renderer separation is not very clean
        // ideally we should make it so that the design is the only thing that tracks object lifetimes and hierarchies
        // i.e. the racking to field segment relationship, wiring component to design relationship, etc.
        // and renderables simply come and go with the associated object without weird stuff like clearSubPolygons()
        const renderable = this.objectRenderMap.get(obj);

        if (obj instanceof Design) {
            this.removeUnwiredModuleHighlight(obj);
        }

        if (renderable) {
            renderable.clearChildRenderables();
            this.dirtyFrame();
        }
    }

    highlightFieldSegment(fs, highlight) {
        const config = this.options.renderConfig;

        const renderable = this.objectRenderMap.get(fs);
        if (renderable && !this.dispatcher.selectedEntities.has(fs)) {
            if (highlight) {
                renderable.updateSurfaceOptions(_.assign({}, renderable.surfaceOptions, config.fieldSegment.hover));
            } else {
                renderable.updateSurfaceOptions(_.assign({}, renderable.surfaceOptions, config.fieldSegment.base));
            }
        }
    }

    highlightKeepout(ko, highlight) {
        const config = this.options.renderConfig;

        const renderable = this.objectRenderMap.get(ko);
        if (renderable && !this.dispatcher.selectedEntities.has(ko)) {
            if (highlight) {
                renderable.updateSurfaceOptions(_.assign({}, renderable.surfaceOptions, config.keepout.hover));
            } else {
                renderable.updateSurfaceOptions(_.assign({}, renderable.surfaceOptions, config.keepout.base));
            }
        }
    }

    highlightEntity(entity, highlight) {
        const renderable = this.objectRenderMap.get(entity);
        if (renderable && this.dispatcher.selectedEntity !== entity) {
            renderable.updateRenderable({ highlight });
        }
    }

    setModuleRenderOptions(modules, options, redraw = true) {
        const redrawFieldSegments = new Set();

        if (Array.isArray(modules)) {
            for (const module of modules) {
                this.setModuleRenderOptions(module, options, false);
                redrawFieldSegments.add(module.fieldSegment);
            }
        } else {
            const renderable = this.objectRenderMap.get(modules.fieldSegment);
            if (renderable && renderable.rackingRenderable) {
                renderable.rackingRenderable.setModuleOptions(modules, options);
            }

            redrawFieldSegments.add(modules.fieldSegment);
        }

        if (!redraw) return;

        for (const fs of redrawFieldSegments) {
            if (this.deferredRenderModule.indexOf(fs) === -1) {
                this.deferredRenderModule.push(fs);
                this.dirtyFrame();
            }
        }
    }

    setUnwiredModuleColors(design) {
        const unwiredModules = design.getUnwiredModules();

        this.setModuleRenderOptions(this.prevUnwiredModules);
        this.prevUnwiredModules = unwiredModules;

        this.setModuleRenderOptions(unwiredModules, { strokeColor: 'gold' });
    }

    removeUnwiredModuleHighlight() {
        // To reset the color of the modules when Mechanical Tab is selected in case there are unwired modules
        // and are highlighted to a different when switched between Mechanical and Electrical Tabs
        this.setModuleRenderOptions(this.prevUnwiredModules);
    }

    /**
     * Updates the color of all modules using a module color map or a single color
     *
     * @param { undefined | string | Map<string, string> } moduleColor
     * A string representing a single color for all modules or a map of module ids to colors
     * @returns void
     */
    setModuleColors(moduleColor) {
        const moduleColorConfig = typeof moduleColor === 'string' ? { fillColor: moduleColor } : MapConfig.module.base;
        const fieldModuleMap = this.design.getFieldModuleMap();

        if (!fieldModuleMap) {
            return;
        }

        if (moduleColor && typeof moduleColor === 'object') {
            for (const [fieldModuleId, color] of Object.entries(moduleColor)) {
                const module = fieldModuleMap[fieldModuleId];
                if (!module) {
                    logger.warn('Module color data error, could not find module with id:', fieldModuleId);
                    continue;
                }
                this.setModuleRenderOptions(module, {
                    fillColor: color,
                });
            }
        } else {
            for (const module of Object.values(fieldModuleMap)) {
                this.setModuleRenderOptions(module, moduleColorConfig);
            }
        }
    }

    renderPoints(objrender, points) {
        if (!points || points.length === 0) {
            return;
        }
        const renderedPoints = [];
        for (let i = 0; i < points.length; i++) {
            const p = points[i];
            const p3d = new THREE.Vector3(p.x, p.y, 0);
            renderedPoints.push(p3d);
        }

        const geometry = makePointGeometry(renderedPoints);
        const material = this.dRenderer.inlineShaderMaterial('vertexShaderPoint', 'fragmentShaderPoint');

        const options = {
            geometry,
            material,
            scene: this.physicalSurfaceLayer,
            strokeWeight: 3.0,
        };

        objrender.subPrimitives.push(this.renderPrimitive(PrimitiveMeshStroke, options));
        this.dirtyFrame();
    }

    renderPath(points, options, objrender = this.getDesignObjRender(this.design)) {
        const geometry = makeWireGeometry(points);
        const material = this.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');
        const fullOptions = _.assign({ geometry, material, scene: this.wiringLayer }, options);
        const prim = this.renderPrimitive(PrimitiveMeshStroke, fullOptions);
        objrender.subPrimitives.push(prim);

        this.dirtyFrame();
    }

    renderModules(fs) {
        const config = this.options.renderConfig;

        const renderable = this.objectRenderMap.get(fs);
        if (!this.dispatcher.showModules || !renderable) {
            return;
        }

        if (renderable) {
            if (!renderable.rackingRenderable) renderable.rackingRenderable = new RenderableRacking(this, fs);
            renderable.rackingRenderable.setRenderableOptions({ projection: this.primaryCameraType });
            renderable.rackingRenderable.renderRenderable(config.module);
        }
    }

    getSurfaceConfiguration(surface) {
        const constructor = surface.constructor;

        let type;
        let renderOrder;
        let override = false;

        if (constructor === FieldSegment) {
            type = 'FieldSegment';
            renderOrder = 100;
            override = this.overrides.field_segments === false;
        } else if (constructor === Keepout) {
            type = 'Keepout';
            renderOrder = 110;
            override = this.overrides.keepouts === false;
        } else {
            type = 'Ground';
            override = this.overrides.field_segments === false;
            renderOrder = 90;
        }

        return {
            type,
            override,
            renderOrder,
        };
    }

    renderSurface(surface, options = { renderOptions: {} }) {
        // TODO: MT: it's weird to have render options specified by the dispatcher
        // specifics of rendering should be in the renderable
        // general state like highlighting, show drag handle, show edge length, etc should be set from outside
        const config = this.options.renderConfig;

        const { type, renderOrder, override } = this.getSurfaceConfiguration(surface);
        if (override) {
            return;
        }

        const selectionData =
            type === 'Ground'
                ? null
                : {
                      type,
                      selectionPriority: 100,
                      object: surface,
                  };

        let renderable = this.objectRenderMap.get(surface);
        if (!renderable) {
            renderable = new RenderableSurface(this, surface, type);
            this.registerRenderable(surface, renderable);
        }

        renderable.clearRenderable();

        const surfaceOptions = _.assign({}, renderable.surfaceOptions || {}, options.renderOptions, {
            selectionData: this.options.enableEditing ? selectionData : null,
            scene: this.physicalSurfaceLayer,
            renderOrder,
        });

        const renderableOptions = {
            surfaceOptions,
            shadowOptions: config.shadow,
            setbackOptions: config.setback,
        };

        renderable.renderRenderable(renderableOptions);
    }

    renderFieldSegment(fs, options) {
        this.renderSurface(fs, options);
    }

    renderKeepout(ko, options) {
        this.renderSurface(ko, options);
    }

    renderPremade(pmo, options = { renderOptions: {} }) {
        let objrender = this.objectRenderMap.get(pmo);
        if (!objrender) {
            objrender = new RenderablePremadeTreeSphere(this, pmo);
            this.objectRenderMap.set(pmo, objrender);
        }

        objrender.clearRenderable();
        objrender.renderRenderable(options ? options.renderOptions : null);

        this.dirtyFrame();
    }

    renderEntity(entity, selectedDraggable = false) {
        if (entity instanceof FieldSegment) {
            this.renderFieldSegment(entity, {
                renderOptions: selectedDraggable
                    ? MapConfig.fieldSegment.selectedDraggable
                    : MapConfig.fieldSegment.base,
            });
        }
        if (entity instanceof Keepout) {
            this.renderKeepout(entity, {
                renderOptions: selectedDraggable ? MapConfig.keepout.selectedDraggable : MapConfig.keepout.base,
            });
        }
        if (entity instanceof EntityPremade) {
            this.renderPremade(entity, {
                renderOptions: selectedDraggable ? MapConfig.premade.selectedDraggable : MapConfig.premade.base,
            });
        }
    }

    async renderOverlay({ overlay, options = {}, markDirty = false }) {
        let objRenderer = this.objectRenderMap.get(overlay);
        const ext = overlay.file.extension;

        const isKMLOverlay = isKMLExtension(ext);
        const isImageOverlay = isImageExtension(ext);

        if (!objRenderer) {
            if (isKMLOverlay) {
                objRenderer = new RenderableKMLOverlay(this, overlay);
            } else if (isImageOverlay) {
                objRenderer = new RenderableImageOverlay(this, overlay);
            } else {
                logger.warn(`unsupported overlay ${overlay}`);
                return;
            }
            this.objectRenderMap.set(overlay, objRenderer);
        }

        objRenderer.renderRenderable(options);

        if (markDirty) {
            this.dispatcher.overlaysDirty = true;
        }
        this.dirtyFrame();
    }

    renderParcel(parcel) {
        let objRenderer = this.objectRenderMap.get(parcel);
        if (!objRenderer) {
            objRenderer = new RenderableParcel(this, parcel);
            this.objectRenderMap.set(parcel, objRenderer);
        }
        objRenderer.renderRenderable();
        this.dirtyFrame();
    }

    mapInputFactory() {
        return new CreatePathInput(this);
    }

    preframeUpdate() {
        this.registerPreFrameCallback(this.preframeUpdate);

        this.cameraProjectionMatrix = new THREE.Matrix4().multiplyMatrices(
            this.primaryCamera.projectionMatrix,
            this.primaryCamera.matrixWorldInverse,
        );

        // if (this.infoHelperGrid) this.infoHelperGrid.update();
        if (this.infoHelperAxisWidget) this.infoHelperAxisWidget.update();

        if (this.updateCamera && this.activeDragAction && this.activeDragAction.dragRedraw) {
            this.activeDragAction.dragRedraw();
        }

        if (this.updateCamera && this.activeTool && this.activeTool.toolRedraw) {
            this.activeTool.toolRedraw();
        }

        if (this.updateCamera && this.tileHelper) {
            this.tileHelper.renderMapTiles();
        }

        if (this.lidarHelper) {
            this.lidarHelper.lidarTick();
        }

        for (const fs of this.deferredRenderModule) {
            this.renderModules(fs);
        }
        // perf: re-use array instead of allocating a new one
        this.deferredRenderModule.length = 0;

        // update design geometry
        if (this.needsUpdateDesign) {
            this.needsUpdateDesign = false;
            this.updateDesignInternal();
        }
    }

    updateDesignInternal() {
        const config = this.options.renderConfig;

        this.lightDir = this.lightDirectionAtTime('2015-12-22T12:00:00Z');

        for (const ps of this.design.field_segments) {
            if (this.updateDesignForceBase) {
                this.renderFieldSegment(ps, { renderOptions: config.fieldSegment.base });
            } else {
                this.renderFieldSegment(ps);
            }

            if (ps.module_characterization) {
                this.renderModules(ps);
            }
        }

        for (const ps of this.design.keepouts) {
            if (this.updateDesignForceBase) {
                this.renderKeepout(ps, { renderOptions: config.keepout.base });
            } else {
                this.renderKeepout(ps);
            }
        }

        for (const pm of this.design.entity_premades) {
            this.renderPremade(pm, { renderOptions: { editable: false } });
        }

        for (const overlay of this.design.project.overlays) {
            this.renderOverlay({ overlay });
        }

        this.renderSurface(THE_GROUND);
    }

    async setImagerySource(sourceId) {
        const tileLayer = _.find(MAP_TILE_LAYERS, (i) => i.id === sourceId);
        if (!tileLayer) {
            throw Error(`no such imagery service ${sourceId}`);
        }

        this.tileLayerName = tileLayer.name;

        if (this.tileLayer === tileLayer) {
            return;
        }

        if (this.tileHelper) {
            this.tileHelper.clearAllData();
            this.tileHelper = null;
            this.tileHelperReady = this.getTileHelperPromise();
        }

        const service = await initService(tileLayer, this.design.project);
        const loginStatus = await service.login(this.toLatLng({ x: 0, y: 0 }));

        if ('restricted_region' in loginStatus) {
            showRestrictedRegionDialog(service, loginStatus);
            // reinitialize and force reset map to google_satellite
            this.setImagerySource('google_satellite', true);
            return;
        }

        // if we couldn't login, show dialog and revert to google
        if (!loginStatus.loggedIn) {
            showLoginRequiredDialog(service, loginStatus);

            // reinitialize and force reset map to google_satellite
            this.setImagerySource('google_satellite', true);
            return;
        }

        this.tileLayer = tileLayer;
        this.tileHelper = new TileHelper(this, service);

        this.dirtyCamera();
        this.dirtyFrame();

        this.dispatcher.publish('tileLayerChange', { tileLayer: sourceId, design: this.dispatcher.design });
        this.tileHelperReady.resolve(this.tileHelper);
    }

    objectIntersectMain(pt) {
        const ray = this.transformClientToWorldRay(pt);
        const rayCaster = new THREE.Raycaster();
        rayCaster.set(ray.origin, ray.dir);
        rayCaster.params.Line.threshold = 5.0 * this.viewportScale;
        const intersects = rayCaster.intersectObjects(
            _.filter(
                this.selectionLayerScene.children,
                (obj) => obj.userData && obj.userData.interactData && !obj.userData.noRaycast,
            ),
        );

        // do we need to deal with the degenerate case where one object is entirely inside another?
        // if (this.viewMode === 'wire') {
        //     intersects = _.reverse(intersects);
        // }
        return _.sortBy(intersects, (i) => i.object.userData.interactData.selectionPriority);
    }

    objectIntersectInteract(pt) {
        const rayCaster = new THREE.Raycaster(new THREE.Vector3(pt.x, pt.y, -9999), new THREE.Vector3(0, 0, 1));
        const intersects = rayCaster.intersectObjects(
            _.filter(this.interactLayer.children, (obj) => obj.userData && !obj.userData.noRaycast),
        );
        return intersects;
    }

    activateDragAction(action) {
        this.mouseCapture = true;
        this.activeDragAction = action;
    }

    cancelDragAction() {
        this.mouseCapture = false;
        this.activeDragAction = null;
    }

    renderEntityHighlight(entity, highlight) {
        if (entity instanceof EntityPremade) {
            _.defer(() => this.highlightEntity(entity, highlight));
        } else if (entity instanceof FieldSegment) {
            _.defer(() => this.highlightFieldSegment(entity, highlight));
        } else if (entity instanceof Keepout) {
            _.defer(() => this.highlightKeepout(entity, highlight));
        }
    }

    renderEntitySelection(entity, selectionState = SelectionState.UNSELECTED) {
        if (!entity.$registered()) return; // entity has already been deleted

        let renderFn;
        let renderOptions;
        let surface = null;

        if (entity instanceof FieldSegment) {
            renderFn = 'renderFieldSegment';
            renderOptions = MapConfig.fieldSegment[selectionState];
            surface = entity;
        } else if (entity instanceof Keepout) {
            renderFn = 'renderKeepout';
            renderOptions = MapConfig.keepout[selectionState];
            surface = entity;
        } else if (entity instanceof EntityPremade) {
            this.renderPremade(entity, {
                renderOptions: {
                    editable: MapConfig.premade[selectionState].editable,
                    fillColor: MapConfig.premade[selectionState].fillColor,
                },
            });
        } else if (entity instanceof Overlay) {
            this.renderOverlay({
                overlay: entity,
                options: {
                    renderOnTop: MapConfig.overlay[selectionState].renderOnTop,
                    renderEditWidgets: MapConfig.overlay[selectionState].editable,
                },
            });
        } else {
            return;
        }

        if (surface) {
            this[renderFn](surface, { renderOptions, updatePath: false });
        }
    }

    handleHighlightChanged() {
        for (const prev of this.renderHighlights) {
            if (!this.dispatcher.highlightedEntities.has(prev)) {
                this.renderEntityHighlight(prev, false);
                this.renderHighlights.delete(prev);
            }
        }

        for (const curr of this.dispatcher.highlightedEntities) {
            if (!this.renderHighlights.has(curr)) {
                this.renderEntityHighlight(curr, true);
                this.renderHighlights.add(curr);
            }
        }
    }

    handleSelectionChanged() {
        if (!this.renderSelection) {
            this.renderSelection = new Set();
        }

        const previousRenderSelection = new Set(this.renderSelection);
        previousRenderSelection.forEach((entity) => {
            if (!this.dispatcher.selectedEntities.has(entity)) {
                this.renderEntitySelection(entity, SelectionState.UNSELECTED);
                this.renderSelection.delete(entity);
            }
        });

        const multiSelect = this.dispatcher.selectedEntities.size > 1;
        this.dispatcher.selectedEntities.forEach((entity) => {
            if (!this.renderSelection.has(entity)) {
                this.renderSelection.add(entity);
                this.renderEntitySelection(
                    entity,
                    multiSelect ? SelectionState.SELECTED_DRAGGABLE : SelectionState.SELECTED,
                );
            } else {
                this.renderEntitySelection(
                    entity,
                    multiSelect ? SelectionState.SELECTED_DRAGGABLE : SelectionState.SELECTED,
                );
            }
        });
    }

    onRendererMouseDownLeft(event) {
        this.mouseCapture = true;
        this.activeTool.toolMouseDown(event);
        event.preventDefault();
    }

    onRendererMouseDownRight(event) {
        this.mouseCapture = true;
        this.activeTool.toolMouseDown(event);
        event.preventDefault();
    }

    onRendererMouseUp(event) {
        if (!this.mouseCapture) {
            this.activeTool.toolMouseUp(event);
            event.preventDefault();
        }
    }

    onRendererMouseMove(event, force = false) {
        if (!this.mouseCapture || force) {
            this.activeTool.toolMouseMove(event);
            this.currentMousePosition = containerPoint(this, event);
            event.preventDefault();
        }
    }

    onRendererKeyDown(event) {
        if (this.activeDragAction) {
            if (this.activeDragAction.dragKeyDown) {
                if (this.activeDragAction.dragKeyDown(event)) event.preventDefault();
            }
        } else {
            let handled = false;
            for (const fn of this.keyDownListeners) {
                if (fn(event)) handled = true;
            }

            if (!handled && this.activeTool.toolKeyDown) {
                if (this.activeTool.toolKeyDown(event)) event.preventDefault();
            }
        }
    }

    onRendererMouseOut(event) {
        if (!this.mouseCapture) {
            this.activeTool.toolMouseOut(event);
            event.preventDefault();
        } else if (this.activeDragAction) {
            if (this.activeDragAction.dragMouseOut && this.activeDragAction.dragMouseOut(event)) {
                this.cancelDragAction();
            }
        }
    }

    onRendererMouseWheel(event) {
        this.activeTool.toolMouseWheel(event);
        event.preventDefault();
    }

    onRendererDblClick(event) {
        this.activeTool.toolDblClick(event);
        event.preventDefault();
    }

    onDocumentMouseUp(event) {
        if (this.mouseCapture) {
            if (this.activeDragAction) {
                this.activeDragAction.dragMouseUp(event);
                this.cancelDragAction();
            } else {
                this.mouseCapture = false;
                this.onRendererMouseUp(event);
            }
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onDocumentMouseMove(event) {
        if (this.mouseCapture) {
            if (this.activeDragAction) {
                if (this.activeDragAction.dragMouseMove(event)) {
                    this.cancelDragAction();
                }
            } else {
                this.onRendererMouseMove(event, true);
            }
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onDocumentKeyDown(event) {
        this.onRendererKeyDown(event, true);
    }

    resourceUsage() {
        return { loading_tiles: _.get(this, 'tileHelper.loadingTiles', 0) };
    }

    initCursorRendering() {
        // Initializes rendering of primitives on the cursor tool
        if (
            this.activeTool instanceof InteractToolCameraPanZoomSelect ||
            this.activeTool instanceof InteractToolCreatePhysicalSurface ||
            this.activeTool instanceof InteractToolCreatePremade
        ) {
            this.activeTool.initCursorRendering();
            this.setCursorStyle(CursorConfig.PASTE);
        }
    }

    clearSurfaceCursorPrimitives() {
        // Removes any rendering of primitives on the cursor tool
        if (this.activeTool) {
            this.activeTool.cursorHelper.clearSurfaceCursorPrimitives();
            this.setCursorStyle(CursorConfig.DEFAULT);
        }
    }

    setCursorStyle(style) {
        this.container.style.cursor = style;
    }
}
