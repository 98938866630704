import { WiringZone } from './WiringZone';

export function makeWiringZone(design, profile) {
    const wiringZones = design.wiring_zones;
    const lastWiringZone = wiringZones[wiringZones.length - 1];
    const wiringZone = _.merge(
        { design_id: design.design_id }, // ensures you have a design id, even if there isn't a previous wiring zone
        profile,
        lastWiringZone,
        {
            description: `Wiring Zone ${wiringZones.length + 1}`,
            wiring_zone_id: null,
        }, // need to remove the wiring zone id form teh clone
    );

    return new WiringZone(wiringZone);
}
