import _ from 'lodash';
import * as THREE from 'three';
import earcut from 'earcut';

import {
    Plane,
    Vector,
    makeEarcutPath,
    makeEarcutPathsWithHoles,
    simplifyPaths,
} from 'helioscope/app/utilities/geometry';
import { arrayItemWrap } from 'helioscope/app/utilities/helpers';
import { AABB3, paths3to2 } from 'helioscope/app/utilities/geometry/geo3';
import {
    cachedIterateCylinderQuads,
    cachedIterateSphereQuads,
    cachedIterateConeQuads,
} from 'helioscope/app/utilities/geometry/shape3';
import { convertColorToRGB } from '../utilities/colors';

function earcutInputToVector(earcutInput, index) {
    return new Vector(earcutInput[index * 3], earcutInput[index * 3 + 1], earcutInput[index * 3 + 2]);
}

function makeColorPoint(pt, color) {
    const vec = new Vector(pt.x, pt.y, pt.z);
    vec.color = color;
    return vec;
}

function vec3Color(color) {
    const colorV = new THREE.Vector3(1, 1, 1);
    if (color) {
        const threecolor = new THREE.Color(convertColorToRGB(color));
        colorV.set(threecolor.r, threecolor.g, threecolor.b);
    }
    return colorV;
}

class BufferWriter {
    constructor(width, count) {
        this.width = width;
        this.array = new Float32Array(count * width);
        this.index = 0;

        if (width === 1) {
            this.pushBuffer = (value) => {
                this.array[this.index] = value;
                this.index++;
            };
        } else if (width === 2) {
            this.pushBuffer = (vec) => {
                this.array[this.index] = vec.x;
                this.array[this.index + 1] = vec.y;
                this.index += 2;
            };
        } else if (width === 3) {
            this.pushBuffer = (vec) => {
                this.array[this.index] = vec.x;
                this.array[this.index + 1] = vec.y;
                this.array[this.index + 2] = vec.z;
                this.index += 3;
            };
        }
    }
}

function generateSegmentPoints(path, includeClosingSegment) {
    const pts = [];
    for (let i = 0; i < path.length - 1; ++i) {
        pts.push(path[i]);
        pts.push(path[i + 1]);
    }

    if (includeClosingSegment) {
        pts.push(path[path.length - 1]);
        pts.push(path[0]);
    }

    return pts;
}

export function pathToSegmentPoints(path) {
    const pts = [];
    for (let i = 0; i < path.length; ++i) {
        pts.push(path[i]);
        pts.push(arrayItemWrap(path, i + 1));
    }

    return pts;
}

export function pathToPolygonPoints(path) {
    return generateSegmentPoints(path, true);
}

export function pathToLinePoints(path) {
    return generateSegmentPoints(path, false);
}

export function removeInstance(instance) {
    if (instance) {
        if (instance.geometry && !instance.geometry._external && instance.geometry.dispose) {
            instance.geometry.dispose();
        }

        instance.parent.remove(instance);
    }
    return null;
}

export function applySolidInstanceStandardOptions(instance, options) {
    const fillColor = convertColorToRGB(options.fillColor);
    const fillOpacity = options.fillOpacity !== undefined ? options.fillOpacity : 1.0;
    const opacity = options.opacity !== undefined ? options.opacity : 1.0;
    instance.material.uniforms.color.value.set(fillColor);
    instance.material.uniforms.alpha.value = fillOpacity * opacity;
    instance.material.visible = instance.material.uniforms.alpha.value > 0.0;
    instance.material.transparent = instance.material.uniforms.alpha.value < 1.0;
}

export function applyWireInstanceStandardOptions(instance, options) {
    const strokeColor = convertColorToRGB(options.strokeColor);
    const strokeOpacity = options.strokeOpacity !== undefined ? options.strokeOpacity : 1.0;
    const opacity = options.opacity !== undefined ? options.opacity : 1.0;
    instance.material.uniforms.color.value.set(strokeColor);
    instance.material.uniforms.alpha.value = strokeOpacity * opacity;
    instance.material.visible = instance.material.uniforms.alpha.value > 0.0;
    instance.material.transparent = instance.material.uniforms.alpha.value < 1.0;
    instance.material.uniforms.weight.value = options.strokeWeight;
}

/*
 * useful for eg LineSegments raycasting
 */
export function makeRawGeometry(points) {
    const geometry = new THREE.BufferGeometry();

    const positions = new BufferWriter(3, points.length);

    for (let i = 0; i < points.length; i += 1) {
        const pt = points[i];
        positions.pushBuffer(pt);
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));

    geometry.computeBoundingSphere();
    return geometry;
}

export function makePointGeometry(points) {
    const geometry = new THREE.BufferGeometry();

    const positions = new BufferWriter(3, points.length * 2 * 3);
    const rotations = new BufferWriter(1, points.length * 2 * 3);

    for (let i = 0; i < points.length; i += 1) {
        const pt1 = points[i];

        rotations.pushBuffer(Math.PI * 0.25);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 0.75);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * -0.25);
        positions.pushBuffer(pt1);

        rotations.pushBuffer(Math.PI * 1.25);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 1.75);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 0.75);
        positions.pushBuffer(pt1);
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.setAttribute('rotation', new THREE.BufferAttribute(rotations.array, 1));

    geometry.computeBoundingSphere();
    return geometry;
}

export function makeHeightMapGeometrySolid(minXY, maxXY, width, height, values) {
    const buffergeo = new THREE.BufferGeometry();

    const vertices = width * height * 6;

    const positions = new BufferWriter(3, vertices);
    const uvs = new BufferWriter(2, vertices);
    const normals = new BufferWriter(3, vertices);

    const dx = (maxXY.x - minXY.x) / width;
    const dy = (maxXY.y - minXY.y) / height;

    for (let j = 0; j < height - 1; ++j) {
        for (let i = 0; i < width - 1; ++i) {
            const k0 = j * width + i;
            const pt0 = new THREE.Vector3(minXY.x + dx * i, minXY.y + dy * j, values[k0]);
            const k1 = j * width + i + 1;
            const pt1 = new THREE.Vector3(minXY.x + dx * (i + 1), minXY.y + dy * j, values[k1]);
            const k2 = (j + 1) * width + i + 1;
            const pt2 = new THREE.Vector3(minXY.x + dx * (i + 1), minXY.y + dy * (j + 1), values[k2]);
            const k3 = (j + 1) * width + i;
            const pt3 = new THREE.Vector3(minXY.x + dx * i, minXY.y + dy * (j + 1), values[k3]);

            const norm1 = new THREE.Vector3()
                .subVectors(pt1, pt0)
                .cross(new THREE.Vector3().subVectors(pt2, pt1))
                .normalize();
            const norm2 = new THREE.Vector3()
                .subVectors(pt2, pt0)
                .cross(new THREE.Vector3().subVectors(pt3, pt2))
                .normalize();
            const norm = norm1.add(norm2).multiplyScalar(0.5).normalize();

            const uv0 = new THREE.Vector2(i / width, j / height);
            const uv1 = new THREE.Vector2((i + 1) / width, j / height);
            const uv2 = new THREE.Vector2((i + 1) / width, (j + 1) / height);
            const uv3 = new THREE.Vector2(i / width, (j + 1) / height);

            positions.pushBuffer(pt0);
            uvs.pushBuffer(uv0);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt1);
            uvs.pushBuffer(uv1);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt2);
            uvs.pushBuffer(uv2);
            normals.pushBuffer(norm);

            positions.pushBuffer(pt0);
            uvs.pushBuffer(uv0);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt2);
            uvs.pushBuffer(uv2);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt3);
            uvs.pushBuffer(uv3);
            normals.pushBuffer(norm);
        }
    }

    buffergeo.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    buffergeo.setAttribute('uv', new THREE.BufferAttribute(uvs.array, 2));
    buffergeo.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    buffergeo.computeBoundingSphere();
    return buffergeo;
}

export function makeWireGeometry(points) {
    const segments = points.length / 2;
    const geometry = new THREE.BufferGeometry();

    const positions = new BufferWriter(3, segments * 2 * 3);
    const endpoints = new BufferWriter(3, segments * 2 * 3);
    const rotations = new BufferWriter(1, segments * 2 * 3);

    for (let i = 0; i < points.length; i += 2) {
        const pt1 = points[i];
        const pt2 = points[i + 1];

        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt2);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 1.5);
        endpoints.pushBuffer(pt2);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt1);
        positions.pushBuffer(pt2);

        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt1);
        positions.pushBuffer(pt2);
        rotations.pushBuffer(Math.PI * 1.5);
        endpoints.pushBuffer(pt1);
        positions.pushBuffer(pt2);
        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt2);
        positions.pushBuffer(pt1);
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.setAttribute('endpoint', new THREE.BufferAttribute(endpoints.array, 3));
    geometry.setAttribute('rotation', new THREE.BufferAttribute(rotations.array, 1));
    geometry.computeBoundingSphere();
    return geometry;
}

export function makeWireGeometryInstanced(points) {
    const geometry = new THREE.InstancedBufferGeometry();
    const position = new BufferWriter(3, 6);

    // z component interpolates between endpoints
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 1.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 1.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 1.0));

    const endpointWorld1 = new BufferWriter(3, points.length / 2);
    const endpointWorld2 = new BufferWriter(3, points.length / 2);

    for (let i = 0; i < points.length; i += 2) {
        endpointWorld1.pushBuffer(points[i]);
        endpointWorld2.pushBuffer(points[i + 1]);
    }

    geometry.maxInstancedCount = points.length / 2;
    // threejs seems to require a 'position' attribute?
    geometry.setAttribute('position', new THREE.BufferAttribute(position.array, 3));
    geometry.setAttribute('endpointWorld1', new THREE.InstancedBufferAttribute(endpointWorld1.array, 3));
    geometry.setAttribute('endpointWorld2', new THREE.InstancedBufferAttribute(endpointWorld2.array, 3));

    const bbox = new AABB3().fromPoints(points);
    geometry.boundingSphere = new THREE.Sphere(
        bbox.getBoxCentroid(),
        bbox.getBoxMax().distanceTo(bbox.getBoxMin()) * 0.5,
    );
    geometry.boundingBox = new THREE.Box3(bbox.getBoxMin(), bbox.getBoxMax());
    return geometry;
}

function makeColorWireGeometry(colorPoints) {
    const segments = colorPoints.length / 2;
    const geometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, segments * 2 * 3);
    const endpoints = new BufferWriter(3, segments * 2 * 3);
    const rotations = new BufferWriter(1, segments * 2 * 3);
    const colors = new BufferWriter(3, segments * 2 * 3);

    for (let i = 0; i < colorPoints.length; i += 2) {
        const pt1 = colorPoints[i];
        const pt2 = colorPoints[i + 1];
        const colorV1 = vec3Color(pt1.color);
        const colorV2 = vec3Color(pt2.color);

        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt2);
        colors.pushBuffer(colorV2);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 1.5);
        endpoints.pushBuffer(pt2);
        colors.pushBuffer(colorV2);
        positions.pushBuffer(pt1);
        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt1);
        colors.pushBuffer(colorV1);
        positions.pushBuffer(pt2);

        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt1);
        colors.pushBuffer(colorV1);
        positions.pushBuffer(pt2);
        rotations.pushBuffer(Math.PI * 1.5);
        endpoints.pushBuffer(pt1);
        colors.pushBuffer(colorV1);
        positions.pushBuffer(pt2);
        rotations.pushBuffer(Math.PI * 0.5);
        endpoints.pushBuffer(pt2);
        colors.pushBuffer(colorV2);
        positions.pushBuffer(pt1);
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.setAttribute('endpoint', new THREE.BufferAttribute(endpoints.array, 3));
    geometry.setAttribute('rotation', new THREE.BufferAttribute(rotations.array, 1));
    geometry.setAttribute('color_', new THREE.BufferAttribute(colors.array, 3));
    geometry.computeBoundingSphere();
    return geometry;
}

function makeColorWireGeometryInstanced(colorPoints) {
    const geometry = new THREE.InstancedBufferGeometry();
    const position = new BufferWriter(3, 6);

    // z component interpolates between endpoints
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 1.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 0.0));
    position.pushBuffer(new THREE.Vector3(0.0, -0.5, 1.0));
    position.pushBuffer(new THREE.Vector3(0.0, 0.5, 1.0));

    const endpointWorld1 = new BufferWriter(3, colorPoints.length / 2);
    const endpointWorld2 = new BufferWriter(3, colorPoints.length / 2);
    const endpointColor1 = new BufferWriter(3, colorPoints.length / 2);
    const endpointColor2 = new BufferWriter(3, colorPoints.length / 2);

    const colors = colorPoints.map((i) => vec3Color(i.color));

    for (let i = 0; i < colorPoints.length; i += 2) {
        endpointWorld1.pushBuffer(colorPoints[i]);
        endpointWorld2.pushBuffer(colorPoints[i + 1]);
        endpointColor1.pushBuffer(colors[i]);
        endpointColor2.pushBuffer(colors[i + 1]);
    }

    geometry.maxInstancedCount = colorPoints.length / 2;
    // threejs seems to require a 'position' attribute?
    geometry.setAttribute('position', new THREE.BufferAttribute(position.array, 3));
    geometry.setAttribute('endpointWorld1', new THREE.InstancedBufferAttribute(endpointWorld1.array, 3));
    geometry.setAttribute('endpointWorld2', new THREE.InstancedBufferAttribute(endpointWorld2.array, 3));
    geometry.setAttribute('endpointColor1', new THREE.InstancedBufferAttribute(endpointColor1.array, 3));
    geometry.setAttribute('endpointColor2', new THREE.InstancedBufferAttribute(endpointColor2.array, 3));

    const bbox = new AABB3().fromPoints(colorPoints);
    geometry.boundingSphere = new THREE.Sphere(
        bbox.getBoxCentroid(),
        bbox.getBoxMax().distanceTo(bbox.getBoxMin()) * 0.5,
    );
    geometry.boundingBox = new THREE.Box3(bbox.getBoxMin(), bbox.getBoxMax());
    return geometry;
}

export function makeCylinderGeometrySolid(phiSteps, radius, height) {
    const cylGeometry = new THREE.BufferGeometry();

    const totalVertices = phiSteps * 2 * 3;
    const positions = new BufferWriter(3, totalVertices);
    const normals = new BufferWriter(3, totalVertices);

    cachedIterateCylinderQuads(phiSteps, radius, height, (pt1, pt2, pt3, pt4) => {
        const n1 = pt3.clone().normalize();
        const n2 = pt4.clone().normalize();
        const n3 = n1;
        const n4 = n2;

        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n3);
        positions.pushBuffer(pt3);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);

        normals.pushBuffer(n2);
        positions.pushBuffer(pt2);
        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);
    });

    cylGeometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    cylGeometry.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    cylGeometry.computeBoundingSphere();
    return cylGeometry;
}

export function makeSphereGeometrySolid(thetaSteps, phiSteps, radius) {
    const sphGeometry = new THREE.BufferGeometry();

    const totalVertices = thetaSteps * phiSteps * 2 * 3;
    const positions = new BufferWriter(3, totalVertices);
    const normals = new BufferWriter(3, totalVertices);

    cachedIterateSphereQuads(thetaSteps, phiSteps, radius, (pt1, pt2, pt3, pt4) => {
        const n1 = pt1.clone().normalize();
        const n2 = pt2.clone().normalize();
        const n3 = pt3.clone().normalize();
        const n4 = pt4.clone().normalize();

        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n3);
        positions.pushBuffer(pt3);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);

        normals.pushBuffer(n2);
        positions.pushBuffer(pt2);
        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);
    });

    sphGeometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    sphGeometry.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    sphGeometry.computeBoundingSphere();
    return sphGeometry;
}

export function makeConeGeometrySolid(phiSteps, baseRadius, height) {
    const coneGeometry = new THREE.BufferGeometry();

    const totalVertices = phiSteps * 4 * 2 * 3;
    const positions = new BufferWriter(3, totalVertices);
    const normals = new BufferWriter(3, totalVertices);

    cachedIterateConeQuads(phiSteps, baseRadius, height, (pt1, pt2, pt3, pt4) => {
        const pt1pt3 = new THREE.Vector3().subVectors(pt1, pt3);
        const pt2pt4 = new THREE.Vector3().subVectors(pt2, pt4);

        const n1 = new THREE.Vector3(pt1pt3.z, -pt1pt3.y, -pt1pt3.x).normalize();
        const n2 = new THREE.Vector3(pt2pt4.z, -pt2pt4.y, -pt2pt4.x).normalize();
        const n3 = n1;
        const n4 = n2;

        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n3);
        positions.pushBuffer(pt3);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);

        normals.pushBuffer(n1);
        positions.pushBuffer(pt1);
        normals.pushBuffer(n4);
        positions.pushBuffer(pt4);
        normals.pushBuffer(n2);
        positions.pushBuffer(pt2);
    });

    coneGeometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    coneGeometry.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    coneGeometry.computeBoundingSphere();
    return coneGeometry;
}

export function makeMeshSegmentPoints(meshEntity) {
    const pts = [];
    for (const edge of meshEntity.edgeList) {
        pts.push(edge.vertex1);
        pts.push(edge.vertex2);
    }

    return pts;
}

export function makeMeshGeometrySolid(meshEntity) {
    const buffergeo = new THREE.BufferGeometry();

    const tricnt = meshEntity.faceList.reduce((sum, face) => sum + face.triangleList.length, 0);
    const totalVertices = tricnt * 3;
    const positions = new BufferWriter(3, totalVertices);
    const normals = new BufferWriter(3, totalVertices);

    for (const face of meshEntity.faceList) {
        const norm = face.plane.normal;
        for (let k = 0; k < face.triangleList.length; ++k) {
            const tri = face.triangleList[k];
            normals.pushBuffer(norm);
            positions.pushBuffer(tri.vertex1);
            normals.pushBuffer(norm);
            positions.pushBuffer(tri.vertex2);
            normals.pushBuffer(norm);
            positions.pushBuffer(tri.vertex3);
        }
    }

    buffergeo.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    buffergeo.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    buffergeo.computeBoundingSphere();
    return buffergeo;
}

export function makeTriangleListGeometrySolid(triangles, textured = false) {
    const buffergeo = new THREE.BufferGeometry();

    const tricnt = triangles.length;
    const totalVertices = tricnt * 3;
    const positions = new BufferWriter(3, totalVertices);
    const normals = new BufferWriter(3, totalVertices);
    const uvs = new BufferWriter(2, totalVertices);

    for (const tri of triangles) {
        let normal = tri.normal;
        if (!normal) {
            const d1 = tri.vertex2.clone().sub(tri.vertex1);
            const d2 = tri.vertex3.clone().sub(tri.vertex1);
            normal = d1.cross(d2).normalize();
        }

        normals.pushBuffer(normal);
        positions.pushBuffer(tri.vertex1);
        normals.pushBuffer(normal);
        positions.pushBuffer(tri.vertex2);
        normals.pushBuffer(normal);
        positions.pushBuffer(tri.vertex3);

        if (textured) {
            uvs.pushBuffer(tri.uv1);
            uvs.pushBuffer(tri.uv2);
            uvs.pushBuffer(tri.uv3);
        }
    }

    buffergeo.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    buffergeo.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    buffergeo.setAttribute('uv', new THREE.BufferAttribute(uvs.array, 2));
    buffergeo.computeBoundingSphere();
    return buffergeo;
}

export function makePhysicalSurfaceGeometrySolid(geometry, offset = new THREE.Vector3(0, 0, 0)) {
    // TODO: MT: perhaps switch over to indexed mesh
    // Note: Potentially extraneous code, path orientations should always be positive
    const buffergeo = new THREE.BufferGeometry();

    const paths = simplifyPaths([geometry.path_3d]);
    const indices = paths.map((path) => earcut(makeEarcutPath(path)));
    const totalIndices = _.sumBy(indices, (i) => i.length) + _.sumBy(paths, (i) => i.length) * 2 * 3;

    const positions = new BufferWriter(3, totalIndices);
    const normals = new BufferWriter(3, totalIndices);

    const topPlane = Plane.fromPath(geometry.path_3d);
    const pathsTop = paths.map((path) => topPlane.pathFromXYs(path));

    const botPlane = Plane.fromPath(geometry.base_3d);
    const pathsBot = paths.map((path) => botPlane.pathFromXYs(path));

    for (let k = 0; k < indices.length; ++k) {
        const pathTop = pathsTop[k];
        const pathBot = pathsBot[k];
        const subIndices = indices[k];

        for (let i = 0; i < subIndices.length; i += 3) {
            const pt1 = pathTop[subIndices[i]];
            const pt2 = pathTop[subIndices[i + 1]];
            const pt3 = pathTop[subIndices[i + 2]];
            const norm = new THREE.Vector3()
                .crossVectors(new THREE.Vector3().subVectors(pt2, pt1), new THREE.Vector3().subVectors(pt3, pt1))
                .normalize();
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt1, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt2, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt3, offset));
        }

        for (let i = 0; i < pathBot.length; i++) {
            const pt1 = arrayItemWrap(pathTop, pathBot.length + i);
            const pt2 = arrayItemWrap(pathTop, pathBot.length + i + 1);
            const pt3 = arrayItemWrap(pathBot, pathBot.length + i);
            const pt4 = arrayItemWrap(pathBot, pathBot.length + i + 1);
            const norm = new THREE.Vector3()
                .crossVectors(new THREE.Vector3().subVectors(pt1, pt2), new THREE.Vector3().subVectors(pt3, pt1))
                .normalize();
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt1, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt3, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt2, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt2, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt3, offset));
            normals.pushBuffer(norm);
            positions.pushBuffer(new THREE.Vector3().addVectors(pt4, offset));
        }
    }

    buffergeo.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    buffergeo.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    buffergeo.computeBoundingSphere();
    return buffergeo;
}

export function makePathSegmentPoints(path, closed = true) {
    const trim = closed ? 0 : 1;
    const points = [];

    for (let i = 0; i < path.length - trim; i++) {
        const pt1 = path[i];
        const pt2 = arrayItemWrap(path, i + 1);
        points.push(new THREE.Vector3().copy(pt1));
        points.push(new THREE.Vector3().copy(pt2));
    }

    return points;
}

export function makePhysicalSurfaceSegmentPoints(geometry, offset = new THREE.Vector3(0, 0, 0)) {
    const points = [];

    for (let i = 0; i < geometry.path_3d.length; i++) {
        const pt1 = arrayItemWrap(geometry.path_3d, i);
        const pt2 = arrayItemWrap(geometry.path_3d, i + 1);
        points.push(new THREE.Vector3().addVectors(pt1, offset));
        points.push(new THREE.Vector3().addVectors(pt2, offset));
    }

    for (let i = 0; i < geometry.base_3d.length; i++) {
        const pt1 = arrayItemWrap(geometry.base_3d, i);
        const pt2 = arrayItemWrap(geometry.base_3d, i + 1);
        points.push(new THREE.Vector3().addVectors(pt1, offset));
        points.push(new THREE.Vector3().addVectors(pt2, offset));
    }

    for (let i = 0; i < geometry.path_3d.length; i++) {
        const pt1 = geometry.path_3d[i];
        const pt2 = geometry.base_3d[i];
        points.push(new THREE.Vector3().addVectors(pt1, offset));
        points.push(new THREE.Vector3().addVectors(pt2, offset));
    }

    return points;
}

export function makeShaderMaterialInstance(geometry, uniforms, material) {
    const clone = material.clone();
    clone.uniforms = uniforms;
    const instance = new THREE.Mesh(geometry, clone);
    instance.material.uniforms.worldMtx = { value: instance.matrixWorld };
    return instance;
}

export function makeTextMeshGeometry(text, options) {
    const geometry = new THREE.TextGeometry(text, options);
    geometry.computeBoundingBox();

    return geometry;
}

export function makeTextTextureGeometry(text, font, texture, scale = 1.0) {
    const geometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, text.length * 2 * 3);
    const uvs = new BufferWriter(2, text.length * 2 * 3);

    const texWidth = texture.image.width;
    const texHeight = texture.image.height;

    let xpos = 0;

    for (let i = 0; i < text.length; ++i) {
        const charCode = text.charCodeAt(i);

        const charData = font.characters[charCode];
        const charWidth = charData.w + 2 * charData.hPadding;
        const charHeight = charData.h;

        const quadPoints = [
            new THREE.Vector3(xpos, 0, 0).multiplyScalar(scale),
            new THREE.Vector3(xpos, charHeight, 0).multiplyScalar(scale),
            new THREE.Vector3(xpos + charWidth, charHeight, 0).multiplyScalar(scale),
            new THREE.Vector3(xpos + charWidth, 0, 0).multiplyScalar(scale),
        ];

        const quadUVs = [
            new THREE.Vector2(charData.x / texWidth, 1.0 - (charData.y + charHeight) / texHeight),
            new THREE.Vector2(charData.x / texWidth, 1.0 - charData.y / texHeight),
            new THREE.Vector2((charData.x + charWidth) / texWidth, 1.0 - charData.y / texHeight),
            new THREE.Vector2((charData.x + charWidth) / texWidth, 1.0 - (charData.y + charHeight) / texHeight),
        ];

        positions.pushBuffer(quadPoints[0]);
        positions.pushBuffer(quadPoints[1]);
        positions.pushBuffer(quadPoints[3]);
        positions.pushBuffer(quadPoints[2]);
        positions.pushBuffer(quadPoints[3]);
        positions.pushBuffer(quadPoints[1]);

        uvs.pushBuffer(quadUVs[0]);
        uvs.pushBuffer(quadUVs[1]);
        uvs.pushBuffer(quadUVs[3]);
        uvs.pushBuffer(quadUVs[2]);
        uvs.pushBuffer(quadUVs[3]);
        uvs.pushBuffer(quadUVs[1]);

        xpos += charData.w + charData.hPadding;
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs.array, 2));
    geometry.computeBoundingBox();

    return geometry;
}

export function makeSurfacePolygonSegmentPoints(paths) {
    const pts = [];

    for (const path of paths) {
        for (let i = 0; i < path.length; i++) {
            const pt1 = path[i];
            const pt2 = arrayItemWrap(path, i + 1);
            pts.push(new THREE.Vector3(pt1.x, pt1.y, pt1.z || 0));
            pts.push(new THREE.Vector3(pt2.x, pt2.y, pt2.z || 0));
        }
    }

    return pts;
}

export function makeMultiPolygonGeometrySolid(multiPoly) {
    const transforms = multiPoly.plane.orthoBasisTransforms();
    const paths2 = paths3to2(multiPoly.paths, transforms);
    const paths3 = multiPoly.paths;

    const inputs = makeEarcutPathsWithHoles(paths2);
    const indices = inputs.map((params) => earcut(params.vertices, params.holes, 3));
    const totalIndices = _.sumBy(indices, (i) => i.length);

    const psGeometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, totalIndices);
    const normals = new BufferWriter(3, totalIndices);

    for (let k = 0; k < indices.length; k++) {
        const orgIndices = inputs[k].originalIndices;
        for (let i = 0; i < indices[k].length; i += 3) {
            const org1 = orgIndices[indices[k][i]];
            const org2 = orgIndices[indices[k][i + 1]];
            const org3 = orgIndices[indices[k][i + 2]];
            const pt1 = paths3[org1[0]][org1[1]];
            const pt2 = paths3[org2[0]][org2[1]];
            const pt3 = paths3[org3[0]][org3[1]];
            const norm = multiPoly.plane.normal;
            normals.pushBuffer(norm);
            positions.pushBuffer(pt1);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt2);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt3);
        }
    }

    psGeometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    psGeometry.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    psGeometry.computeBoundingSphere();
    return psGeometry;
}

export function makeSurfacePolygonGeometrySolid(paths) {
    const inputs = makeEarcutPathsWithHoles(paths);
    const indices = inputs.map((params) => earcut(params.vertices, params.holes, 3));
    const totalIndices = _.sumBy(indices, (i) => i.length);

    const psGeometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, totalIndices);
    const normals = new BufferWriter(3, totalIndices);

    for (let k = 0; k < indices.length; k++) {
        for (let i = 0; i < indices[k].length; i += 3) {
            const pt1 = earcutInputToVector(inputs[k].vertices, indices[k][i]);
            const pt2 = earcutInputToVector(inputs[k].vertices, indices[k][i + 1]);
            const pt3 = earcutInputToVector(inputs[k].vertices, indices[k][i + 2]);
            const norm = new THREE.Vector3()
                .crossVectors(new THREE.Vector3().subVectors(pt2, pt1), new THREE.Vector3().subVectors(pt3, pt1))
                .normalize();
            normals.pushBuffer(norm);
            positions.pushBuffer(pt1);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt2);
            normals.pushBuffer(norm);
            positions.pushBuffer(pt3);
        }
    }

    psGeometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    psGeometry.setAttribute('normal', new THREE.BufferAttribute(normals.array, 3));
    psGeometry.computeBoundingSphere();
    return psGeometry;
}

const DEFAULT_QUAD_UVS = [
    new THREE.Vector2(0, 0),
    new THREE.Vector2(1, 0),
    new THREE.Vector2(1, 1),
    new THREE.Vector2(0, 1),
];

export function makeQuadTexturedGeometryFromSize(quadSize, quadUVs = DEFAULT_QUAD_UVS) {
    const quadPoints = [
        new THREE.Vector3(-quadSize.x * 0.5, -quadSize.y * 0.5, 0),
        new THREE.Vector3(quadSize.x * 0.5, -quadSize.y * 0.5, 0),
        new THREE.Vector3(quadSize.x * 0.5, quadSize.y * 0.5, 0),
        new THREE.Vector3(-quadSize.x * 0.5, quadSize.y * 0.5, 0),
    ];

    return makeQuadTexturedGeometryFromPoints(quadPoints, quadUVs);
}

export function makeQuadTexturedGeometryFromPoints(quadPoints, quadUVs = DEFAULT_QUAD_UVS) {
    const geometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, 2 * 3);
    const uvs = new BufferWriter(2, 2 * 3);

    positions.pushBuffer(quadPoints[0]);
    positions.pushBuffer(quadPoints[1]);
    positions.pushBuffer(quadPoints[3]);
    positions.pushBuffer(quadPoints[2]);
    positions.pushBuffer(quadPoints[3]);
    positions.pushBuffer(quadPoints[1]);

    uvs.pushBuffer(quadUVs[0]);
    uvs.pushBuffer(quadUVs[1]);
    uvs.pushBuffer(quadUVs[3]);
    uvs.pushBuffer(quadUVs[2]);
    uvs.pushBuffer(quadUVs[3]);
    uvs.pushBuffer(quadUVs[1]);

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs.array, 2));
    geometry.computeBoundingSphere();

    return geometry;
}

export function makeQuadGeometryFromPoints(quadPoints) {
    const geometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, 6);

    positions.pushBuffer(quadPoints[0]);
    positions.pushBuffer(quadPoints[1]);
    positions.pushBuffer(quadPoints[3]);
    positions.pushBuffer(quadPoints[2]);
    positions.pushBuffer(quadPoints[3]);
    positions.pushBuffer(quadPoints[1]);

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.computeBoundingSphere();
    return geometry;
}

function quadsToColorPoints(colorQuads) {
    const pts = [];
    for (const quad of colorQuads) {
        pts.push(makeColorPoint(quad.path[0], quad.color));
        pts.push(makeColorPoint(quad.path[1], quad.color));
        pts.push(makeColorPoint(quad.path[1], quad.color));
        pts.push(makeColorPoint(quad.path[2], quad.color));
        pts.push(makeColorPoint(quad.path[2], quad.color));
        pts.push(makeColorPoint(quad.path[3], quad.color));
        pts.push(makeColorPoint(quad.path[3], quad.color));
        pts.push(makeColorPoint(quad.path[0], quad.color));
    }

    return pts;
}

export function makeMultiColorQuadGeometryWire(colorQuads) {
    return makeColorWireGeometry(quadsToColorPoints(colorQuads));
}

export function makeMultiColorQuadGeometryWireInstanced(colorQuads) {
    return makeColorWireGeometryInstanced(quadsToColorPoints(colorQuads));
}

export function makeMultiColorQuadGeometrySolid(colorQuads) {
    const quads = colorQuads.length;
    const buffergeo = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, quads * 2 * 3);
    const colors = new BufferWriter(3, quads * 2 * 3);
    const side1v = new BufferWriter(3, quads * 2 * 3);
    const side2v = new BufferWriter(3, quads * 2 * 3);

    for (const quad of colorQuads) {
        const path = quad.path;
        const colorV = vec3Color(quad.color);
        // trading off larger buffers for normal computation on gpu
        const side1 = new THREE.Vector3().subVectors(path[2], path[0]);
        const side2 = new THREE.Vector3().subVectors(path[1], path[2]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[0]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[2]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[1]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[0]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[3]);
        side1v.pushBuffer(side1);
        side2v.pushBuffer(side2);
        colors.pushBuffer(colorV);
        positions.pushBuffer(path[2]);
    }

    buffergeo.setAttribute('color_', new THREE.BufferAttribute(colors.array, 3));
    buffergeo.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    buffergeo.setAttribute('side1', new THREE.BufferAttribute(side1v.array, 3));
    buffergeo.setAttribute('side2', new THREE.BufferAttribute(side2v.array, 3));
    buffergeo.computeBoundingSphere();
    return buffergeo;
}

export function makeConvexPathGeometrySolid(path) {
    const geometry = new THREE.BufferGeometry();
    const positions = new BufferWriter(3, (path.length - 2) * 3);

    // triangle fan
    const fanCenter = path[0];
    for (let i = 2; i < path.length; i++) {
        const fanCurr = path[i];
        const fanLast = path[i - 1];
        positions.pushBuffer(new THREE.Vector3(fanCenter.x, fanCenter.y, 0));
        positions.pushBuffer(new THREE.Vector3(fanCurr.x, fanCurr.y, 0));
        positions.pushBuffer(new THREE.Vector3(fanLast.x, fanLast.y, 0));
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions.array, 3));
    geometry.computeBoundingSphere();
    return geometry;
}

function segmentsFromConvexPath(path) {
    const segPts = [];
    for (let i = 0; i < path.length; i++) {
        const pt1 = path[i];
        const pt2 = arrayItemWrap(path, i + 1);
        segPts.push(new THREE.Vector3(pt1.x, pt1.y, 0));
        segPts.push(new THREE.Vector3(pt2.x, pt2.y, 0));
    }

    return segPts;
}

export function makeConvexPathGeometryWire(path) {
    return makeWireGeometry(segmentsFromConvexPath(path));
}

export function makeConvexPathGeometryWireInstanced(path) {
    return makeWireGeometryInstanced(segmentsFromConvexPath(path));
}

// stolen from THREE
// Add limit to image width and height (width: 2048, height: 2048)
export function resizeImageIfNeeded(image, maxSize = 2048) {
    const { width, height } = image;

    if (
        THREE.MathUtils.isPowerOfTwo(width) &&
        THREE.MathUtils.isPowerOfTwo(height) &&
        width <= maxSize &&
        height <= maxSize
    ) {
        return image;
    }

    const longEdge = Math.max(width, height);
    const scale = Math.min(1, maxSize / longEdge);

    const newWidth = width * scale;
    const newHeight = height * scale;

    const canvas = document.createElement('canvas');
    canvas.width = THREE.MathUtils.floorPowerOfTwo(newWidth);
    canvas.height = THREE.MathUtils.floorPowerOfTwo(newHeight);

    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvas;
}

export function makeTextureFromImage(image) {
    const resizedImage = resizeImageIfNeeded(image);
    const texture = new THREE.Texture();

    texture.image = resizedImage;
    texture.needsUpdate = true;
    texture.format = THREE.RGBAFormat;

    if (image.src) {
        const isJPEG = image.src.search(/\.(jpg|jpeg)$/) > 0 || image.src.search(/^data:image\/jpeg/) === 0;
        if (isJPEG) texture.format = THREE.RGBFormat;
    }

    return texture;
}
