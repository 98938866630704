/* global angular:true, jQuery:true, window:true */
/*jslint bitwise:true */

/**
 * Note that $http is still being configured, since we still have call sites that rely on it instead of the new fetch()
 * stuff.
 * This can be safely axed ones we migrated over.
 */
import { get } from 'lodash';

import { getChat } from 'helioscope/app/utilities/chat-wrapper';
import * as analytics from 'helioscope/app/utilities/analytics';
import { helioscopeConfig } from 'helioscope/app/config';
import * as bugReports from 'helioscope/app/utilities/bug_reports';
import * as modal from 'helioscope/app/utilities/modal';
import { $http, $state } from 'helioscope/app/utilities/ng';
import { requestHeaders } from 'helioscope/app/utilities/relational/resource';

import * as angularBridge from 'reports/angular-bridge.ts';

import { User } from './resources';
import { func } from 'prop-types';


const ROLES = {
    anon: 0b000,
    expired: 0b001,
    user: 0b010,
    admin: 0b100,
};

const ACCESS_LEVELS = {
    public: 0b111,
    free: 0b001,
    user: 0b110,
    admin: 0b100,
};

const anonUser = {
    _role: ROLES.anon,
    hasFeature: () => false,
};

let currentUser = anonUser; // eslint-disable-line import/no-mutable-exports
angularBridge.setUser(currentUser);

export { currentUser as user, ROLES, ACCESS_LEVELS };

function assignRole(user) {
    if (user.is_current && user.is_admin) {
        user._role = ROLES.admin;
    } else if (user.is_current && (user.is_admin === false)) {
        user._role = ROLES.user;
    } else if (user.activated) {
        user._role = ROLES.expired;
    } else {
        user._role = ROLES.anon;
    }

    angularBridge.setUser(user);

    return user;
}

export function login(user) {
    currentUser = assignRole(user);

    if (helioscopeConfig.access_token) {
        requestHeaders.access_token = helioscopeConfig.access_token;
        $http.defaults.headers.common.access_token = helioscopeConfig.access_token;
    }

    analytics.identify(user);
    getChat().login(user);
    bugReports.identify(user);
}

(function (angular, $) {
    'use strict';

    var mod = angular.module('helioscope.users.auth', ['helioscope.users.resources',
                                                       'helioscope.services',
                                                       'helioscope.users.auth.config']),
        mod_config = angular.module('helioscope.users.auth.config', []);

    mod_config.constant('roles', ROLES);
    mod_config.constant('accessLevels', ACCESS_LEVELS);

    // keep for backwards compatibility
    mod_config.constant('auth.roles', ROLES);
    mod_config.constant('auth.accessLevels', ACCESS_LEVELS);


    mod.factory('Authenticator', ['Messager', '$rootScope', 'auth.roles', 'accessLevels', '$q', '$window', '$log', '$injector',
                function (Messager, $rootScope, roles, accessLevels, $q, $window, $log, $injector) {

            var loginDlg,
                nextState = {};

            function getLoginDlg() {
                if (loginDlg === undefined) {
                    loginDlg = $injector.get('LoginDlg');
                }

                return loginDlg(nextState.email);
            }

            function promptLogin(callback) {
                $log.log("prompt login");

                const { current, params } = $rootScope.$state;

                // Given that the user is logged in already,
                // we don't want to go through loginUser due
                // to some of the extra calls there (analytics and etc).
                if ($rootScope.authorized()) {
                    $rootScope.$state.go('home');
                    // Bail so we don't show the dialog.
                    return;
                }
                return getLoginDlg().then(function (user) {
                    if (callback) {
                        callback(user);
                    }

                    if (user.shouldShowSnoozeableClickwrapLogin) {
                        $rootScope.$state.go(
                            'home.updatedTOUSnoozeable',
                            { toState: get(current, 'name', 'home') },
                            params,
                        );
                    }

                    return user;
                });
            }

            function loginUser(user) {
                //take a user object and log them into the app (client-side)
                // sets the permissions and runs any intercepted http requests
                if (user) {
                    login(user);
                }
            }

            function loginAnonymousUser() {
                currentUser = anonUser;
                getChat().login();
            }

            function logout() {
                getChat().logout();
                currentUser.$logout(function () {
                    // perform a full refresh by navigating to '/'.
                    // note that there's no need to update currentUser, because this will trigger a full app reload
                    $window.location.href = '/';
                }, function (response) {
                    Messager.error("Could not log you out right now.");
                    $log.warn(response);
                });
            }

            function impersonateUser(user) {
                requestHeaders['IMPERSONATE_USER'] = user;
                $http.defaults.headers.common['IMPERSONATE_USER'] = user;
                $rootScope.permissionOverriding = true;
            }

            function disablePermissions() {
                $http.defaults.headers.common['OVERRIDE_PERMISSIONS'] = true;
                requestHeaders['OVERRIDE_PERMISSIONS'] = true;
                $rootScope.permissionOverriding = true;
            }

            $rootScope.$on('event:auth-loginRequired', (event, { redirectUrl }) => {
                $log.log("Login Required (AJAX)");

                currentUser = anonUser;
                const { current, params } = $rootScope.$state;
                // When coming from a 401 page, current doesn't have either
                // name or home and so angular-ui-router throws an exception--
                // which actually bricks the UI.
                // To avoid the error, default route to 'home'.
                const route = get(current, 'name', 'home') || 'home';
                promptLogin()
                    .then(() => {
                        // This is somewhat janky, but if there is a
                        // redirect url provided by the auth-loginRequired
                        // broadcaster, then we should redirect there.
                        // It would be better to be able to use
                        // $state.go instead of assigning $window.location,
                        // but snagging a route name + params doesn't seem
                        // terribly straightforward--especially from the
                        // broadcaster.
                        if (redirectUrl) {
                            $window.location.href = redirectUrl;
                        } else {
                            $rootScope.$state.go(route, params);
                        }
                    })
                    .catch(() => $rootScope.$state.go('home'));
            });

            $rootScope.$on('event:session-locked', (event, data) => {
                $log.log('Locked out');
                promptLockout(data);
            });

            $rootScope.$on('$stateChangeStart', function (event, current, to_params) {
                nextState.email = to_params.email;
            });

            function authorize(accessLevel) {
                //authorize the current user to given access level, if they are not at
                //that access level, prompt for them to login

                var deferred = $q.defer();
                // $log.log("Authorize to " + accessLevel);

                // eslint-disable-next-line no-bitwise
                if (!(currentUser._role & accessLevel)) {
                    if (currentUser._role === roles.expired) {
                        deferred.reject({
                            'message': 'Your trial account has expired, sign up for a full account to continue using HelioScope.',
                            'type': 402
                        });
                    } else {
                        promptLogin(function (user) {
                            // eslint-disable-next-line no-bitwise
                            if (user && (user._role & accessLevel)) {
                                deferred.resolve(user);
                            } else {
                                deferred.reject({
                                    'message': 'Access denied',
                                    'type': 403
                                });
                            }
                        });
                    }
                } else {
                    deferred.resolve(currentUser);
                }

                return deferred.promise;
            }

            return {
                promptLogin,
                loginUser,
                logout,
                loginAnonymousUser,
                impersonateUser,
                disablePermissions,
                user: function () { return currentUser; },
                authorized: function (level) {
                    var access = level ? accessLevels[level] : accessLevels['public'];
                    // eslint-disable-next-line no-bitwise
                    return (currentUser && (currentUser._role & access) > 0);
                },
                authorize,
            };

        }]);

    mod.factory('LoginDlg', ['$modal', 'Authenticator', function ($modal, Authenticator) {

        var opts = {
                templateUrl: require('helioscope/app/users/partials/login-modal.html'),
                controller: 'LoginCtrl',
                backdropFade: true,
                dialogFade: true,
                windowClass: 'login-modal',
            },
            isOpen = false,
            modalInstance;

        return function (email) {
          // if the modal instance is already open, don't open it again
            if (!isOpen) {
                modalInstance = $modal.open(angular.extend(opts,
                    {
                        resolve: {
                            email: function () { return email; }
                        }
                    }));
                isOpen = true;
                modalInstance.result.then(function (user) {
                    Authenticator.loginUser(user);
                    isOpen = false;
                }, function dismiss() {
                    isOpen = false;
                });
            }

            return modalInstance.result;
        };

    }]);


    mod.controller('LoginCtrl', [
        '$scope',
        '$modalInstance',
        'Messager',
        '$log',
        'ResetPasswordDlg',
        '$state',
        'email',
        '$window',
        ($scope, $modalInstance, messager, $log, resetPasswordDlg, state, email, $window) => {
            $modalInstance.opened.then(function () {$scope.isOpen = true; });

            $scope.close = () => {
                $modalInstance.dismiss();
            };

            $scope.new_user = {
                'remember_me': true,
                'email': email
            };

            $scope.emailState = 'email';
            $scope.passwordState = 'password';
            $scope.dialogState = $scope.emailState;

            $scope.isLoading = false;

            $scope.text =
                $window.location.pathname === '/'
                    ? 'Sign in using your registered account:'
                    : 'Log in to this access this page:';

            $scope.next = function () {
                // this is a hack so that password managers can work
                $scope.new_user.email = $('#login-email').val();

                var user = new User($scope.new_user);

                $scope.isLoading = true;
                user.$hasSSO(function (response) {
                    if (response.has_sso) {
                        $window.location.href = '/api/sso/login/' + response.provider_uuid;
                        return;
                    }
                    $scope.isLoading = false;
                    $scope.dialogState = $scope.passwordState;

                }, function (response) {
                    messager.error("Unknown error", {delay: 1000});
                    $scope.isLoading = false;
                });


            }

            $scope.logIn = function () {
                // this is a hack so that password managers can work
                $scope.new_user.password = $('#current-password').val();

                var user = new User($scope.new_user);
                $scope.isLoading = true;
                $scope.denied = false;

                user.$login(function (user) {
                    messager.success("Logged in successfully", {delay: 1000});
                    $modalInstance.close(user);

                    if (user.isExpired()) {
                        state.go('home.expired');
                    } else {
                        $state.go('home');
                    }
                }, function (response) {
                    $log.warn(response);
                    $scope.isLoading = false;

                    if (response.status === 403) {
                        state.go('home.notActivated', { email: $scope.new_user.email });
                        messager.error("Please activate your account", {delay: 1000});
                        return;
                    }

                    messager.error("Invalid login.", {delay: 1000});
                    $scope.denied = true;
                    $scope.dialogState = $scope.emailState;
                });
            };

            $scope.resetPassword = function (user) {
                resetPasswordDlg(user);
            };

            $scope.back = function () {
                $scope.dialogState = $scope.emailState;
            }

        }]);

}(angular, jQuery));

function promptLockout(data) {
    let modalInstance = null;
    const opts = {
        templateUrl: require('helioscope/app/users/partials/acquire-session-lock-modal.html'),
        controller: ($scope) => {
            'ngInject';
            Object.assign($scope, data);
            if (data.timeout) {
                $scope.timeout = data.timeout;
                const countdown = () => {
                    $scope.timeout--;
                    $scope.$digest();
                    if ($scope.timeout > 0) {
                        window.setTimeout(countdown, 1000);
                    }
                };

                window.setTimeout(countdown, 1000);
            }
            return {
                acquireLock: async() => {
                    const result = await currentUser.$acquireSessionLock();
                    analytics.track('session_lock.acquire');
                    if (!result.ok) {
                        $scope.message = result.message;
                        return;
                    }

                    modalInstance.close();
                    $state.reload();
                },
                contactSupport: () => {
                    getChat().newMessage();
                }
            };
        },
        controllerAs: 'ctrl',
        backdropFade: true,
        dialogFade: true,
        backdrop: 'static',
    };

    modalInstance = modal.open(opts, 'lockout');
    if (!modalInstance) {
        return;
    }

    return modalInstance.result;
}
