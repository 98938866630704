import { $filter } from 'helioscope/app/utilities/ng';

/**
 * angular-ui-tooltips appear to be broken for tooltip-template in all the
 * versions close to what we're using (0.14.x); otherwise this would just be
 * it's own tootlips.string_size.html template
 *
 * so manually calculate the template strings here, because embedding this
 * in logic in inline HTML is even uglier
 */
function ashraeTooltip(wiringZone, stringGuidelinesSource) {
    const { bounds, weather } = stringGuidelinesSource;

    if (!bounds) {
        return {
            min: 'Minimum number of modules in series. <br><br> Select a module for design guidelines.',
            max: 'Maximum number of modules in series. <br><br> Select a module for design guidelines.',
        };
    }

    const {
        moduleVoltages: { vMp, vOc, vMpMaxTemp, vOcMinTemp },
        inverterLimits: { minVoltage, maxVoltage },
    } = bounds;

    const { minTemp, maxTemp } = weather;

    const numberFilter = $filter('number');
    const round = (x) => numberFilter(x, 1);

    const getClass = (val) => {
        if (val > maxVoltage) return 'text-danger';
        if (val < minVoltage) return 'text-info';
        return '';
    };

    const getMessage = (fieldName, count) => `
        The ${fieldName} number of modules to be wired in series.
        <br><br>
        MPP range: ${round(minVoltage)}V - ${round(maxVoltage)}V
        <br>
        <table class="table table-horizontal table-compact">
            <tr class="${getClass(vMpMaxTemp * count)}">
                <td>V<sub>mp</sub> @ ${round(maxTemp)}&deg;C</td>
                <td>${round(vMpMaxTemp * count)}V</td>
            </tr>
            <tr class="${getClass(vMp * count)}">
                <td>V<sub>mp</sub> @ 25.0&deg;C</td>
                <td>${round(vMp * count)}V</td>
            </tr>
            <tr class="${getClass(vOc * count)}">
                <td>V<sub>oc</sub> @ 25.0&deg;C</td>
                <td>${round(vOc * count)}V</td>
            </tr>
            <tr class="${getClass(vOcMinTemp * count)}">
                <td>V<sub>oc</sub> @ ${round(minTemp)}&deg;C</td>
                <td>${round(vOcMinTemp * count)}V</td>
            </tr>
        </table><hr>
        Source: ${weather}`;

    return {
        min: getMessage('minimum', wiringZone.string_size_min),
        max: getMessage('maximum', wiringZone.string_size_max),
    };
}

function solaredgeTooltip(wiringZone, stringGuidelinesSource) {
    const {
        bounds: { min, max, minWarning, maxWarning },
    } = stringGuidelinesSource;

    return {
        min: `String sizes based on SolarEdge design guidelines.<br><br>
        Target minimum of ${min} modules per string.<br>
        (${minWarning}).`,

        max: `String sizes based on SolarEdge design guidelines.<br><br>
        Target maximum of ${max} modules per string.<br>
        (${maxWarning})`,
    };
}

const TOOLTIP_FUNCTIONS = {
    solaredge: solaredgeTooltip,
    temperature: ashraeTooltip,
};

export function stringingTooltips(wiringZone, stringBounds) {
    const baseSource = stringBounds.baseSource;

    return TOOLTIP_FUNCTIONS[baseSource.source](wiringZone, baseSource);
}
