/* eslint-disable camelcase */
import { FieldSegment } from 'helioscope/app/designer/field_segment/FieldSegment';
import { Keepout } from 'helioscope/app/designer/keepout/Keepout';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { RelationalBase } from 'helioscope/app/relational';
import { fetchJSON, postJSON } from 'helioscope/app/utilities/relational';

/**
 * Responsible for managing bulk operations on a collection of entities.
 * It provides methods to save, update, and delete these entities in bulk using the /bulk_operations api,
 * and manages side effects such as registering and deregistering entities in the designer.
 *
 * @class BulkEntityChange
 * @extends {RelationalBase}
 *
 * @param {Array} entities - The collection of entities to be managed.
 * @param {Map} changes - changes to be applied to entities.
 * @param {Map} undoChanges - changes to be undone.
 */
export class BulkEntityChange extends RelationalBase {
    constructor(entities, changes = new Map(), undoChanges = new Map()) {
        super();

        if (!entities || entities.length === 0) {
            throw new Error('Entities cannot be undefined or empty');
        }

        this.entities = new Set(entities);
        this.design_id = this.entitiesArray[0].design_id;

        this.changes = changes;
        this.undoChanges = undoChanges;
    }

    get length() {
        return this.entities.size;
    }

    get entitiesArray() {
        return [...this.entities];
    }

    get field_segments() {
        return this.entitiesArray.filter((object) => object instanceof FieldSegment);
    }

    get keepouts() {
        return this.entitiesArray.filter((object) => object instanceof Keepout);
    }

    get entity_premades() {
        return this.entitiesArray.filter((object) => object instanceof EntityPremade);
    }

    toString() {
        return `${this.length} entities`;
    }

    _generatePayload() {
        return {
            field_segments: this.field_segments,
            keepouts: this.keepouts,
            entity_premades: this.entity_premades,
        };
    }

    getEntityChanges(entity) {
        return this.changes.has(entity) ? { ...entity, ...this.changes.get(entity) } : entity;
    }

    _generateUpdatePayload() {
        return {
            field_segments: this.field_segments.map((field_segment) => this.getEntityChanges(field_segment)),
            keepouts: this.keepouts.map((keepout) => this.getEntityChanges(keepout)),
            entity_premades: this.entity_premades.map((entity_premade) => this.getEntityChanges(entity_premade)),
        };
    }

    deregisterObjects() {
        this.entities.forEach((entity) => {
            entity.$deregister();
        });
    }

    $delete() {
        return fetchJSON(`/api/bulk_operations/${this.design_id}`, { body: this._generatePayload(), method: 'DELETE' })
            .then(() => {
                this.deregisterObjects();
                return this;
            })
            .catch((error) => Promise.reject(error));
    }

    initializeObjects(createdObjects) {
        this.field_segments.forEach((field_segment, index) => {
            field_segment.$initialize(createdObjects.field_segments[index]);
        });
        this.keepouts.forEach((keepout, index) => {
            keepout.$initialize(createdObjects.keepouts[index]);
        });
        this.entity_premades.forEach((entity_premade, index) => {
            entity_premade.$initialize(createdObjects.entity_premades[index]);
        });
    }

    $save() {
        return postJSON(`/api/bulk_operations/${this.design_id}`, this._generatePayload())
            .then((response) => {
                this.initializeObjects(response.data);
                return this;
            })
            .catch((error) => Promise.reject(error));
    }

    applyEntityChanges(entity, undo = false) {
        const entityChanges = undo ? this.undoChanges.get(entity) : this.changes.get(entity);
        if (entityChanges) {
            // eslint-disable-next-line guard-for-in
            for (const key in entityChanges) {
                entity[key] = entityChanges[key];
            }
        }
    }

    $update() {
        return fetchJSON(`/api/bulk_operations/${this.design_id}`, {
            body: this._generateUpdatePayload(),
            method: 'PUT',
        })
            .then((_response) => this)
            .catch((error) => Promise.reject(error));
    }
}
