/* eslint-disable camelcase */
import { $document, $anchorScroll, $cookies, $rootScope, Messager } from 'helioscope/app/utilities/ng';
import { makeIframeAwareUrl, getBetaBillingURL } from 'helioscope/app/utilities/url';
import * as SolarTime from 'helioscope/app/utilities/solar/solar_time';
import * as Geometry from 'helioscope/app/utilities/geometry';
import { KEY, isEventFromTextInput, isMacLike } from 'helioscope/app/utilities/helpers';
import { user } from 'helioscope/app/users';
import { track, trackKeyboardAction } from 'helioscope/app/utilities/analytics';

import {
    checkManualModuleOverlap,
    FieldSegmentActionsMixin,
    fieldSegmentActions,
    findOverlappingModule,
} from './actions';
import {
    makeFieldSegment,
    timeOfDayRowSpacingV2,
    getDefaultShadeTimes,
    notUsingDefaultCharacterization,
} from './services';

const INPUT_THROTTLE = 100;

export class NewFieldSegmentCtrl {
    constructor($scope, design, dispatcher, profile) {
        'ngInject';

        this.fieldSegment = makeFieldSegment(design, profile);
        this.dispatcher = dispatcher;
        this.userLabelPrefs = { labelScaleFactor: user.preferences.designer.label_scale_factor };

        this.updateFunction = _.throttle(this.updateFunction.bind(this), INPUT_THROTTLE, { trailing: true });

        this.unsubscribers = [this.dispatcher.subscribe('selectionModeExited', this.onSelectionModeExited.bind(this))];
        this.createNewSegment = this.createNewSegment.bind(this);

        $scope.$on('$destroy', () => {
            this.mapInput.cancel();
            for (const unsub of this.unsubscribers) {
                unsub();
            }
            $document.off('keydown.NewFieldSegmentCtrl');
        });

        $document.on('keydown.NewFieldSegmentCtrl', (evt) => {
            if (evt.keyCode === KEY.ESC) {
                this.clear();
            }
        });

        this.dispatcher.onRendererReady(() => {
            this.mapInput = this.dispatcher.renderer.mapInputFactory();
            this.createNewSegment(this.fieldSegment);
        });
    }

    updateFunction(path) {
        if (path && path.length > 2) {
            // remove any points within 1cm when deduping
            path = Geometry.sanitizePath(path, 1e-2);
            // set the path to the sanitized path
            this.fieldSegment.geometry.path = path;
            // initialize the surface plane based on the path
            this.fieldSegment.initializePlanes();
            this.estimation = this.fieldSegment.calculateMaxModules();
        }
    }

    onSelectionModeExited() {
        if (this.dispatcher.selectedEntities.size === 0) {
            this.createNewSegment(this.fieldSegment);
        }
    }

    createNewSegment(fieldSegment) {
        this.clear();
        this.mapInput.getPolygon(this.userLabelPrefs).then(
            (path) => {
                // field segment path must have at least 3 points
                if (path.length < 3) {
                    Messager.error('A field segment must contain at least 3 points');
                    this.dispatcher.designManager.removeEntities([fieldSegment]);
                    this.createNewSegment(this.fieldSegment);
                    return;
                }

                // remove any points within 1cm when deduping
                fieldSegment.geometry.path = Geometry.sanitizePath(path, 1e-2);

                // reset the layout start before creating so the first layout is re-optimized with
                // the final path
                fieldSegment.geometry.layout_start = null;

                fieldSegmentActions
                    .createFieldSegment({ fieldSegment, dispatcher: this.dispatcher })
                    .catch(() => this.clear()); // clear on reject
            },
            () => this.clear(),
            (path) => this.updateFunction(path),
        );
    }

    clear() {
        this.estimation = {};
        this.fieldSegment.geometry.path.length = 0;
        this.dispatcher.onRendererReady(() => {
            this.mapInput.clear();
            this.dispatcher.renderer.clearEntity(this.fieldSegment);
        });
        this.updateFunction.cancel();
    }
}

export class FieldSegmentMapControls {
    constructor(dispatcher, $scope) {
        this.dispatcher = dispatcher;
        this.createHighlightListeners($scope);
    }

    createHighlightListeners($scope) {
        const dispatcher = this.dispatcher;
        const mouseoverListener = dispatcher.subscribe('FieldSegment:mouseover', (disp, { fieldSegment }) => {
            $anchorScroll(this.domId(fieldSegment));
            this.mapHighlighted = fieldSegment;
            $scope.$apply();
        });

        const mouseoutListener = dispatcher.subscribe('FieldSegment:mouseout', () => {
            delete this.mapHighlighted;
            // Prevents the angularjs digest cycle from running if it's already in progress
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });

        $scope.$on('$destroy', () => {
            mouseoverListener();
            mouseoutListener();
        });
    }

    highlight(fieldSegment, highlight) {
        delete this.mapHighlighted; // if the user mouses over a row, don't show the old highlighting
        this.dispatcher.renderer.highlightFieldSegment(fieldSegment, highlight);
    }

    domId(fieldSegment) {
        return `field_segment:${fieldSegment.field_segment_id}`;
    }
}

export class FieldSegmentListCtrl extends FieldSegmentActionsMixin {
    constructor(design, dispatcher, $scope, ContextualHelp) {
        'ngInject';

        super(dispatcher);

        this.design = design;

        this.mapControls = new FieldSegmentMapControls(dispatcher, $scope);

        this.characterizationWarning = 'Module characterizations different from user defaults.';
        this.notUsingDefaultCharacterizations = () =>
            this.design.field_segments.reduce(
                (total, fieldSegment) => total || notUsingDefaultCharacterization(fieldSegment),
                false,
            );

        if ($cookies.get('firstTimeDesigner') === 'true') {
            ContextualHelp.showHelpModal();
            $cookies.put('firstTimeDesigner', 'false');
        }
    }

    totalModules() {
        let sum = 0;
        this.design.field_segments.forEach((fieldSegment) => {
            sum += fieldSegment.data && fieldSegment.data.modules;
        });

        return sum;
    }

    totalPower() {
        let sum = 0;
        this.design.field_segments.forEach((fieldSegment) => {
            sum += fieldSegment.data && fieldSegment.data.power;
        });

        return sum;
    }

    get fsCastShadows() {
        return _.some(this.design.field_segments, 'shadow_caster');
    }

    set fsCastShadows(fsCastShadows) {
        this.setFieldSegmentsCastShadows({ fsCastShadows });
    }
}

export class FieldSegmentCtrl extends FieldSegmentActionsMixin {
    constructor(
        $scope,
        fieldSegment,
        dispatcher,
        profile,
        confirmRemoveIndependentTiltDlg,
        independentTiltContactAdminDlg,
    ) {
        'ngInject';

        super(dispatcher, fieldSegment);

        // only assigning the profile here for the TOD controller to grab
        this.profile = profile;

        this.forceMaxSizeVisible = fieldSegment.max_size > 0;

        this.manualRackingOrientation = $cookies.get('manualRackingOrientation') || 'horizontal';

        this.libraryRoute = makeIframeAwareUrl('/modules', '/library/modules');

        this.upsellSectionExpanded = false;

        this.confirmRemoveIndependentTiltDlg = confirmRemoveIndependentTiltDlg;

        this.removeIndependentTilt = this.toggleIndependentTilt;

        this.independentTiltContactAdminDlg = independentTiltContactAdminDlg;

        const interval =
            $rootScope.user() && $rootScope.user().subscription && $rootScope.user().subscription.plan_type
                ? $rootScope.user().subscription.plan_type
                : 'month';

        $scope.trackUpgradeToPro = (referrer) => {
            track('designer.upgrade_to_pro_cta', {
                referrer,
                project_id: this.fieldSegment.design.project_id,
                design_id: this.fieldSegment.design.design_id,
                team_id: $rootScope.user().team_id,
            });
        };
        this.trackUpgradeToPro = $scope.trackUpgradeToPro;

        $scope.betaBillingURL = (product) =>
            getBetaBillingURL(user, '', {
                product,
                dialog: user.subscription ? 'change' : 'initial',
                interval,
                referrer: 'independent_tilt_upsell',
            });

        this.unsubscribers = [this.dispatcher.subscribe('selectionModeExited', this.onSelectionModeExited.bind(this))];
        $scope.$on('$destroy', () => {
            this.toggleManualMode(null);
            for (const unsub of this.unsubscribers) {
                unsub();
            }
            $cookies.put('manualRackingOrientation', this.manualRackingOrientation);
        });

        $scope.$watch(() => {
            const group = $document.find('#manualModuleGroup');
            const vis = group.is(':visible');
            if (!group.length || !vis) {
                this.toggleManualMode(null);
            }
        });

        this.updateControl = () => {
            $scope.$apply();
        };

        $scope.rackingTypes = {
            carport: {
                template: require('helioscope/app/designer/field_segment/partials/racking.carport.html'),
            },
            dual: {
                template: require('helioscope/app/designer/field_segment/partials/racking.dual.html'),
            },
            flush: {
                template: require('helioscope/app/designer/field_segment/partials/racking.flush.html'),
            },
            rack: {
                template: require('helioscope/app/designer/field_segment/partials/racking.rack.html'),
            },
            single_axis: {
                template: require('helioscope/app/designer/field_segment/partials/racking.rack.html'),
            },
        };

        this.dispatcher.onRendererReady(() => {
            this.createDocumentListeners($scope);
        });
    }
    onSelectionModeExited() {
        this.toggleManualMode(this.manualRackingToolType, this.manualRackingMulti);
    }
    createDocumentListeners($scope) {
        const keydown = 'keydown.FieldSegmentCtrl';
        $document.on(keydown, (evt) => {
            if (isEventFromTextInput(evt)) {
                return;
            }
            if (this.dispatcher.areShortcutsDisabled) {
                return;
            }
            if (evt.keyCode === KEY.BACKSPACE || evt.keyCode === KEY.DELETE) {
                this.deleteFieldSegment();
                trackKeyboardAction(
                    'designer-shortcut-delete_field_segment',
                    this.fieldSegment.design,
                    $rootScope.user(),
                );
            }
        });

        $scope.$on('$destroy', () => {
            $document.off(keydown);
        });
    }

    get hasFullAccessIndependentTilt() {
        return this.fieldSegment.rack_type === 'rack' && $rootScope.user().hasIndependentTiltAccess;
    }

    // Controls displaying the Independent Tilt Upsell for users that do not have access
    get showIndependentTiltUpsell() {
        const isBasicUser =
            !$rootScope.user().team.is_on_custom_plan &&
            $rootScope.user().subscription &&
            $rootScope.user().subscription.product === 'basic';

        return (
            this.fieldSegment.rack_type === 'rack' &&
            !$rootScope.user().hasIndependentTiltAccess &&
            isBasicUser &&
            !this.fieldSegment.independent_tilt_enabled
        );
    }

    get showIndependentTiltCallout() {
        return !$rootScope.user().hasIndependentTiltAccess || $rootScope.user().isOnTrial();
    }

    showSingleAxisTrackers = () => $rootScope.user().hasSingleAxisTrackersAccess();

    toggleIndependentTiltUpsell() {
        this.upsellSectionExpanded = !this.upsellSectionExpanded;

        track('designer.toggle_independent_tilt_upsell', {
            action: this.upsellSectionExpanded ? 'open' : 'close',
            project_id: this.fieldSegment.design.project_id,
            design_id: this.fieldSegment.design.design_id,
            team_id: $rootScope.user().team_id,
        });
    }

    showRemoveConfirmationModal() {
        this.confirmRemoveIndependentTiltDlg(this);
    }

    showContactAdminModal(referrer) {
        this.independentTiltContactAdminDlg();
        this.trackUpgradeToPro(referrer);
    }

    toggleIndependentTilt() {
        let independent_tilt_azimuth;
        let independent_tilt_surface_tilt;

        // Initialize default values for independent tilt when toggled on
        if (!this.fieldSegment.independent_tilt_enabled) {
            // default I.T azimuth = 180 if project is in Northern Hemisphere
            // default I.T azimuth = 0 if project is in Southern Hemisphere
            independent_tilt_azimuth = Geometry.defaultAzimuth(this.fieldSegment.design);
            independent_tilt_surface_tilt = 0;
        }

        this.dispatcher.createMultiPropertyChange({
            resource: this.fieldSegment,
            changes: [
                {
                    path: 'independent_tilt_enabled',
                    oldVal: this.fieldSegment.independent_tilt_enabled,
                    newVal: !this.fieldSegment.independent_tilt_enabled,
                },
                {
                    path: 'independent_tilt_surface_azimuth',
                    oldVal: this.fieldSegment.independent_tilt_surface_azimuth,
                    newVal: independent_tilt_azimuth,
                },
                {
                    path: 'independent_tilt_surface_tilt',
                    oldVal: this.fieldSegment.independent_tilt_surface_tilt,
                    newVal: independent_tilt_surface_tilt,
                },
            ],
            loadMessage: 'Setting Independent Tilt',
            rollbackMessage: 'Undo Independent Tilt',
            mergeable: true,
        });

        track('designer.toggle_independent_tilt', {
            action: this.fieldSegment.independent_tilt_enabled ? 'open' : 'close',
            project_id: this.fieldSegment.design.project_id,
            design_id: this.fieldSegment.design.design_id,
            team_id: $rootScope.user().team_id,
        });
    }

    showMaxSize(showMax = false) {
        // show the max size UI element
        if (showMax === true) {
            this.fieldSegment.max_size = 0;
            this.forceMaxSizeVisible = true;
        } else {
            this.forceMaxSizeVisible = false;
            this.dispatcher.createSinglePropertyChange({
                resource: this.fieldSegment,
                path: 'max_size',
                newVal: null,
                oldVal: this.fieldSegment.max_size,
            });
        }
    }

    maxSizeVisible() {
        // should the max size UI element be visible
        return this.fieldSegment.max_size > 0 || this.forceMaxSizeVisible;
    }

    manualRackingToolName() {
        const toolType = this.manualRackingToolType;

        if (!toolType) {
            return '';
        }

        let options = '';
        if (toolType === 'Add') {
            const multi = this.manualRackingMulti ? 'Multi' : 'Single';
            const orientation = this.manualRackingOrientation === 'horizontal' ? 'Horz' : 'Vert';

            options = `:${multi}${orientation}`;
        }
        return `ManualModule:${toolType}${options}`;
    }

    setManualRackingOrientation(orientation) {
        if (orientation !== this.manualRackingOrientation) {
            this.toggleManualRackingOrientation();
        }
    }

    toggleManualRackingOrientation() {
        this.manualRackingOrientation = this.manualRackingOrientation === 'horizontal' ? 'vertical' : 'horizontal';
        const { activeTool } = this.dispatcher.renderer;

        // weird to have to do both?
        activeTool.toggleOrientation();
        activeTool.toolName = this.manualRackingToolName();
    }

    handleRendererKeyDown(event) {
        if (event.keyCode === KEY.r) {
            this.toggleManualRackingOrientation();
            this.updateControl();
        }
    }

    toggleManualMode(toolType, multi = undefined) {
        let currentToolName = this.manualRackingToolName();

        if (!currentToolName && !toolType) return;

        this.manualRackingToolType = toolType;
        this.manualRackingMulti = multi;

        const newToolName = this.manualRackingToolName();

        if (currentToolName === newToolName) {
            this.manualRackingToolType = null;
            currentToolName = null;
        } else {
            currentToolName = newToolName;
        }

        if (this.keyDownUnsub) {
            this.keyDownUnsub();
            this.keyDownUnsub = null;
        }

        this.dispatcher.onRendererReady(() => {
            if (currentToolName && currentToolName === newToolName) {
                if (toolType === 'Add') {
                    this.keyDownUnsub = this.dispatcher.renderer.registerKeyDownListener((event) => {
                        this.handleRendererKeyDown(event);
                    });
                }

                this.dispatcher.renderer.activateInteractTool({
                    tool: newToolName,
                    escapeHandler: () => {
                        this.toggleManualMode(newToolName);
                    },
                });
            } else {
                const { activeTool } = this.dispatcher.renderer;
                if (_.startsWith(activeTool.toolName, 'ManualModule:')) {
                    this.dispatcher.renderer.activateInteractTool(null);
                }
            }
        });
    }
}

export class FieldSegmentDimensionsMenuCtrl extends FieldSegmentActionsMixin {
    constructor(fieldSegment, dispatcher, distance, heading) {
        'ngInject';

        super(dispatcher, fieldSegment);
        this.distance = distance;
        this.heading = heading;

        const signAdj = Geometry.signedArea(fieldSegment.geometry.path) > 0 ? 1 : -1;

        this.azimuth = (360 + heading + signAdj * 90) % 360;
        this.topEdgeAzimuth = (this.azimuth + 180) % 360;
    }
}

export class FieldSegmentMenuCtrl extends FieldSegmentActionsMixin {
    constructor(fieldSegment, location, dispatcher) {
        'ngInject';

        super(dispatcher, fieldSegment);

        this.location = location;
        this.internalClipboard = dispatcher.internalClipboard;

        // only display the Fit to LIDAR (Tilt/Height and Tilt) menu options for flush racking or independent tilt
        this.canSetTiltAndAzimuth =
            fieldSegment.rack_type === 'flush' ||
            (fieldSegment.independent_tilt_enabled && $rootScope.user().hasIndependentTiltAccess);

        this._showRemoveModule = false;
        this._showRestoreModule = false;
        this._showDivider = true;
        // when modules are not visible to the user at all, don't show
        // per-module actions. Exit early to avoid the cost of determining
        // module under the cursor
        //
        // this is slightly buggy in case the state changes
        if (!this.dispatcher.showModules) {
            this._showDivider = fieldSegment.geometry.removed_module_locations.length > 0;
            return;
        }

        // find a module under the cursor to decide whether context menu
        // should show "Remove this Module", "Restore this Module" or
        // nothing at all
        const { module, removed } = findOverlappingModule(fieldSegment, location);

        if (module) {
            this._showRemoveModule = !removed;
            this._showRestoreModule = removed && !module.manual && !checkManualModuleOverlap(fieldSegment, module);
        } else {
            // when we're not over a module and there are no removed modules,
            // we need to hide one of the dividers
            this._showDivider = fieldSegment.geometry.removed_module_locations.length > 0;
        }
    }

    toggleModule() {
        return super.toggleModule({ location: this.location });
    }

    alignModules() {
        return super.alignModules({ location: this.location });
    }

    copyFieldSegment() {
        this.dispatcher.copyEntityFromMenu(this.fieldSegment);
    }

    paste() {
        this.dispatcher.activatePasteMode();
    }

    hasComponentInClipboard() {
        return this.internalClipboard.isNotEmpty();
    }

    isOnMac() {
        return isMacLike();
    }

    rotate() {
        this.dispatcher.selectEntity(this.fieldSegment);
        this.dispatcher.onRendererReady(() => {
            this.dispatcher.renderer.activateInteractTool({
                tool: 'InteractToolRotation',
            });
        });
    }
}

export const FieldSegmentContextMenu = {
    templateUrl: require('helioscope/app/designer/field_segment/partials/context.html'),
    controller: FieldSegmentMenuCtrl,
    controllerAs: 'fsMenu',
};

export const FieldSegmentEdgeMenu = {
    templateUrl: require('helioscope/app/designer/field_segment/partials/context.dimensions.html'),
    controller: FieldSegmentDimensionsMenuCtrl,
    controllerAs: 'fsEdgeMenu',
};

export class FixedTiltRackController {
    constructor($scope) {
        'ngInject';

        this.hidePopovers();

        $document.on('keydown.FixedTiltRackController', (evt) => {
            if (evt.keyCode === KEY.ESC) {
                this.hidePopovers();
                $scope.$apply();
            }
        });

        $scope.$on('$destroy', () => {
            $document.off('keydown.FixedTiltRackController');
        });
    }

    /**
     * toggle the given key, and hide everything else
     */
    updatePopovers(key) {
        this.popovers = {
            showS2R: key === 'showS2R' ? !this.popovers[key] : false,
            showGCR: key === 'showGCR' ? !this.popovers[key] : false,
            showTOD: key === 'showTOD' ? !this.popovers[key] : false,
        };
    }

    hidePopovers() {
        this.popovers = {
            showS2R: false,
            showGCR: false,
            showTOD: false,
        };
    }
}

export class PopoverModifyCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;
    }

    init(target, fieldSegment, dispatcher) {
        this.fieldSegment = fieldSegment;
        this.dispatcher = dispatcher;
        const $scope = this.$scope;

        $scope.$watch(target, (val, prev) => {
            if (val) {
                const roundFactor = 1e5;
                this.backup = fieldSegment.row_spacing;
                this.groundCoverageRatio = Math.round(fieldSegment.groundCoverageRatio * roundFactor) / roundFactor;
                this.spanToRise = Math.round(fieldSegment.spanToRise * roundFactor) / roundFactor;
            } else if (val === false && prev === true && this.backup !== undefined) {
                fieldSegment.row_spacing = this.backup;
            }
        });
    }

    cancel() {
        this.fieldSegment.row_spacing = this.backup;
        this.$scope.rackCtrl.hidePopovers();
    }

    saveSpanToRise() {
        // need to set the field segment back to the original value so we have undo history
        this.fieldSegment.row_spacing = this.backup;

        // persist the change
        fieldSegmentActions.setSpanToRise({
            dispatcher: this.dispatcher,
            fieldSegment: this.fieldSegment,
            spanToRise: this.spanToRise,
        });

        // need to hide last, because it will undo any changes
        this.backup = this.fieldSegment.row_spacing;
        this.$scope.rackCtrl.hidePopovers();
    }

    saveGroundCoverageRatio() {
        // need to set the field segment back to the original value so we have undo history
        this.fieldSegment.row_spacing = this.backup;

        // persist the change
        fieldSegmentActions.setGroundCoverageRatio({
            dispatcher: this.dispatcher,
            fieldSegment: this.fieldSegment,
            groundCoverageRatio: this.groundCoverageRatio,
        });

        // need to hide last, because it will undo any changes
        this.backup = this.fieldSegment.row_spacing;
        this.$scope.rackCtrl.hidePopovers();
    }
}

export class TimeOfDayShadeCtrl {
    datePickerOptions = {
        'show-weeks': false,
    };

    constructor($scope) {
        'ngInject';

        this.$scope = $scope;
    }

    init(fieldSegment, dispatcher, profile) {
        this.fieldSegment = fieldSegment;
        this.dispatcher = dispatcher;

        const { startTime, endTime } = getDefaultShadeTimes(fieldSegment.design, profile);

        this.pickerDate = startTime;
        this.startTime = startTime;
        this.endTime = endTime;

        const $scope = this.$scope;

        $scope.$watch('rackCtrl.popovers.showTOD', (val, prev) => {
            if (val === true) {
                this.backup = fieldSegment.row_spacing;
                fieldSegment.row_spacing = timeOfDayRowSpacingV2(fieldSegment, this.startTime, this.endTime);
            } else if (val === false && prev === true && this.backup !== undefined) {
                fieldSegment.row_spacing = this.backup;
            }
        });

        $scope.$watchGroup(['todCtrl.pickerDate', 'todCtrl.startTime', 'todCtrl.endTime'], () => {
            // entering invalid date in todCtrl.pickerDate results in undefined
            if (!this.pickerDate) {
                return;
            }
            const calcStartTime = SolarTime.combineDateTime(this.pickerDate, this.startTime);
            const calcEndTime = SolarTime.combineDateTime(this.pickerDate, this.endTime);

            if ($scope.rackCtrl.popovers.showTOD) {
                fieldSegment.row_spacing = timeOfDayRowSpacingV2(fieldSegment, calcStartTime, calcEndTime);
            }
        });
    }

    open($event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.opened = !this.opened;
    }

    cancel() {
        this.fieldSegment.row_spacing = this.backup;
        this.$scope.rackCtrl.hidePopovers();
    }

    save() {
        // persist the change
        fieldSegmentActions.setToDRowSpacing({
            dispatcher: this.dispatcher,
            fieldSegment: this.fieldSegment,
            startTime: this.startTime,
            endTime: this.endTime,
            newVal: this.fieldSegment.row_spacing,
            oldVal: this.backup,
        });

        // set the backup at the end, s that we can use it for undo
        this.backup = this.fieldSegment.row_spacing;

        this.$scope.rackCtrl.hidePopovers();
    }
}
