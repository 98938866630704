import * as THREE from 'three';

import { applyRotationToFourPoints } from '../GeometryHelpers';

// This is 1 greater than the max of the int4 type in postgres
export const MAX_OVERLAY_ORDER = 2147483648;

export function getFourPoints(overlay, rotate = true) {
    const p1 = overlay.overlay_parameter.quad_points[0];
    const p2 = overlay.overlay_parameter.quad_points[1];
    const p3 = overlay.overlay_parameter.quad_points[2];
    const p4 = overlay.overlay_parameter.quad_points[3];
    const angle = overlay.overlay_parameter.rotation_angle; // radian

    const fourPoints = [
        new THREE.Vector3(p1.x, p1.y, 0),
        new THREE.Vector3(p2.x, p2.y, 0),
        new THREE.Vector3(p3.x, p3.y, 0),
        new THREE.Vector3(p4.x, p4.y, 0),
    ];

    if (rotate) {
        return applyRotationToFourPoints(fourPoints, angle);
    } else {
        return fourPoints;
    }
}

export function degreesToRotateCursor(cursorType, handleIndex, degrees) {
    switch (cursorType) {
        case 'resize':
            // handleIndex: 0 (south), 1 (east), 2 (north), 3 (west), when overlay is not rotated
            return 90 * handleIndex + 90 - degrees;
        case 'scale':
            // handleIndex: 0 (southwest), 1 (southeast), 2 (northeast), 3 (northwest), when overlay is not rotated
            return 90 * handleIndex - degrees;
        case 'rotate':
            // handleIndex: null (because there is only one rotation handle)
            return degrees * -1 + 45;
        default:
            return 0;
    }
}

export function getRotationHandlePoint(fourPoints) {
    // Returns the Vector3 of where to locate the overlay image rotation handle.
    // This is a helper function because it's also used to determine the endpoint
    // of the line that connects the overlay image to the rotation handle.

    // calculate rectangle midpoint
    const rectangleMidPoint = new THREE.Vector3(
        (fourPoints[0].x + fourPoints[1].x + fourPoints[2].x + fourPoints[3].x) / 4.0,
        (fourPoints[0].y + fourPoints[1].y + fourPoints[2].y + fourPoints[3].y) / 4.0,
    );
    // calculate side midpoint
    const sideMidPoint = new THREE.Vector3(
        (fourPoints[1].x + fourPoints[2].x) / 2.0,
        (fourPoints[1].y + fourPoints[2].y) / 2.0,
    );
    // calculate new pos
    const rectMidToSideMidVector = new THREE.Vector3(
        sideMidPoint.x - rectangleMidPoint.x,
        sideMidPoint.y - rectangleMidPoint.y,
    );

    return new THREE.Vector3(
        sideMidPoint.x + rectMidToSideMidVector.x / 5.0,
        sideMidPoint.y + rectMidToSideMidVector.y / 5.0,
    );
}
