import { ngRefs as ng } from 'helioscope/app/utilities/ng';
import { flMap } from 'helioscope/app/utilities/containers';

const _or = (x, y) => x || y;

function booleanAssignOr(target, source) {
    return _.assignWith(target, source, _or);
}

/**
 * merge an object of true/false flags into a map entry, always keeping truthy-values
 */
function booleanMergeInto(map, key, val) {
    const merged = booleanAssignOr(map.get(key), val);
    map.set(key, merged);
    return merged;
}

const newObject = () => ({});

export class RenderUpdater {
    static UPDATE_ARRAY_DELAY_IN_MS = 250;

    constructor(dispatcher) {
        this.dispatcher = dispatcher;
        this.designScene = dispatcher.design.designScene();
        this.dirtyEntities = flMap(newObject);

        this.paused = false;
        this.needWiringUpdate = false;
    }

    pause() {
        if (this.unpauseTimeoutId) {
            window.clearTimeout(this.unpauseTimeoutId);
        }

        this.paused = true;
    }

    unpause(delay = 1000) {
        if (this.unpauseTimeoutId) {
            window.clearTimeout(this.unpauseTimeoutId);
        }

        this.unpauseTimeoutId = window.setTimeout(() => {
            this.paused = false;
            this.scheduleUpdate();
        }, delay);
    }

    scheduleUpdate() {
        this.dispatcher.designDirty = true;

        if (this.paused) {
            return;
        }

        this._updateArrayDeferred();
    }

    updateArray() {
        const needSceneUpdates = [...this.dirtyEntities]
            .filter(([_, options]) => options.updateScene)
            .map(([surf]) => surf);

        // recomputing paths and planes for each surface
        for (const [surf, updates] of this.designScene.updateSurfaces(needSceneUpdates)) {
            booleanMergeInto(this.dirtyEntities, surf, updates);
        }

        for (const [surface, options] of this.dirtyEntities) {
            this.updateSurface(surface, options);
        }

        if (this.needWiringUpdate === true) {
            this.updateWiring();
        }

        if (!ng.$rootScope.$$phase) {
            // not convinced this is necessary
            ng.$rootScope.$apply();
        }
    }

    _updateArrayDeferred = _.debounce(this.updateArray, RenderUpdater.UPDATE_ARRAY_DELAY_IN_MS);

    scheduleUpdates(designSceneUpdates) {
        for (const [surface, updateOptions] of designSceneUpdates) {
            this.updateSurfaceDeferred(surface, updateOptions);
        }
    }

    updateSurfaceDeferred(surface, options) {
        booleanMergeInto(this.dirtyEntities, surface, options);
        this.scheduleUpdate();
    }

    updateSurface(surface, { updateLayout, updateGeometry, updateShadows, updateSetbacks }) {
        if (updateLayout) {
            surface.moduleFill();

            // hack. globally override module rendering.
            if (this.dispatcher.showModules) {
                this.dispatcher.renderer.renderModules(surface);
            }

            this.needWiringUpdate = true;
        }

        if (updateGeometry || updateShadows || updateSetbacks) {
            if (surface.renderOverride) {
                surface.renderOverride(this.dispatcher.renderer);
            } else {
                this.dispatcher.renderer.renderSurface(surface);
            }
        }

        this.dirtyEntities.delete(surface);
    }

    updateWiring() {
        const design = this.dispatcher.design;
        design.generateWiring();

        // hack to only show wiring on wiring views
        if (this.dispatcher.showWiring && this.dispatcher.showModules) {
            this.dispatcher.renderer.renderWiringTree(design);
        } else {
            this.dispatcher.renderer.clearSubpolygons(design);
        }

        this.dispatcher.publish('WiringUpdated', { design });
        this.needWiringUpdate = false;
    }

    updateWiringDeferred() {
        this.needWiringUpdate = true;
        this.scheduleUpdate();
    }
}
