import Logger from 'js-logger';

const logger = Logger.get('inverter_scheduling');

/**
 * based on a fixed inverter count, get an inverter schedule that minimizes
 * unused modules, and ideally distributes modules as evenly as possible
 * across all inverters
 * @param  stringBounds     min and max bounds for inverter string sizes
 * @param  totalModules     the total modules to allocate across inveters
 * @param  rawInverterCount the target inverter count (if we don't have a enough strings to
 *                          allocate across all inverters, reduce the inverter count)
 * @return an array of inverter configurations
 */
export function createInverterSchedule(stringBounds, totalModules, rawInverterCount) {
    if (totalModules === 0 || rawInverterCount === 0) {
        logger.warn(`cannot create inverter schedule with ${totalModules} modules and ${rawInverterCount} inverters`);
        return [];
    }

    const scheduleBuilder = new ScheduleBuilder(stringBounds, totalModules, rawInverterCount);
    return scheduleBuilder.buildSchedule();
}

/**
 * Inverter Configuration that allows the input string length to vary in size
 * on a string by string basis.  Is fully generic, but requires you to build
 * strings yourself.
 */
export class GenericInverterConfig {
    constructor(inputs = []) {
        this.inputs = inputs;
    }

    stringsByInput() {
        return this.inputs;
    }

    stringCount(index = undefined) {
        if (index === undefined) {
            return _.sumBy(this.inputs, 'length');
        }

        return this.inputs[index].length;
    }

    moduleCount(index = undefined) {
        if (index === undefined) {
            return _(this.inputs).flatten().sum();
        }

        return _.sum(this.inputs[index]);
    }

    getCopy() {
        return new GenericInverterConfig(this.inputs.map((moduleCounts) => moduleCounts.slice()));
    }
}

/**
 * inverter configuration class that includes options for analyzing and
 * incrementally adding modules
 */
export class InverterConfig {
    constructor(inputs = []) {
        this.inputs = inputs;
    }

    /**
     * returns an array (entry for each inverter input) of arrays (entry for each string on that input
     * that describes the number of modules that should be included on that string)
     */
    stringsByInput() {
        return this.inputs.map(({ stringSize, stringCount }) => _.times(stringCount, _.constant(stringSize)));
    }

    stringCount(index = undefined) {
        if (index === undefined) {
            return _.sumBy(this.inputs, 'stringCount');
        }

        return this.inputs[index].stringCount;
    }

    moduleCount(index = undefined) {
        if (index === undefined) {
            return _.sumBy(this.inputs, ({ stringSize, stringCount }) => stringSize * stringCount);
        }

        const { stringSize, stringCount } = this.inputs[index];
        return stringSize * stringCount;
    }

    getCopy() {
        return new InverterConfig(this.inputs.map(({ stringSize, stringCount }) => ({ stringSize, stringCount })));
    }

    /**
     * determine an optimal string allocation for an inverter based on the
     * min and max bounds for string lengths, and the total item count.
     *
     * @return an inverterConfiguration that consumes at most moduleCount modules
     */
    static createOptimized(inverterBounds, moduleCount, inputs = 2) {
        if (inputs !== 2) {
            throw Error(`Can only analyze for 2 inputs, not ${inputs}`);
        }

        const { min, max } = inverterBounds;

        if (_.isNil(min) || _.isNil(max) || min > max) {
            throw Error(`Invalid stringing bounds for inverterSchedule [${min}, ${max}]`);
        }

        const potentialConfigurations = [];

        if (max - min > 1000) {
            throw new Error(`max - min too large (${max - min})`);
        }

        for (let input1Size = max; input1Size >= min; input1Size--) {
            for (let input2Size = input1Size; input2Size >= min; input2Size--) {
                const inputSizes = [input1Size, input2Size];
                potentialConfigurations.push(...this._generateValidStringCombinations(inputSizes, moduleCount));
            }
        }

        return _.minBy(potentialConfigurations, this._getScoringFunction(moduleCount, inverterBounds));
    }

    /**
     * for each input size tuple [input0Size, input1Size], find all valid
     * string counts per input that use as many modules as possible
     * @param  {Array[Int]} stringSizes: the requested string lengths for each input
     * @param  {Int}        moduleCount: total modules to be distributed
     * @param  {Float}      minAllocation: the minimum % of modules to be placed on either input
     * @return an array of proposed solutions for various legal allocations
     */
    static _generateValidStringCombinations(stringSizes, moduleCount, minAllocation = 0.3) {
        if (stringSizes.length !== 2) {
            throw Error(`Can only analyze for a size of 2, not ${stringSizes.length}`);
        }
        const [input0Size, input1Size] = stringSizes;

        if (input0Size === input1Size) {
            // if the input sizes are identical short circuit with a single input
            // configuration.  In reality, some users might want the strings balanced
            // across two inputs - particularly for large arrays where smaller MPPT
            // zones could be valuable.  In practice most people prefer to reduce
            // the complexity (and number of combiner boxes) by effectively only
            // using one input
            const totalStrings = Math.floor(moduleCount / input0Size);

            return [new InverterConfig([{ stringSize: input0Size, stringCount: totalStrings }])];
        }

        const minInput0Strings = Math.ceil((minAllocation * moduleCount) / input0Size);
        const maxInput0Strings = Math.floor(((1 - minAllocation) * moduleCount) / input0Size);

        const solutions = [];

        for (let input0Strings = minInput0Strings; input0Strings <= maxInput0Strings; input0Strings += 1) {
            // scan through valid input ranges, assign the rest of the modules to the second input.
            // if both are >= minAllocation, then its considered a valid solution
            const input1Strings = Math.floor((moduleCount - input0Strings * input0Size) / input1Size);

            const newConfig = new InverterConfig([
                { stringSize: input0Size, stringCount: input0Strings },
                { stringSize: input1Size, stringCount: input1Strings },
            ]);

            const input0Share = newConfig.moduleCount(0) / newConfig.moduleCount();

            if (input0Share >= minAllocation && input0Share <= 1 - minAllocation) {
                solutions.push(newConfig);
            }
        }

        return solutions;
    }

    /**
     * get a scoring function for picking a solution prioritizing for
     * 1. eliminating unused modules
     * 2. using fewer strings
     * 3. balancing power across the inputs
     *
     * this function should guarantee that there is always at least an order of
     * magnitude between subsequent, priorities (2 orders of mag for unused modules)
     */
    static _getScoringFunction(moduleCount, { min: minStringSize, max: maxStringSize }) {
        // first goal is reducing how many modules are left unused
        const unusedModuleCostFactor = 1000;

        // second goal is reducing the total string count
        // cost of 100 gives objetive range of [0, 100]; where 100 is when the
        // solution is at the theoretical maximum number of strings required to
        // cover the set and 0 is when the solution is at the theoretical minimum
        // number of strings to cover the set
        const stringCountCostFactor = 100;
        const minStringCount = Math.floor(moduleCount / maxStringSize);
        const maxStringCount = Math.ceil(moduleCount / minStringSize);

        // if the string range is zero, then the modules divide evenly,
        // so this metric doesnt matter anyway, but now we don't divide by zero
        const stringRange = maxStringCount - minStringCount || 1;

        // third goal is balancing power across inputs
        // cost of 2 gives objective range of [0, 1] where 1 is the worst
        const balancedInputCostFactor = 2;

        return function _scoreSolution(inverterConfig) {
            let balancedInputCost = 0;
            if (inverterConfig.inputs.length === 2) {
                balancedInputCost =
                    balancedInputCostFactor *
                    Math.abs(inverterConfig.moduleCount(0) / inverterConfig.moduleCount(1) - 1);
            }

            return (
                unusedModuleCostFactor * (moduleCount - inverterConfig.moduleCount()) +
                (stringCountCostFactor * Math.max(inverterConfig.stringCount() - minStringCount, 0)) / stringRange +
                balancedInputCost
            );
        };
    }
}

export class ScheduleBuilder {
    constructor(stringBounds, totalModules, rawInverterCount) {
        if (stringBounds.min > totalModules) {
            const { min, max } = stringBounds;
            throw Error(`Not enough modules (${totalModules}) for stringBounds([${min}, ${max}])`);
        }

        this.stringBounds = stringBounds;
        this.totalModules = totalModules;
        this.rawInverterCount = rawInverterCount;

        this.inverterSchedule = [];
        this._configurations = {};
    }

    scheduledModules() {
        return _.sumBy(this.inverterSchedule, (config) => config.moduleCount());
    }

    unusedModules() {
        return this.totalModules - this.scheduledModules();
    }

    /**
     * return an inverter configuration that has at most as many modules as
     * targetModuleCount.
     *
     * the delta will always be non-positive, because the the configuration
     * can only include fewer modules than the passed in targetModuleCounts.
     */
    getClosestConfig(targetModuleCount) {
        let { delta, config } = this._configurations[targetModuleCount] || {};

        if (config === undefined) {
            // create a configuration that uses as close to (<=) target Module count as possible
            config = InverterConfig.createOptimized(this.stringBounds, targetModuleCount);
            delta = config.moduleCount() - targetModuleCount;

            // because InverterConfig.createOptimized always prioritizes using
            // more modules over other constraints, if there are any
            // unused modules in the configruation, then we can prepopulate the
            // cache of configurations for the relevant smaller counts, since
            // they will all result in the same final solution
            for (let i = delta; i <= 0; i += 1) {
                this._configurations[targetModuleCount + i] = {
                    delta: i,
                    config: config.getCopy(),
                };
            }
        }

        return { delta, config: config.getCopy() };
    }

    /**
     * get a configuration that has more modules than the passed in targetModuleCount
     * the returned delta will always be positive
     */
    getLargerConfig(targetModuleCount) {
        let adder = 1;
        let config;

        do {
            config = this.getClosestConfig(targetModuleCount + adder).config;
            adder += 1;
        } while (config.moduleCount() <= targetModuleCount);

        return { config: config.getCopy(), delta: config.moduleCount() - targetModuleCount };
    }

    /**
     * create an inverter that attempts to allocate out all the modules across
     * an array of InverterConfigs
     */
    buildSchedule() {
        const { totalModules, stringBounds, rawInverterCount } = this;

        // may not need to the total number of inverters
        const inverterCount = Math.min(rawInverterCount, Math.floor(totalModules / stringBounds.min));

        const baseInverterModules = Math.floor(totalModules / inverterCount);
        const { config } = this.getClosestConfig(baseInverterModules);

        this.inverterSchedule = ScheduleBuilder.createHomogenousSchedule(config, inverterCount);

        let unusedModules = this.unusedModules();
        let currentChange = 0;

        do {
            currentChange = this.executeSmallestMove(unusedModules);
            unusedModules -= currentChange;
        } while (unusedModules > 0 && currentChange !== 0);

        return this.inverterSchedule;
    }

    /**
     * take the smallest inverter configuration where is possible to increase
     * it's size to the next largest configuration.  Update the config if the
     * change is below the maxChange threshold (this will increase total
     * module count by [1..maxChange] modules.
     *
     * This relies on the fact that the inverterSchedule is always ordererd
     * from smallest to largest inverter.
     *
     * The goal of this function should be to have a schedule of as
     * evenly-sized configurations as possible (e.g. no large or small)
     * outliers.
     *
     * TODO: could implement a borrowing algorithm so that the size allocation
     * can move modules from one inverter to another if the resultant size
     * delta is consistent.  In practices this passes every 'human consistent'
     * edge case, and fixed-inverter count allocations will be phased out for a
     * 'max power per inverter' type calculation.
     */
    executeSmallestMove(maxChange) {
        for (const config of this.inverterSchedule) {
            const { delta, config: largerConfig } = this.getLargerConfig(config.moduleCount());

            if (delta <= maxChange) {
                this.replaceConfig(config, largerConfig);
                return delta;
            }
        }

        return 0;
    }

    /**
     * insert into the inverterSchedule while preserving ordering by size
     */
    insertConfig(config) {
        const index = _.sortedIndexBy(this.inverterSchedule, config, (x) => x.moduleCount());
        this.inverterSchedule.splice(index, 0, config);
    }

    /**
     * replace an element in the inverterSchedule while preserving ordering
     * by size
     */
    replaceConfig(oldConfig, newConfig) {
        this.inverterSchedule.splice(this.inverterSchedule.indexOf(oldConfig), 1);
        this.insertConfig(newConfig);
    }

    static createHomogenousSchedule(baseInverterConfig, count) {
        const schedule = new Array(count);

        for (let i = 0; i < count; i += 1) {
            schedule[i] = baseInverterConfig.getCopy();
        }

        return schedule;
    }
}
