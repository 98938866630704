import { getMostImportantModuleCharacterization } from './string_sizing';
import { groupArray } from '../components/helpers';

export class InputConfiguration {
    // get the ideal module configuration for each potential number of modules
    // prioritize parallel over series
    // if multiple strings in parallel, they must all be the same length

    constructor(wiringZone, ignoreUser = false) {
        this.wiringZone = wiringZone;
        this.ignoreUser = ignoreUser;

        this.constraints = this.calculateConstraints();
        this.validOptions = this.generateOptions();

        const { parallel, series } = this.getLargestConfig();

        this.maxModules = parallel * series;

        if (parallel > 1 && series > 1) {
            this.description = `${parallel} x ${series} modules in series`;
        } else if (parallel > 1) {
            this.description = `${parallel} modules in parallel`;
        } else if (series > 1) {
            this.description = `${series} modules in series`;
        } else {
            this.description = '1 module per device';
        }
    }

    getConfig(batchSize) {
        return this.validOptions[batchSize];
    }

    getLargestConfig() {
        return this.getSmallerConfig(this.constraints.parallelMax * this.constraints.seriesMax);
    }

    getSmallerConfig(targetBatchSize) {
        while (targetBatchSize > 0) {
            const conf = this.validOptions[targetBatchSize];
            if (conf != null) {
                return conf;
            }

            targetBatchSize--;
        }

        return null;
    }

    generateOptions({ parallelMax, seriesMax, powerMax } = this.constraints) {
        const connections = { 1: { parallel: 1, series: 1 } }; // ensure a single module solution is always available

        for (let parallel = parallelMax; parallel > 0; parallel--) {
            for (let series = seriesMax; series > 0; series--) {
                const moduleCount = parallel * series;

                if (moduleCount <= powerMax && connections[moduleCount] == null) {
                    connections[moduleCount] = { parallel, series };
                }
            }
        }

        return connections;
    }

    createConnection(fieldModules, ctors) {
        // should always return a single element:`
        // a parallel connection w/ single module children
        // a parallel connection w/ series connection children (w/ module children)
        // a single module
        // a series connection w/ module children
        const { parallel } = this.validOptions[fieldModules.length];

        if (parallel === 1) {
            return fieldModules.length === 1
                ? fieldModules[0]
                : ctors.SeriesConnection.create(this.wiringZone, fieldModules);
        }

        const seriesGroups = groupArray(fieldModules, parallel);

        return ctors.ParallelConnection.create(
            this.wiringZone,
            seriesGroups.map((fms) =>
                fms.length === 1 ? fms[0] : ctors.SeriesConnection.create(this.wiringZone, fms),
            ),
        );
    }

    createInputCircuits(fieldModules, minStringLength = Number.NEGATIVE_INFINITY, constructors = undefined) {
        // return an array of batch sizes for the given count of modules
        // split into the the smallest number of batchs that satisfy the minimum string length, and can
        // be fit into a viable connection
        fieldModules = fieldModules.slice();
        const moduleCount = fieldModules.length;

        const targetBatchCount = Math.max(Math.ceil(moduleCount / this.maxModules), minStringLength);
        const maxAllowedSize = Math.ceil(moduleCount / targetBatchCount);

        const rtn = [];

        let config = this.getSmallerConfig(Math.min(fieldModules.length, maxAllowedSize));

        while (config != null) {
            // algo works because 1 module is always a valid
            const moduleBatch = fieldModules.splice(0, config.parallel * config.series);
            rtn.push(this.createConnection(moduleBatch, constructors));

            config = this.getSmallerConfig(Math.min(fieldModules.length, maxAllowedSize));
        }

        return rtn;
    }

    calculateConstraints() {
        const { wiringZone, ignoreUser } = this;

        const { module_config_series: userSeriesMax, module_config_parallel: userParallelMax, inverter } = wiringZone;

        let voltageSeriesMax = 1;
        let currentParallelMax = 1;
        let modulePowerMax = 1;

        let forceSingleModule = false;

        const messages = [];

        const device =
            // need to always choose a microinverter first, since selecting a microinverter will effect wiring,
            // but won't necessarily clear out the optimizer
            inverter && inverter.microinverter === true ? inverter : wiringZone.power_optimizer;

        const char = getMostImportantModuleCharacterization(wiringZone);
        let inverterLoadRatio = 1;

        if (device == null || device.device_type_name === 'buck_optimizer') {
            // don't support multimodule input for buck optimizers
            forceSingleModule = true;
            messages.push('Multiple modules unsupported for this configuration');
        } else if (char != null) {
            // AC (microinverter) Designs typically overpower the inverter by a specified ratio, but
            // DC (optimizer) designs have a hard power limit

            // eslint-disable-next-line eqeqeq
            if (device.microinverter == true) {
                // current UI doesn't not allow user to set, otherwise we'd use wiringZone.max_dc_ac_ratio
                inverterLoadRatio = 1.25;
            }

            modulePowerMax = Math.floor((device.max_power * inverterLoadRatio) / char.power) || 1;

            if (device.max_input_current == null || device.max_input_current === 0) {
                // if no input current specified, assume only one module in parallel possible
                if (device.microinverter === true && device.modules_per_device != null) {
                    // to avoid backupdating every microinverter in the database, fallback to the DB Value
                    currentParallelMax = device.modules_per_device;
                } else {
                    currentParallelMax = 1;
                }
            } else {
                currentParallelMax = Math.floor(device.max_input_current / char.i_sc) || 1;
            }

            voltageSeriesMax = Math.floor((device.max_mpp_voltage || device.max_voltage) / char.v_mp) || 1;
        }

        let parallelMax = currentParallelMax;
        let seriesMax = voltageSeriesMax;
        let powerMax = modulePowerMax;

        if (forceSingleModule === false) {
            if (ignoreUser === false) {
                parallelMax = userParallelMax != null ? userParallelMax : currentParallelMax;
                seriesMax = userSeriesMax != null ? userSeriesMax : voltageSeriesMax;
                powerMax = userParallelMax != null || userSeriesMax != null ? parallelMax * seriesMax : modulePowerMax;
            }

            if (parallelMax > currentParallelMax) {
                messages.push(`Current limit (${currentParallelMax} modules) exceeded`);
            }

            if (seriesMax > voltageSeriesMax) messages.push(`Voltage limit (${voltageSeriesMax} modules) exceeded`);

            if (powerMax > modulePowerMax) {
                if (device != null && device.microinverter) {
                    messages.push(`Power limit (${device.max_power}w x ${inverterLoadRatio}) exceeded`);
                } else if (device != null) {
                    messages.push(`Power limit (${device.max_power}w) exceeded`);
                }
            }
        }

        return {
            parallelMax,
            seriesMax,
            powerMax,

            modulePowerMax,
            voltageSeriesMax,
            currentParallelMax,

            messages,
        };
    }
}

export default InputConfiguration;
