import * as THREE from 'three';
import Logger from 'js-logger';
import { TYPE_POLYGON, TYPE_LINE_STRING, TYPE_MULTI_GEOMETRY } from '../../designer/overlays/KMLDocument';
import { Vector, Matrix } from '../../utilities/geometry';
import { forceMultiPolygon3Winding, Plane3 } from '../../utilities/geometry/geo3';
import { loadImage } from '../../utilities/io';
import * as GLH from '../GLHelpers';
import { PrimitiveMeshStroke, PrimitiveMeshFill } from '../Primitives';
import { BRIGHT_GREEN } from '../RendererOptions';
import { MAX_OVERLAY_ORDER } from './OverlayHelpers';

const logger = Logger.get('RenderableKMLOverlay');

export class RenderableKMLOverlay {
    constructor(renderer, overlay) {
        this.overlay = overlay;
        this.renderer = renderer;
        this.primitives = [];
        this.scene = null;
        this.renderPromise = this.initializeKML();
        this.renderOrder = this.overlay.order;
    }

    ready() {
        return this.renderPromise;
    }

    async initializeKML() {
        // If the document was already loaded in the controller, we don't need to load it again
        if (!this.overlay.kml) {
            await this.overlay.loadDocument();
        }
    }

    clearRenderable() {
        this.primitives.forEach((prim) => {
            prim.clearInstances();
        });

        if (this.scene) {
            GLH.removeInstance(this.scene);
            this.scene = null;
        }
        this.primitives = [];
        this.renderer.dirtyFrame();
    }

    renderRenderable(renderOptions = {}) {
        this.ready()
            .then(() => {
                this.internalRenderRenderable(renderOptions);
            })
            .catch((err) => {
                logger.warn(err);
            });
    }

    internalRenderRenderable(renderOptions) {
        this.clearRenderable();

        this.scene = new THREE.Scene();
        this.renderer.overlayLayer.add(this.scene);

        this.renderOrder = renderOptions.renderOnTop ? MAX_OVERLAY_ORDER : this.overlay.order;

        this.renderKMLElements(renderOptions);

        this.scene.visible = this.overlay.visible;
    }

    renderKMLElements(renderOptions) {
        const overlayPromises = [];

        for (const placemark of this.overlay.kml.placemarks) {
            const geometry = placemark.geometry;
            this._createGeometry(placemark, geometry, renderOptions);
        }

        for (const groundOverlay of this.overlay.kml.groundOverlays) {
            overlayPromises.push(
                this._createGroundOverlay(groundOverlay, renderOptions).then((prims) => {
                    this.primitives.concat(prims);
                }),
            );
        }

        Promise.all(overlayPromises);
    }

    _translatePoint({ lat, lng, alt = 0 } = {}) {
        const vec = this.renderer.toXY({ latitude: lat, longitude: lng });
        return new THREE.Vector3(vec.x, vec.y, alt);
    }

    _translatePath(path) {
        return path.map((p) => this._translatePoint(p));
    }

    _createPolygon(placemark, polygon, renderOptions) {
        const { style } = placemark;

        // convert paths to correct orientation before passing to renderPrimitive
        const groundPlane = new Plane3(new THREE.Vector3(0, 0, 1), 0);
        const outerPaths = [this._translatePath(polygon.outerBoundaryIs.linearRing.coordinates)];
        const innerPaths = [];
        for (const boundary of polygon.innerBoundaryIs) {
            innerPaths.push(this._translatePath(boundary.linearRing.coordinates));
        }
        const geometryPolygon = forceMultiPolygon3Winding(outerPaths, innerPaths, groundPlane);

        if (style.fill) {
            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshFill, {
                    fillColor: `#${style.fillColor.rgb}`,
                    fillOpacity: style.fillColor.opacity,
                    geometry: GLH.makeMultiPolygonGeometrySolid(geometryPolygon),
                    material: this.renderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
                    renderOrder: this.renderOrder,
                    scene: this.scene,
                }),
            );
        }

        if (style.outline) {
            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                    geometry: GLH.makeWireGeometry(
                        GLH.pathToPolygonPoints(this._translatePath(polygon.outerBoundaryIs.linearRing.coordinates)),
                    ),
                    material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                    renderOrder: this.renderOrder,
                    scene: this.scene,
                    strokeColor: `#${style.lineColor.rgb}`,
                    strokeOpacity: style.lineColor.opacity,
                    strokeWeight: style.width,
                }),
            );

            for (const boundary of polygon.innerBoundaryIs) {
                this.primitives.push(
                    this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                        geometry: GLH.makeWireGeometry(
                            GLH.pathToPolygonPoints(this._translatePath(boundary.linearRing.coordinates)),
                        ),
                        material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                        renderOrder: this.renderOrder,
                        scene: this.scene,
                        strokeColor: `#${style.lineColor.rgb}`,
                        strokeOpacity: style.lineColor.opacity,
                        strokeWeight: style.width,
                    }),
                );
            }
        }

        if (renderOptions.renderEditWidgets) {
            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                    geometry: GLH.makeWireGeometry(
                        GLH.pathToPolygonPoints(this._translatePath(polygon.outerBoundaryIs.linearRing.coordinates)),
                    ),
                    material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                    renderOrder: this.renderOrder,
                    scene: this.scene,
                    strokeColor: BRIGHT_GREEN,
                    strokeOpacity: 1.0,
                    strokeWeight: 1.0,
                }),
            );
        }
    }

    _createLineString(placemark, lineString, renderOptions) {
        if (lineString.coordinates) {
            const { style } = placemark;

            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                    geometry: GLH.makeWireGeometry(GLH.pathToLinePoints(this._translatePath(lineString.coordinates))),
                    material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                    renderOrder: this.renderOrder,
                    scene: this.scene,
                    strokeColor: renderOptions.renderEditWidgets ? BRIGHT_GREEN : `#${style.lineColor.rgb}`,
                    strokeOpacity: renderOptions.renderEditWidgets ? 1.0 : style.lineColor.opacity,
                    strokeWeight: style.width,
                }),
            );
        }
    }

    _createGeometry(placemark, geometry, renderOptions) {
        try {
            if (geometry.type === TYPE_POLYGON) {
                this._createPolygon(placemark, geometry, renderOptions);
            } else if (geometry.type === TYPE_LINE_STRING) {
                this._createLineString(placemark, geometry, renderOptions);
            } else if (geometry.type === TYPE_MULTI_GEOMETRY) {
                for (const childGeometry of geometry.children) {
                    this._createGeometry(placemark, childGeometry, renderOptions);
                }
            }
        } catch (e) {
            logger.warn('Error rendering geometry: ', placemark, geometry);
            logger.warn(e);
        }
    }

    async _createGroundOverlay(groundOverlay, renderOptions) {
        const href = groundOverlay.icon.href;
        const src = this.overlay.imageDict ? this.overlay.imageDict[href] : href;

        const latlonBox = groundOverlay.latLonBox;
        const latlonQuad = groundOverlay.latLonQuad;

        const renderImageOptions = {
            opacity: groundOverlay.opacity,
            renderer: this.renderer,
            renderOptions,
            renderOrder: this.renderOrder,
            scene: this.scene,
            src,
        };

        let quadPoints = [];
        if (latlonBox) {
            const sw = this.renderer.toXY({ latitude: latlonBox.south, longitude: latlonBox.west });
            const ne = this.renderer.toXY({ latitude: latlonBox.north, longitude: latlonBox.east });

            const maxX = Math.max(sw.x, ne.x);
            const minX = Math.min(sw.x, ne.x);
            const maxY = Math.max(sw.y, ne.y);
            const minY = Math.min(sw.y, ne.y);

            quadPoints = [
                new Vector(minX, maxY, 0),
                new Vector(minX, minY, 0),
                new Vector(maxX, minY, 0),
                new Vector(maxX, maxY, 0),
            ];

            const rotation = latlonBox.rotation;
            if (rotation) {
                const matrix = Matrix.rotateZ(rotation, new Vector(minX + (maxX - minX) / 2, minY + (maxY - minY) / 2));

                quadPoints = matrix.transform(quadPoints);
            }
        } else if (latlonQuad) {
            quadPoints = _.map(latlonQuad, (point) => this.renderer.toXY({ longitude: point.x, latitude: point.y }));
        }

        return _createImageQuad(quadPoints, renderImageOptions);
    }
}

async function _createImageQuad(quadPoints, renderImageOptions) {
    const { opacity, renderer, renderOptions, renderOrder, scene, src } = renderImageOptions;

    const quadUVs = [
        new THREE.Vector2(0, 1),
        new THREE.Vector2(0, 0),
        new THREE.Vector2(1, 0),
        new THREE.Vector2(1, 1),
    ];

    const texture = GLH.makeTextureFromImage(await loadImage(src));
    texture._owned = true;

    texture.wrapS = THREE.MirroredRepeatWrapping;
    texture.wrapT = THREE.MirroredRepeatWrapping;

    const prims = [];
    prims.push(
        renderer.renderPrimitive(PrimitiveMeshFill, {
            geometry: GLH.makeQuadTexturedGeometryFromPoints(quadPoints, quadUVs),
            material: renderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
            opacity: opacity || 1,
            renderOrder,
            scene,
            selectionData: null,
            texture,
        }),
    );

    if (renderOptions.renderEditWidgets) {
        prims.push(
            renderer.renderPrimitive(PrimitiveMeshStroke, {
                geometry: GLH.makeWireGeometry(GLH.pathToPolygonPoints(quadPoints)),
                material: renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                renderOrder,
                scene,
                strokeColor: BRIGHT_GREEN,
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
            }),
        );
    }

    return prims;
}
