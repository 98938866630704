import { dumpComponentTree, dumpSldTree } from 'helioscope/app/designer/debug/tree';

let currentDesign;
async function installWiringDebuggerEventListener(keyCode) {
    const { getSldTree } = await import('helioscope/app/singleline/core');

    document.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.code === keyCode) {
            e.preventDefault();
            console.log('Component tree:');
            const components = currentDesign.generateWiring();
            dumpComponentTree(components);
            console.log('SLD tree:');
            const sldTree = getSldTree(components);
            dumpSldTree(sldTree);
        }
    });
}

function installWiringDebugger(dispatcher, keyCode) {
    if (!currentDesign) {
        installWiringDebuggerEventListener(keyCode);
    }
    currentDesign = dispatcher.design;
}

let installed = false;
export function installDebugTools(dispatcher) {
    installWiringDebugger(dispatcher, 'KeyW');

    if (installed) {
        return;
    }

    installed = true;
}
