export const icons = {
    interconnectIconTexture: require('helioscope/app/apollo/static/interconnect-icon-256.png'),
    inverterIconTexture: require('helioscope/app/apollo/static/inverter-icon-256.png'),
    combinerIconTexture: require('helioscope/app/apollo/static/combiner-icon-64.png'),
    panelIconTexture: require('helioscope/app/apollo/static/panel-icon-64.png'),
    rainbowMap: require('helioscope/app/apollo/static/rainbow-map.png'),
};

export const fonts = {
    notoSansRegularFont: {
        type: 'texture',
        data: require('helioscope/app/apollo/static/font-noto-sans-22.json'),
        texture: {
            name: 'notoSansRegularTexture',
            url: require('helioscope/app/apollo/static/font-noto-sans-22.png'),
        },
    },
};
