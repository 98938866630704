import Logger from 'js-logger';
import _ from 'lodash';
import * as THREE from 'three';
import Q from 'q';

import * as analytics from 'helioscope/app/utilities/analytics';
import { toRadians, lerp, invLerp } from 'helioscope/app/utilities/geometry';
import { getBlobFromCanvas } from 'helioscope/app/utilities/io';
import { utcDate, calculateSolarAngle } from 'helioscope/app/utilities/solar';

import { SimpleShapeBuilder } from './SimpleShapeBuilder';

const logger = Logger.get('GLRenderer');
const VIEWPORT_SCALE_TO_FOV_RATIO = 30;

export function checkWebGL() {
    try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (ctx) return true;
    } catch (e) {
        logger.warn(e);
    }

    logger.error('webgl context check failed');
    return false;
}

export class GLRenderer {
    constructor() {
        // for perf we want to truncate preframeCallbacks instead of allocating
        // a new one on every preRenderUpdate(). However, while we execute
        // queued callbacks, new ones might be queued so we need 2 backing arrays
        // for preframeCallbacks and alternate between them
        this.preframeCallbacksSingle1 = [];
        this.preframeCallbacksSingle2 = [];
        this.preframeCallbacksSingle = this.preframeCallbacksSingle1;
        this.preframeCallbacksRepeat = [];

        this.clientSize = new THREE.Vector2(0, 0);
        this.cameraCenter = new THREE.Vector3(0, 0, 0);
        this.cameraTheta = 0;
        this.cameraPhi = 0;
        this.viewportScale = 0.1;
        this.needsRenderFrame = false;
        this.needsCameraUpdate = false;
        this.viewMode = 'solid';
        this.shapeBuilder = new SimpleShapeBuilder();

        this.tinyZOffset = 0.01; // to avoid occluding certain items

        this._rendererReady = Q.defer();
        this.requiredInitTasks = 1;

        this.renderFrame = this.renderFrame.bind(this);
    }

    initGLRenderer(
        container,
        teamId,
        designId,
        { antialias = false, preserveDrawingBuffer = false, pixelRatio = window.devicePixelRatio } = {},
    ) {
        // set webgl container
        this.container = container;

        // init webgl renderer
        try {
            this.renderer = new THREE.WebGLRenderer({ antialias, preserveDrawingBuffer });
            this.renderer.setPixelRatio(pixelRatio);
            this.renderer.autoClear = false;
            container.appendChild(this.renderer.domElement);

            this.surveyContextProps(teamId, designId);
        } catch (err) {
            logger.error('THREE.WebGLRenderer init failed');
            this.initFailed = true;
        }

        // instantiate a loader
        this.textureLoader = new THREE.TextureLoader();
        this.textureLoader.crossOrigin = '';

        // primary camera
        this.primaryCamera = new THREE.OrthographicCamera();

        // scenes
        this.renderSceneList = [];
        this.offscreenSceneList = [];

        // main layer
        this.baseLayerScene = new THREE.Scene();

        // selection layer
        this.selectionLayerScene = new THREE.Scene();

        // interaction layer
        this.screenSpaceCamera = new THREE.OrthographicCamera();
        this.screenSpaceCamera.near = -9999;
        this.screenSpaceCamera.far = 9999;
        this.screenSpaceCamera.lookAt(new THREE.Vector3(0, 0, 0));
        this.screenSpaceCamera.position.z = 1;

        // initialize basic graphic resources
        this.graphicResourceCache = {
            // intended for unrendererd selection objects
            dummyMaterial: new THREE.MeshBasicMaterial({ color: 0xffffff }),
        };

        // events
        this.resizeHandler = (event) => {
            this.onWindowResize(event);
        };
        window.addEventListener('resize', this.resizeHandler, false);

        // start rendering
        this.onWindowResize();
        this.recenterView();

        this.requiredInitTasks--;

        this.capturePromise = null;
    }

    shutdownGLRenderer() {
        if (!this.renderer) return;

        for (const key of Object.keys(this.graphicResourceCache)) {
            if (this.graphicResourceCache[key]) {
                if (this.graphicResourceCache[key].dispose) {
                    this.graphicResourceCache[key].dispose();
                }

                delete this.graphicResourceCache[key];
            }
        }

        for (const i of this.offscreenSceneList) {
            i.userData.renderTarget.dispose();
        }

        if (this.requestFrameID) {
            this.container.removeChild(this.renderer.domElement);
            this.unrequestFrame();
        }

        if (this.shapeBuilder) {
            this.shapeBuilder.clearAllShapes();
        }

        window.removeEventListener('resize', this.resizeHandler, false);

        this.renderer.dispose();

        this.renderer = null;
    }

    surveyContextProps(teamId, designId) {
        const ctx = this.renderer.getContext();

        this.glContextProps = {
            maxTextureFragmentChannels: ctx.getParameter(ctx.MAX_TEXTURE_IMAGE_UNITS),
            maxTextureVertexChannels: ctx.getParameter(ctx.MAX_VERTEX_TEXTURE_IMAGE_UNITS),
            maxRenderBufferSize: ctx.getParameter(ctx.MAX_RENDERBUFFER_SIZE),
            maxTextureSize: ctx.getParameter(ctx.MAX_TEXTURE_SIZE),
            version: ctx.getParameter(ctx.VERSION),
            ANGLE_instanced_arrays: !!ctx.getExtension('ANGLE_instanced_arrays'),
            EXT_texture_filter_anisotropic: !!ctx.getExtension('EXT_texture_filter_anisotropic'),
            WEBGL_depth_texture: !!ctx.getExtension('WEBGL_depth_texture'),
            WEBGL_draw_buffers: !!ctx.getExtension('WEBGL_draw_buffers'),
            OES_texture_float: !!ctx.getExtension('OES_texture_float'),
            OES_texture_float_linear: !!ctx.getExtension('OES_texture_float_linear'),
            OES_vertex_array_object: !!ctx.getExtension('OES_vertex_array_object'),
            design_id: designId,
            team_id: teamId,
        };

        analytics.track('designer.webgl_context', this.glContextProps);
    }

    recenterView() {
        this.cameraCenter = new THREE.Vector3(0, 0, 0);
        this.cameraTheta = 0;
        this.cameraPhi = 0;
        this.viewportScale = 0.1;
        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }

    setViewMode(mode) {
        this.viewMode = mode;
        this.renderDesign(this.design);
        this.dirtyFrame();
    }

    addPrimaryRenderLayer() {
        const scene = new THREE.Scene();
        scene.userData.camera = this.primaryCamera;
        this.renderSceneList.push(scene);
        return scene;
    }

    addScreenRenderLayer() {
        const scene = new THREE.Scene();
        scene.userData.camera = this.screenSpaceCamera;
        this.renderSceneList.push(scene);
        return scene;
    }

    addOffscreenRenderLayer(width, height, camera) {
        const scene = new THREE.Scene();
        scene.userData.renderTarget = new THREE.WebGLRenderTarget(width, height);
        scene.userData.camera = camera;
        this.offscreenSceneList.push(scene);
        return scene;
    }

    addCustomRenderLayer(callback) {
        const scene = new THREE.Scene();
        scene.userData.customRenderCallback = callback;
        this.renderSceneList.push(scene);
        return scene;
    }

    dirtyCamera() {
        this.needsCameraUpdate = true;
    }

    dirtyFrame() {
        this.needsRenderFrame = true;
    }

    renderFrame() {
        this.requestFrame();

        if (this.clientSize.x !== this.container.clientWidth || this.clientSize.y !== this.container.clientHeight) {
            this.onWindowResize();
        }

        if (this.verifyRendererReady() && this.needsRenderFrame) {
            this.needsRenderFrame = false;
            this.updateCamera = this.needsCameraUpdate; // used by client code
            this.needsCameraUpdate = false;
            this.preRenderUpdate();

            this.selectionLayerScene.updateMatrixWorld(true);

            for (const scene of this.offscreenSceneList) {
                this.renderer.setRenderTarget(scene.userData.renderTarget);
                this.renderer.clear();
                this.renderer.render(scene, scene.userData.camera);
                this.renderer.setRenderTarget(null);
            }

            this.renderer.setClearColor(0xffffff, 1.0);
            this.renderer.clear();

            this.renderer.render(this.baseLayerScene, this.primaryCamera);
            for (const scene of this.renderSceneList) {
                if (scene.userData.customRenderCallback) {
                    scene.userData.customRenderCallback(this.renderer);
                    continue;
                }

                this.renderer.clear(false, true, false);
                this.renderer.render(scene, scene.userData.camera);
            }

            this.onFrameRenderFinished();
        }
    }

    async capture() {
        this.capturePromise = Q.defer();
        this.dirtyFrame();
        return this.capturePromise.promise;
    }

    async cameraUpdateCompleted() {
        this.cameraUpdatePromise = Q.defer();
        this.dirtyCamera();
        return this.cameraUpdatePromise.promise;
    }

    onFrameRenderFinished() {
        const { capturePromise, cameraUpdatePromise } = this;
        if (capturePromise && this.renderer) {
            getBlobFromCanvas(this.renderer.domElement).then((blob) => {
                if (!this.renderer) {
                    return capturePromise.reject('renderer lost');
                }

                return capturePromise.resolve({
                    blob,
                    width: this.renderer.domElement.width,
                    height: this.renderer.domElement.height,
                });
            });

            delete this.capturePromise;
        }

        if (cameraUpdatePromise) {
            cameraUpdatePromise.resolve();
            delete this.cameraUpdatePromise;
        }
    }

    requestFrame() {
        this.requestFrameID = window.requestAnimationFrame(this.renderFrame);
    }

    unrequestFrame() {
        if (this.requestFrameID) {
            window.cancelAnimationFrame(this.requestFrameID);
            this.requestFrameID = null;
        }
    }

    destroy() {
        this.shutdownRenderer();
        this.$$destroyed = true;
    }

    verifyRendererReady() {
        if (this.requiredInitTasks > 0) return false;

        if (!this.readyResolved) {
            this._rendererReady.resolve();
            this.readyResolved = true;
        }

        return this.readyResolved;
    }

    onReady() {
        return this._rendererReady.promise;
    }

    renderPrimitive(Ctor, options) {
        const newprim = new Ctor(this);
        newprim.setRenderOptions(options);
        newprim.makeInstances();

        this.dirtyFrame();
        return newprim;
    }

    inlineShaderMaterial(vertexShaderName, fragmentShaderName) {
        const key = `_inlineShaderMaterial_${vertexShaderName}_${fragmentShaderName}`;
        let cached = this.graphicResourceCache[key];
        if (!cached) {
            const vertexTag = document.getElementById(vertexShaderName);
            const fragmentTag = document.getElementById(fragmentShaderName);
            if (vertexTag && fragmentTag) {
                const vertexShader = vertexTag.textContent;
                const fragmentShader = fragmentTag.textContent;
                cached = new THREE.ShaderMaterial({ uniforms: {}, vertexShader, fragmentShader });
                this.graphicResourceCache[key] = cached;
            } else {
                // may happen for example if page is changed and this is called in a shutdown draw function
                return new THREE.MeshBasicMaterial();
            }
        }

        return cached;
    }

    lightDirectionAtTime(time) {
        const lightTime = utcDate(time, this.design.project.time_zone_offset);
        const solarAngle = calculateSolarAngle(lightTime, this.design.project.location);
        const lightDir = new THREE.Vector3(0, -1, 0);
        const mtxt = new THREE.Matrix4().makeRotationX(toRadians(solarAngle.apparentElevation));
        const mtxp = new THREE.Matrix4().makeRotationZ(toRadians(-solarAngle.azimuth));
        lightDir.applyMatrix4(mtxt);
        lightDir.applyMatrix4(mtxp);
        return lightDir;
    }

    loadTexture(resourceName, url) {
        const promise = Q.defer();
        this.textureLoader.load(
            url,
            (texture) => {
                if (resourceName) {
                    this.graphicResourceCache[resourceName] = texture;
                }
                promise.resolve(texture);
                return texture;
            },
            () => {},
            () => {
                logger.warn(`threejs load texture failed ${url}`);

                promise.reject();
                return null;
            },
        );
        return promise.promise;
    }

    loadFontJSON(resourceName, fontData) {
        const promise = Q.defer();
        const json = fontData;
        const rawCharData = json.characters;
        json.characters = [];
        for (const key of Object.keys(rawCharData)) {
            const rawChar = rawCharData[key];
            const charCode = parseInt(key, 10);
            if (charCode + 1 > json.characters.length) {
                json.characters.length = charCode + 1;
            }
            json.characters[charCode] = rawChar;
        }

        if (resourceName) {
            this.graphicResourceCache[resourceName] = json;
        }

        promise.resolve(json);
        return promise.promise;
    }

    registerPreFrameCallback(fn, repeat = false) {
        if (repeat) {
            this.preframeCallbacksRepeat.push(fn);

            const removeFn = () => {
                _.remove(this.preframeCallbacksRepeat, (i) => i === fn);
            };

            return removeFn;
        } else {
            this.preframeCallbacksSingle.push(fn);
            return null;
        }
    }

    preRenderUpdate() {
        const preframe = this.preframeCallbacksSingle;
        if (this.preframeCallbacksSingle === this.preframeCallbacksSingle1) {
            this.preframeCallbacksSingle = this.preframeCallbacksSingle2;
        } else {
            this.preframeCallbacksSingle = this.preframeCallbacksSingle1;
        }
        // perf: re-use array instead of allocating a new one
        this.preframeCallbacksSingle.length = 0;

        for (const fn of preframe) {
            fn();
        }

        for (const fn of this.preframeCallbacksRepeat) {
            fn();
        }
    }

    transformObjectToClient(obj, camProjMtx, pt) {
        const objPt = new THREE.Vector3().copy(pt);
        objPt.applyMatrix4(obj.matrixWorld);
        objPt.applyMatrix4(camProjMtx);
        const clientPt = this.transformCanonicalToClient(objPt);
        objPt.set(clientPt.x, clientPt.y, 0);
        return objPt;
    }

    transformObjectMatrixToClient(objmtx, camProjMtx, pt) {
        const objPt = new THREE.Vector3().copy(pt);
        objPt.applyMatrix4(objmtx);
        objPt.applyMatrix4(camProjMtx);
        const clientPt = this.transformCanonicalToClient(objPt);
        objPt.set(clientPt.x, clientPt.y, 0);
        return objPt;
    }

    transformWorldToClient(pt) {
        const clipPt = new THREE.Vector3().copy(pt);
        clipPt.applyMatrix4(this.primaryCamera.matrixWorldInverse);
        clipPt.applyMatrix4(this.primaryCamera.projectionMatrix);
        return this.transformCanonicalToClient(clipPt);
    }

    transformClientToWorldRay(pt) {
        return this.transformClientToCameraRay(this.primaryCamera, pt);
    }

    transformClientToCameraRay(camera, pt) {
        let x = null;
        let y = null;

        if (this.primaryCameraType === 'Perspective') {
            const height = camera.position.z;
            const pos = height * Math.tan(((camera.fov / 2.0) * Math.PI) / 180);
            const neg = -1 * pos;

            x = lerp(neg, pos, invLerp(0, this.clientSize.x, pt.x));
            y = lerp(neg, pos, invLerp(0, this.clientSize.y, pt.y));
        } else {
            x = lerp(camera.left, camera.right, invLerp(0, this.clientSize.x, pt.x));
            y = lerp(camera.top, camera.bottom, invLerp(0, this.clientSize.y, pt.y));
        }

        const camWX = new THREE.Vector3();
        const camWY = new THREE.Vector3();
        const camWZ = new THREE.Vector3();
        camera.matrixWorld.extractBasis(camWX, camWY, camWZ);
        const origin = new THREE.Vector3()
            .addVectors(camWX.multiplyScalar(x), camWY.multiplyScalar(y))
            .add(camera.position);
        const dir = camWZ.negate();

        return { origin, dir };
    }

    transformCanonicalToClient(pt) {
        const x = lerp(0, this.clientSize.x, invLerp(-1, 1, pt.x));
        const y = lerp(0, this.clientSize.y, invLerp(1, -1, pt.y));
        return new THREE.Vector2(x, y);
    }

    computeCameraOffsets(cameraTheta, cameraPhi) {
        const up = new THREE.Vector3(0, 1, 0);

        const backward = new THREE.Vector3(0, 0, 999);
        const mtxt = new THREE.Matrix4().makeRotationX(-cameraTheta);
        const mtxp = new THREE.Matrix4().makeRotationZ(cameraPhi);

        up.applyMatrix4(mtxt);
        up.applyMatrix4(mtxp);
        backward.applyMatrix4(mtxt);
        backward.applyMatrix4(mtxp);
        return { up, backward };
    }

    recomputePrimaryCamera() {
        this.clientSize.set(this.container.clientWidth, this.container.clientHeight);

        const { up, backward } = this.computeCameraOffsets(this.cameraTheta, this.cameraPhi);
        this.primaryCamera.up.set(up.x, up.y, up.z);
        this.primaryCamera.position.addVectors(this.cameraCenter, backward);
        this.primaryCamera.lookAt(this.cameraCenter);

        if (this.primaryCameraType === 'Perspective') {
            this.primaryCamera.fov = VIEWPORT_SCALE_TO_FOV_RATIO * this.viewportScale;
        } else {
            this.primaryCamera.left = (-this.clientSize.x / 2) * this.viewportScale;
            this.primaryCamera.right = (this.clientSize.x / 2) * this.viewportScale;
            this.primaryCamera.top = (this.clientSize.y / 2) * this.viewportScale;
            this.primaryCamera.bottom = (-this.clientSize.y / 2) * this.viewportScale;
        }

        this.primaryCamera.updateProjectionMatrix();
        this.primaryCamera.updateMatrixWorld(true);

        this.primaryCamera.matrixWorldInverse.copy(this.primaryCamera.matrixWorld).invert();

        this.cameraWorldUp = new THREE.Vector3().copy(up).normalize();
        this.cameraWorldRight = new THREE.Vector3().crossVectors(up, backward).normalize();
        this.cameraWorldForward = new THREE.Vector3().copy(backward).normalize().multiplyScalar(-1.0);

        this.cameraPanForward = new THREE.Vector3().subVectors(up, backward);
        this.cameraPanForward.z = 0;
        this.cameraPanForward.normalize();
        this.cameraPanRight = new THREE.Vector3().crossVectors(up, backward).negate();
        this.cameraPanRight.z = 0;
        this.cameraPanRight.normalize();
        this.dirtyCamera();
    }

    assignCameraUniforms(uniforms, camera) {
        const actual = camera || this.primaryCamera;
        const values = {
            projMtx: { value: actual.projectionMatrix },
            camMtxInv: { value: actual.matrixWorldInverse },
        };

        _.assign(uniforms, values);
    }

    onWindowResize() {
        if (this.renderer) this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

        this.screenSpaceCamera.left = 0;
        this.screenSpaceCamera.right = this.container.clientWidth;
        this.screenSpaceCamera.top = 0;
        this.screenSpaceCamera.bottom = this.container.clientHeight;
        this.screenSpaceCamera.updateProjectionMatrix();

        this.recomputePrimaryCamera();
        this.dirtyFrame();
    }
}
