import _ from 'lodash';
import * as THREE from 'three';
import { makeWireGeometry, pathToSegmentPoints } from 'helioscope/app/apollo/GLHelpers';
import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';
import { KEY } from 'helioscope/app/utilities/helpers';
import { rotatePath, toDegrees, Vector } from 'helioscope/app/utilities/geometry';
import {
    SurfaceCursorHelper,
    addSvgContainer,
    containerPoint,
    groupAndRotateSvgPaths,
    svgStringToCursorUrl,
    zoomView,
} from './InteractHelpers';
import { PrimitiveMeshStroke } from './Primitives';
import { bulkActions } from '../designer/BulkActionsMixin';
import { CursorConfig, CursorSvgPaths } from '../designer/CursorConfig';
import { EntityPremade } from '../designer/premade';

export class InteractToolRotation {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);

        this.boundaryVectors = [];
        this.boundaryCentroid = null;
        this.boundingBoxPrimitive = null;

        this.startVector = null;
        this.rotating = false;

        this.entities = dRenderer.dispatcher.selectedEntities;

        this.newState = new Map();
        this.previousState = new Map();

        this.getBoundaryVectors(this.entities);
        this.drawBoundingBox();

        this.cursorStartVector = null;
        this.cursorOffsetAngle = -0.785398; // 45 degrees in radians. The original icon svg has a 45 degree rotation
        this.dRenderer.setCursorStyle(CursorConfig.ROTATE);

        this.dRenderer.dispatcher.setShortcutsDisabled(true);
    }

    /**
     * Calculates the vector from the centroid of the boundary to the cursor position.
     *
     * @param {Event} event - The event object containing cursor position information.
     * @returns {THREE.Vector3} The vector from the boundary centroid to the cursor position.
     */
    getCursorToCentroidVector(event) {
        const cursorPt = containerPoint(this.dRenderer, event);
        const cursorPoint = this.cursorHelper.computeCursorPositionNoSurface(cursorPt).groundPoint;
        const cursorVector = new THREE.Vector3(cursorPoint.x, cursorPoint.y, 0);
        const cursorVectorFromCentroid = new THREE.Vector3().subVectors(this.boundaryCentroid, cursorVector);
        return cursorVectorFromCentroid;
    }

    /**
     * Calculates the signed rotation angle between two vectors.
     *
     * @param {THREE.Vector3} startVector - The starting vector.
     * @param {THREE.Vector3} endVector - The ending vector.
     * @returns {number} The signed rotation angle in radians.
     */
    getSignedRotation(startVector, endVector) {
        const rotationRadians = startVector.angleTo(endVector);

        const crossProduct = new THREE.Vector3().crossVectors(startVector, endVector);
        const sign = Math.sign(crossProduct.z);
        const signedRotation = rotationRadians * sign;

        return signedRotation;
    }

    /**
     * Generates a dynamic cursor svg based on the provided rotation angle.
     *
     * @param {number} rotationAngle - The angle in radians to rotate the cursor.
     * @returns {string} - An SVG representing the rotated cursor.
     */
    generateCursor(rotationAngle) {
        const paths = CursorSvgPaths.ROTATE.paths;
        const dimensions = CursorSvgPaths.ROTATE.dimensions;

        const rotatedPathsGroup = groupAndRotateSvgPaths(paths, toDegrees(rotationAngle), dimensions);
        const cursorString = addSvgContainer(rotatedPathsGroup, dimensions);
        return svgStringToCursorUrl(cursorString, dimensions);
    }

    toolMouseDown(event) {
        this.rotating = true;
        this.startVector = this.getCursorToCentroidVector(event);

        this.entities.forEach((entity) => {
            const entityClone = _.cloneDeep(entity);
            if (entity instanceof EntityPremade) {
                this.previousState.set(entity, {
                    geometry: {
                        ...entityClone.geometry,
                        parameters: {
                            ...entityClone.geometry.parameters,
                            position: entityClone.geometry.parameters.position,
                        },
                    },
                });
            } else {
                this.previousState.set(entity, {
                    geometry: { ...entityClone.geometry, path: entityClone.geometry.path },
                });
            }
        });
    }

    toolMouseMove(event) {
        if (this.cursorStartVector === null) {
            this.cursorStartVector = this.getCursorToCentroidVector(event);
            const xAxis = new THREE.Vector3(1, 0, 0);
            this.cursorOffsetAngle += this.getSignedRotation(this.cursorStartVector, xAxis) * -1;
        }
        const currentCursorVector = this.getCursorToCentroidVector(event);
        const cursorSignedRotation = this.getSignedRotation(this.cursorStartVector, currentCursorVector);

        this.dRenderer.setCursorStyle(
            this.generateCursor((cursorSignedRotation + this.cursorOffsetAngle) * -1 + Math.PI),
        );

        if (this.startVector === null || !this.rotating) {
            return;
        }

        const endAngle = this.getCursorToCentroidVector(event);
        const signedRotation = this.getSignedRotation(this.startVector, endAngle);

        // rotate bounding box
        const rotatedBox = rotatePath(this.boundaryVectors, this.boundaryCentroid, toDegrees(signedRotation));
        this.boundaryVectors = rotatedBox;
        this.drawBoundingBox();

        // rotate entities
        const surfaces = [];
        this.entities.forEach((entity) => {
            if (entity instanceof EntityPremade) {
                entity.geometry.parameters.position = rotatePath(
                    [entity.geometry.parameters.position],
                    this.boundaryCentroid,
                    toDegrees(signedRotation),
                )[0];
                this.newState.set(entity, {
                    geometry: {
                        ...entity.geometry,
                        parameters: { ...entity.geometry.parameters, position: entity.geometry.parameters.position },
                    },
                });
                surfaces.push(entity.proxyStackableSurface());
            } else {
                entity.geometry.path = rotatePath(
                    entity.geometry.path,
                    this.boundaryCentroid,
                    toDegrees(signedRotation),
                );
                this.newState.set(entity, { geometry: { ...entity.geometry, path: entity.geometry.path } });
                surfaces.push(entity);
            }
            this.dRenderer.renderEntity(entity, true);
        });
        this.updateGeometries(surfaces);
        // update starting values
        this.startVector = endAngle;
    }

    toolMouseUp(_event) {
        bulkActions.update(this.dRenderer.dispatcher, [...this.entities], null, {
            previousState: this.previousState,
            newState: this.newState,
        });
        this.cleanUpVariables();
        this.exitRotate();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolMouseOut(_event) {}

    updateGeometries(surfaces) {
        this.dRenderer.dispatcher.designManager.designScene.updateGeometries(surfaces);
    }

    toolKeyDown(event) {
        if (event.keyCode === KEY.ESC) {
            this.exitRotate();
        }
    }

    exitRotate() {
        this.dRenderer.activateInteractTool(null);
    }

    deactivateTool() {
        this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);
        this.dRenderer.dispatcher.setShortcutsDisabled(false);
        this.cleanUpBoundingBoxRender();
    }

    cleanUpVariables() {
        this.rotating = false;
        this.startVector = null;
        this.startMousePoint = null;
    }

    cleanUpBoundingBoxRender() {
        if (this.boundingBoxPrimitive) {
            this.boundingBoxPrimitive.clearInstances();
            this.boundingBoxPrimitive = null;
        }
    }

    getLowestHighest(lowestX, lowestY, highestX, highestY, position) {
        if (lowestX === undefined || position.x < lowestX) {
            lowestX = position.x;
        }
        if (lowestY === undefined || position.y < lowestY) {
            lowestY = position.y;
        }
        if (highestX === undefined || position.x > highestX) {
            highestX = position.x;
        }
        if (highestY === undefined || position.y > highestY) {
            highestY = position.y;
        }
        return { lowestX, lowestY, highestX, highestY };
    }

    getBoundaryVectors(entities) {
        let lowestX;
        let lowestY;
        let highestX;
        let highestY;
        entities.forEach((entity) => {
            if (entity instanceof EntityPremade) {
                const position = entity.geometry.parameters.position;
                ({ lowestX, lowestY, highestX, highestY } = this.getLowestHighest(
                    lowestX,
                    lowestY,
                    highestX,
                    highestY,
                    position,
                ));
            } else {
                entity.geometry.path.forEach((position) => {
                    ({ lowestX, lowestY, highestX, highestY } = this.getLowestHighest(
                        lowestX,
                        lowestY,
                        highestX,
                        highestY,
                        position,
                    ));
                });
            }
        });

        this.boundaryCentroid = new Vector((lowestX + highestX) / 2, (lowestY + highestY) / 2);

        this.boundaryVectors = [
            new Vector(lowestX, lowestY),
            new Vector(highestX, lowestY),
            new Vector(highestX, highestY),
            new Vector(lowestX, highestY),
        ];
    }

    getLineOptions(path) {
        return {
            geometry: makeWireGeometry(pathToSegmentPoints(path)),
            material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
            scene: this.dRenderer.editSurfaceLayer,
            depthOffset: this.dRenderer.tinyZOffset,
            screenSpace: false,
            strokeColor: RendererOptions.moduleControlOptions.selectionOutlineColor,
            strokeWeight: 1.0,
        };
    }

    drawBoundingBox() {
        if (this.boundaryVectors.length === 0) {
            return;
        }

        this.cleanUpBoundingBoxRender();

        this.boundingBoxPrimitive = this.dRenderer.renderPrimitive(
            PrimitiveMeshStroke,
            this.getLineOptions(this.boundaryVectors),
        );
    }
}
