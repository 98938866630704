import { calcMinY, Matrix, Vector } from 'helioscope/app/utilities/geometry';

export const RACKING_EPSILON = 0.0000001;

export const DEFAULT_CHARACTERIZATION = {
    vector: new Vector(1.65, 0.99, 0),
    length: 1.65,
    width: 0.99,
    i_mp: 0,
    v_mp: 0,
};

/**
 * An object that contains transformation matrixes between world space and racking space.
 * See racking_v3.js for more description of racking space and world space.
 * @typedef {Object} RackingSpaceTransforms
 * @property {Matrix} transformMatrix transforms vectors from world space to racking space
 * @property {Matrix} returnMatrix the inverse -- transforms vectors from racking space to world space
 */

/**
 * Generates the racking space transform and its inverse given a field segment
 *
 * @param {FieldSegment} surface the field segment
 * @param {Object} params See LayoutRegion. An object that contains a subset of field segment properties needed for
 * layout computation
 * @returns {RackingSpaceTransforms} racking space transforms
 */
export function rackingSpaceTransforms(surface, params) {
    const rackingRotation = surface.surfaceAzimuth() - 180 + params.racking_rotation;

    // matrix to rotate the path of the field segment so that the azimuth of
    // the module orientation is directly down in the y-axis
    const matrix = Matrix.rotateZ(rackingRotation);
    const minY = calcMinY(matrix, surface.geometry.path);

    // translate the whole matrix so that the lowest 'y' value is 0, because this makes
    // calculating roof height for flush mount more straightforward
    const transformMatrix = matrix.translate(0, -minY, 0);

    // matrix to rotate the return paths back into the orientation of the array
    const returnMatrix = Matrix.translate(0, minY, 0).rotateZ(-rackingRotation);

    return { transformMatrix, returnMatrix };
}

/**
 * Generates the racking space transform and its inverse given a field segment. This is
 * for use in FixedTiltEngineV3 and unlike {@link rackingSpaceTransforms}, it transforms the
 * field segment surface such that it lies on the X-Y plane (i.e. Z = 0 for every point on the field
 * segment surface)
 *
 * @param {FieldSegment} surface the field segment
 * @param {Object} params See LayoutRegion. An object that contains a subset of field segment properties needed for
 * layout computation
 * @returns {RackingSpaceTransforms} racking space transforms
 */
export function rackingSpaceTransformsV3(surface, params) {
    const moduleAzimuth = surface.moduleAzimuth;
    const rackingRotation = surface.surfaceAzimuth() - 180 + params.racking_rotation;
    const referencePoint = surface.pointOnSurface(surface.referencePoint());

    // translate such that the lowest point on the field segment surface
    // (or a point on the lowest edge) becomes the origin (0,0,0)
    let transformMatrix = Matrix.translate(-referencePoint.x, -referencePoint.y, -referencePoint.z);
    // rotate such that the surface azimuth is aligned to the Y axis
    transformMatrix = transformMatrix.rotateZ(rackingRotation);
    // undo the surface tilt such that the resulting surface lies flat in the X-Y plane
    transformMatrix = transformMatrix.rotateX(-surface.surfaceTilt());
    // rotate such that rows of modules are parallel to the X axis
    // and that jumping from the first to the second row is in the -Y direction
    transformMatrix = transformMatrix.rotateZ(-surface.surfaceAzimuth() + moduleAzimuth);

    // Does the inverse of the above operations (note the reverse order) for the return matrix
    let returnMatrix = Matrix.identity();
    returnMatrix = returnMatrix.rotateZ(surface.surfaceAzimuth() - moduleAzimuth);
    returnMatrix = returnMatrix.rotateX(surface.surfaceTilt());
    returnMatrix = returnMatrix.rotateZ(-rackingRotation);
    returnMatrix = returnMatrix.translate(referencePoint.x, referencePoint.y, referencePoint.z);

    return {
        transformMatrix,
        returnMatrix,
    };
}

// points is Array of Vector. Find min and max y values of all points
export function pointsFindMinYMaxY(points) {
    const len = points.length;
    const p0 = points[0];
    let minY = p0.y;
    let maxY = p0.y;
    for (let i = 1; i < len; i++) {
        const y = points[i].y;
        if (y > maxY) {
            maxY = y;
        } else if (y < minY) {
            minY = y;
        }
    }
    return { minY, maxY };
}

export function createRowBounds(pathsT, startY, frameExtY, rowSpacing) {
    const bounds = [];

    const maxY = startY;
    const minY = _(pathsT).flatten().map('y').min();
    const totalGap = frameExtY + rowSpacing;

    let top = maxY;
    while (top - frameExtY > minY) {
        bounds.push({ top, bottom: top - frameExtY });

        top -= totalGap;
    }

    return bounds;
}

export function transformRacking(racking, returnMatrix) {
    for (let i = 0; i < racking.length; i++) {
        const rack = racking[i];
        rack.transform(returnMatrix);
    }
    return racking;
}

/**
 * Takes an array of racking structures and removes modules from last to first until
 * the FieldSegment's max_size constraint is satisfied. This operation is mutable and modifies
 * the input array, the RackingStructure objects, and the RackingFrame objects in-place.
 *
 * @param {FieldSegment} fieldSegment the field segment the racking structures are placed on
 * @param {RackingStructure[]} racking the automatically placed racking structures
 * @param {number} manualModuleCount an integer count of the how many manual modules
 * have been added to the field segment
 * @returns the input array (after in-place modifications)
 */
export function enforceMaxSize(fieldSegment, racking, manualModuleCount = 0) {
    const modulePower = fieldSegment.modulePower();
    const totalModuleCount = _.sumBy(racking, 'moduleCount') + manualModuleCount;

    // max size is stored in kW, so need to multiply by 1000
    if (fieldSegment.max_size && fieldSegment.max_size * 1000 <= totalModuleCount * modulePower) {
        let modulesToRemove = totalModuleCount - Math.floor((fieldSegment.max_size * 1000) / modulePower);

        while (modulesToRemove > 0 && racking.length) {
            const currentRack = racking[racking.length - 1];
            modulesToRemove -= currentRack.trimModules(modulesToRemove);

            if (currentRack.moduleCount === 0) {
                racking.pop();
            }
        }
    }

    return racking;
}

/**
 * Removes modules from the field segment surface based on the given location vectors
 * @param {Object} obj
 * @param {Object} obj.moduleCache global cache object that stores the racking structure
 * information of the field segment
 * @param {RackingStructure[]} obj.racking the racking structures of the field segment
 * @param {Vector[]} obj.locations array of Vectors representing the locations of the modules to remove
 * @param {RackingSpaceTransforms} obj.transforms
 */
export function removeModulesInLocation({ moduleCache, racking, locations, transforms }) {
    const rackCache = moduleCache.getRackCache(racking, transforms);
    const foundNodes = rackCache.findByPoints(locations);

    for (const { module, frame } of foundNodes) {
        // eslint-disable-next-line no-unused-expressions
        !module.manual && !module.removed && frame.removeModule(module);
    }
}

/**
 * Removes modules from the field segment surface based on the provided paths
 * @param {Object} obj
 * @param {Object} obj.moduleCache
 * @param {RackingStructure[]} obj.racking
 * @param {Vector[]} obj.paths
 * @param {RackingSpaceTransforms} obj.transforms
 */
export function removeModulesInPath({ moduleCache, racking, paths, transforms }) {
    const rackCache = moduleCache.getRackCache(racking, transforms);
    const foundNodes = rackCache.findByPaths(paths);

    for (const { module, frame } of foundNodes) {
        // eslint-disable-next-line no-unused-expressions
        !module.manual && !module.removed && frame.removeModule(module);
    }
}
