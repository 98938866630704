import { uniq, get, flatten } from 'lodash';

import { flMap } from 'helioscope/app/utilities/containers';

import { THE_GROUND } from 'helioscope/app/designer/field_segment/PhysicalSurface';

import { calculateSolarAngle } from 'helioscope/app/utilities/solar/solar_angles';
import { utcDate } from 'helioscope/app/utilities/solar/solar_time';
import {
    Bounds,
    correctOrientation,
    intersectPathsMulti,
    pathContainsMulti,
    simplifyPaths,
    unionPathsMulti,
    Vector,
} from 'helioscope/app/utilities/geometry';

import { multiPathOnSurface, calculatePathAbove } from './surface_helpers';

const MIN_SOLAR_ELEVATION = 2;
const DEFAULT_SHADE_START = '2016-12-21T10:00:00Z';
const DEFAULT_SHADE_END = '2016-12-21T14:00:00Z';
const DOWN = new Vector(0, 0, -1);

export class ShadowManager {
    constructor(designScene) {
        this.design = designScene.design;
        this.designScene = designScene;

        this.initialize();
    }

    initialize() {
        // Map { receiver => merged shade polgyon on the receiver surface }
        this.shadePolygons = flMap((x) => this._mergeShadePolygons(x));

        // Map { receiver => Map { caster => Shadow Polgyons on the Receiver }}
        // these get merged to create the final shade polygon
        this.shadePolygonContributors = flMap(() => new Map());

        this.solarRays = calculateSolarRays(
            get(this.design, 'project.location'),
            get(this.design, 'shade_keepouts_start', DEFAULT_SHADE_START),
            get(this.design, 'shade_keepouts_end', DEFAULT_SHADE_END),
            get(this.design, 'project.time_zone_offset', 0),
        );
    }

    /**
     * return true if this surface has any shadows casted on it's plane.  This does not
     * filter whether or not the shadows are actually within the path
     */
    hasShadow(surface) {
        return this.shadePolygonContributors.get(surface).size > 0;
    }

    /**
     * get a multipolygon describing the total shadow on the surface of an object
     */
    getShadowPath(surface) {
        return this.shadePolygons.get(surface);
    }

    getShaders(surface) {
        // only used by 2d renderer
        return [...this.shadePolygonContributors.get(surface).keys()];
    }

    getShadedBy(surface) {
        const shadedBy = [];

        for (const [receiver, contributors] of this.shadePolygonContributors) {
            if (contributors.has(surface)) {
                shadedBy.push(receiver);
            }
        }

        return shadedBy;
    }

    getCastShadows(surface) {
        const shadows = [];

        for (const contributors of this.shadePolygonContributors.values()) {
            const shadow = contributors.get(surface);
            if (shadow) {
                shadows.push(shadow);
            }
        }

        return shadows;
    }

    /**
     * merge and clip all the shade polygons from individual objects onto the surface of the receiver
     */
    _mergeShadePolygons(surface) {
        if (surface.geometry.path.length < 3) {
            // dont project shadows onto line keepouts
            return [];
        }

        const shadows = this.shadePolygonContributors.get(surface).values();
        const shadowPaths = Array.from(shadows).map(({ paths }) => paths);

        let clippedPolygon = unionPathsMulti(flatten(shadowPaths));

        if (surface !== THE_GROUND) {
            clippedPolygon = intersectPathsMulti(clippedPolygon, surface.geometry.path);
        }

        return multiPathOnSurface(surface, clippedPolygon);
    }

    /**
     * update the shadows based on nodes with affected geometries
     * returns an array of all the surfaces with a new shadow on them
     */
    updateSurface(surfacesWithNewGeometry) {
        const dirty = [];
        for (const surface of surfacesWithNewGeometry) {
            dirty.push(...this.castShadows(surface));

            // surface had changed geometry, so the shadows on it may also be different
            if (this.receiveShadows(surface)) {
                dirty.push(surface);
            }
        }

        return uniq(dirty);
    }

    getAllIntersectors(surface) {
        const children = this.designScene.childSurfaces(surface);
        const intersections = this.designScene.intersectingSurfaces(surface);

        return [...children, ...intersections];
    }

    getPotentialShaders(surface) {
        return this.getAllIntersectors(surface).filter((x) => x.castsShadows());
    }

    /**
     * cast a shadow from the caster to the receiver, if there is a cached shadow
     * that has the same projection (e.g. if both shapes moved together), then
     * use the shadow from the cache, otherwise reproject it.
     *
     * you always need to delete the merged shade polygon though, in case the
     * path or height changed on one of the objects (the merge step also reprojects
     * everything to the surface)
     *
     * Note this updates caches in addition to returning the shadow
     */
    castSingleShadow(caster, receiver) {
        const shadowContributors = this.shadePolygonContributors.get(receiver);

        if (caster.castsShadows() && receiver.receivesShadows()) {
            this.shadePolygons.delete(receiver);

            for (const shadow of this.getCastShadows(caster)) {
                if (shadow && shadow.fingerprint.matches(caster, receiver)) {
                    shadowContributors.set(caster, shadow);
                    return shadow;
                }
            }

            const casterHull = caster.getShadingHull();
            const paths = [];
            for (const shadingPath of this.getPathsThatShade(receiver, casterHull)) {
                paths.push(...createShadePolygon(shadingPath, receiver.surfacePlane, this.solarRays));
            }

            if (paths.length) {
                const shadow = {
                    paths,
                    bounds: Bounds.fromMultipath(paths),
                    fingerprint: new ShadowFingerprint(caster, receiver),
                };

                shadowContributors.set(caster, shadow);
                return shadow;
            }
        }

        shadowContributors.delete(caster);
        return null;
    }

    /**
     * remove previously cached shadow polygons contributed by the source surface
     */
    removeSurfaces(surfaces) {
        const affected = new Set();

        for (const targetSurface of surfaces) {
            for (const [surface, contributorMap] of this.shadePolygonContributors) {
                if (contributorMap.delete(targetSurface)) {
                    this.shadePolygons.delete(surface);
                    affected.add(surface);
                }
            }
        }

        for (const targetSurface of surfaces) {
            this.shadePolygons.delete(targetSurface);
            this.shadePolygonContributors.delete(targetSurface);
        }

        return Array.from(affected);
    }

    /**
     * calculate the shadows received on a surface from the rest of the scene
     * return true if the shadows on the surface may have changed
     */
    receiveShadows(receiverSurface) {
        const receiverNode = this.designScene.getNode(receiverSurface);
        const calculated = new Set();

        const hadShadowsBefore = this.hasShadow(receiverSurface);

        // anything that intersects this could shade
        for (const caster of this.getPotentialShaders(receiverSurface)) {
            this.castSingleShadow(caster, receiverSurface);
            calculated.add(caster);
        }

        // anything that shades the parent surface could also shade this surface
        const parentSurface = this.designScene.parentSurface(receiverSurface);
        for (const [caster, shadow] of this.shadePolygonContributors.get(parentSurface)) {
            if (
                caster !== receiverSurface &&
                shadow.bounds.intersects(receiverNode.bounds) &&
                !calculated.has(caster)
            ) {
                this.castSingleShadow(caster, receiverSurface);
                calculated.add(caster);
            }
        }

        // clear out all the surfaces that no longer contribute
        const shadeContributors = this.shadePolygonContributors.get(receiverSurface);
        for (const caster of shadeContributors.keys()) {
            if (!calculated.has(caster)) {
                shadeContributors.delete(caster);
            }
        }

        // NOTE:  technically we should account for self shading when doing
        // keepouts from shade, but we haven't been, and it will create lots of
        // confusion, since only residential users use heavily tilted surfaces,
        // and they don't like the feature
        // if (this.shadesSelf(receiverSurface)) {
        //     calculated.add(receiverSurface);
        // }

        const hasShadowsNow = calculated.size > 0;
        return hadShadowsBefore || hasShadowsNow;
    }

    /**
     * cast shadows from a given surface, and return all the surfaces that have had
     * the shadow on them affected
     *
     * works by calculating the shadows on the immediate parent surface (and any immediately
     * related surfaces on top of or intersecting the parent surface).  if that surface
     * entirely contains the shadow, then you don't need to propagate any further
     */
    castShadows(surface) {
        // short circuit if surface shouldn't be casting shadows.
        if (surface === THE_GROUND) return [];

        const previouslyShadedSurfaces = this.getShadedBy(surface);
        let processed = new Map(); // a map of surfaces that have been processed to whether they are shaded
        let propagate = true;
        let parentSurface = this.designScene.parentSurface(surface);

        while (propagate && parentSurface) {
            const shadowResults = this.calculateShadowCasting(surface, parentSurface, processed);
            processed = shadowResults.processed;
            propagate = !shadowResults.contained;

            parentSurface = this.designScene.parentSurface(parentSurface);
        }

        const updatedSurfaces = [];
        for (const [receiver, isShaded] of processed) {
            if (isShaded) {
                updatedSurfaces.push(receiver);
            }
        }

        // delete any shadows that this surface did contribute to, but doesn't anymore
        for (const prevReceiver of previouslyShadedSurfaces) {
            if (!processed.get(prevReceiver)) {
                // will be false if it was processed but with no shadow or
                // undefined if not processed at all (so still no shadow)
                this.shadePolygonContributors.get(prevReceiver).delete(surface);
                this.shadePolygons.delete(prevReceiver);
                updatedSurfaces.push(prevReceiver);
            }
        }

        return updatedSurfaces;
    }

    shadesSelf(surface, solarRays = this.solarRays) {
        if (!surface.castsShadows() || surface === THE_GROUND) {
            return false;
        }

        let planeNormal = surface.surfacePlane.normal;
        if (planeNormal.dot(DOWN) > 0) {
            planeNormal = planeNormal.scale(-1);
        }

        for (const ray of solarRays) {
            if (planeNormal.cosTheta(ray) > 0) {
                const path = surface.surfacePath3d();
                const paths = simplifyPaths([path]);
                const shadow = {
                    paths,
                    bounds: Bounds.fromMultipath(paths),
                    fingerprint: new ShadowFingerprint(surface, surface),
                };

                this.shadePolygonContributors.get(surface).set(surface, shadow);
                this.shadePolygons.delete(surface);
                return true;
            }
        }

        return false;
    }

    /**
     * cast shadows and propagate to any related surfaces
     *
     * return a tuple:
     *     processed: a map of all the receivers that have been analyzed to whether they are shaded or not
     *     contained: was the shadow contained entirely by the receiver surface
     * note: this mutates processed in addition to returning it
     */
    calculateShadowCasting(caster, receiver, processed = new Map()) {
        const shadow = this.castSingleShadow(caster, receiver);
        const hasShadow = shadow !== null;
        processed.set(receiver, hasShadow);

        if (hasShadow) {
            // other receivers that intersect the parent so might also be shaded by the caster
            const siblingReceivers = this.getAllIntersectors(receiver);
            for (const siblingReceiver of siblingReceivers) {
                if (processed.has(siblingReceiver)) {
                    continue;
                }

                const node = this.designScene.getNode(siblingReceiver);
                if (node.bounds.intersects(shadow.bounds)) {
                    this.calculateShadowCasting(caster, siblingReceiver, processed);
                }
            }
        }

        return {
            processed,
            contained:
                receiver === THE_GROUND || shadow === null || pathContainsMulti(receiver.surfacePath3d(), shadow.paths),
        };
    }

    /**
     * get all the paths on the caster that are above the surface of the receiver
     *
     * if the path is in the intersection cache this has already been calculated
     */
    getPathsThatShade(receiver, caster) {
        return calculatePathAbove(receiver, caster);
    }
}

function createShadePolygon(casterPath, receiverPlane, solarRays) {
    const pathOnBase = receiverPlane.pathFromXYs(casterPath);

    const shadeFragments = [pathOnBase];
    const shadowPaths = [];

    for (const ray of solarRays) {
        const shadowPath = projectPathDown(casterPath, receiverPlane, ray);
        if (!shadowPath) {
            // if at any point shading fails, early exit
            // Note: this only works because we're making the assumption that
            // solar rays always have a downward component to them, and we're
            // tracking intersections for only shapes above the target plane,
            // so if any point on the path intersects the plane, they all will
            return [];
        }
        shadowPaths.push(shadowPath);
        shadeFragments.push(...getShadeQuads(pathOnBase, shadowPath));
    }

    // smooth out shading across multiple rays by adding the corner paths in
    shadeFragments.push(...getCornerTriangles(pathOnBase, shadowPaths));

    return unionPathsMulti(shadeFragments);
}

/**
 * project a path onto a plane, return null if any of the points aren't projected
 * onto the target plane downard
 */
function projectPathDown(path, plane, ray) {
    const rtn = [];

    for (const pt of path) {
        let projectPt = plane.projectPoint(pt, ray, DOWN);

        if (!projectPt) {
            if (plane.pointFromXY(pt).z < pt.z) {
                // the ray doesn't intersect going down, so shadow is 'infinite'
                projectPt = plane.pointFromXY(ray.scale(1e7, 1e7, 0));
            } else {
                return null;
            }
        }

        rtn.push(projectPt);
    }

    return rtn;
}

/**
 * return an array of quadrangles that can be unioned into a shadow projection
 */
function getShadeQuads(basePath, projectedPath) {
    const shadeQuads = [];

    const length = basePath.length;
    for (let i = 1; i < length; i++) {
        // iterate through every edge of the polygon and project the face into a quad on the surface
        const shadowSegment = [projectedPath[i - 1], projectedPath[i], basePath[i], basePath[i - 1]];
        shadeQuads.push(correctOrientation(shadowSegment));
    }

    const shadowSegment = [projectedPath[length - 1], projectedPath[0], basePath[0], basePath[length - 1]];
    shadeQuads.push(correctOrientation(shadowSegment));

    return shadeQuads;
}

/**
 * return triangles that will interpolate the shadow from one shade projection to the next
 *
 * requires that the paths are ordered according to the sun rays
 */
function getCornerTriangles(basePath, [firstPath, ...projectedPaths]) {
    const length = basePath.length;
    const shadeTriangles = [];

    let lastPath = firstPath;
    for (const projectedPath of projectedPaths) {
        for (let i = 0; i < length; i++) {
            shadeTriangles.push(correctOrientation([basePath[i], lastPath[i], projectedPath[i]]));
        }

        lastPath = projectedPath;
    }

    return shadeTriangles;
}

function calculateSolarRays(location, startTime, endTime, timeZone, stepSize = 30) {
    const startTimeUTC = utcDate(startTime, timeZone).getTime();
    const endTimeUTC = utcDate(endTime, timeZone).getTime();
    const steps = Math.floor((endTimeUTC - startTimeUTC) / 1000 / 60 / stepSize);

    const solarRays = [];

    for (let i = 0; i <= steps; i++) {
        const shadowTime = new Date(startTimeUTC + (i / (steps || 1)) * (endTimeUTC - startTimeUTC));
        const { apparentElevation, azimuth } = calculateSolarAngle(shadowTime, location);

        if (apparentElevation && apparentElevation > 0) {
            solarRays.push(Vector.createRay(Math.max(apparentElevation, MIN_SOLAR_ELEVATION), azimuth));
        }
    }

    return solarRays;
}

/**
 * helper to track if the shadow project is still the same as it was before
 * between two surfaces, checks if plane orientations and distance apart still identical, and
 * that the caster path did not change at all
 */
class ShadowFingerprint {
    constructor(caster, receiver) {
        this.path = caster.geometry.path;
        this.casterNormal = caster.surfacePlane.normal;
        this.receiverNormal = receiver.surfacePlane.normal;
        this.planeDelta = caster.surfacePlane.constant - receiver.surfacePlane.constant;
    }

    matches(caster, receiver) {
        return (
            caster.geometry.path === this.path &&
            Math.abs(this.planeDelta - (caster.surfacePlane.constant - receiver.surfacePlane.constant)) < 1e-4 &&
            this.casterNormal.approx(caster.surfacePlane.normal) &&
            this.receiverNormal.approx(receiver.surfacePlane.normal)
        );
    }
}
