import { differencePaths } from 'helioscope/app/utilities/geometry';

export function multiPathOnSurface(surface, multiPath, zEpsilon = 0) {
    const rtn = [];

    for (const path of multiPath) {
        const pathOnSurface = [];
        for (const pt of path) {
            const projected = surface.pointOnSurface(pt);
            projected.z += zEpsilon;

            pathOnSurface.push(projected);
        }
        rtn.push(pathOnSurface);
    }

    return rtn;
}

/**
 * return a path for the intersector surface that is above the plane of the
 * intersectee surface
 *
 * match surfaces within an epsilon of tolerance, because you can get numerical
 * precision issues, particularly when comparing new data with data parsed from
 * the server
 */
export function calculatePathAbove(belowSurface, aboveSurface, epsilon = 1e-5) {
    const belowPlane = belowSurface.surfacePlane;
    const abovePlane = aboveSurface.surfacePlane;
    const path = aboveSurface.surfacePath3d();

    // use a threshold for parallel, due to numerical accuracy
    const { point, direction } = belowPlane.intersect(abovePlane, 1e-7);

    if (!point) {
        const belowOriginZ = -belowPlane.constant / belowPlane.normal.z;
        const aboveOriginZ = -abovePlane.constant / abovePlane.normal.z;

        if (belowOriginZ < aboveOriginZ - epsilon) {
            return [path];
        } else {
            return [];
        }
    }

    const belowVec = direction.rotateZ(-90).scale(1e5, 1e5, 0);

    if (belowPlane.pointFromXY(belowVec).z < abovePlane.pointFromXY(belowVec).z) {
        belowVec.scaleSelf(-1);
    }

    const alongRay = point.add(direction.scale(1e5, 1e5, 0));
    const behindRay = point.add(direction.scale(-1e5, -1e5, 0));
    const alongBelow = alongRay.add(belowVec);
    const behindBelow = behindRay.add(belowVec);

    const belowBox = [alongRay, behindRay, behindBelow, alongBelow];

    // project a multipath onto the relevant plane, add a small vertical delta to ensure that these
    // overlaps can always be projected onto the intersectee for use in creating shadows
    // this needs to be relatively large to help deal wtih clipper imprecision
    return multiPathOnSurface(aboveSurface, differencePaths(path, [belowBox]), 1e-2);
}
