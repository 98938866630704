import { $http } from 'helioscope/app/utilities/ng';
import { Parcel } from './Parcel';

export class ParcelsDataFetcher {
    constructor(location) {
        this.location = location;
        this.parcels = [];
        this.loading = false;
        this.noParcelsFound = false;
    }

    getApiUrl() {
        const { latitude, longitude } = this.location;
        return `/api/parcels?latitude=${latitude}&longitude=${longitude}`;
    }

    async fetchParcelsData() {
        this.loading = true;
        this.noParcelsFound = false;

        try {
            const response = await $http.get(this.getApiUrl());

            if (response.data.length > 0) {
                this.parcels = response.data.map((parcel) => new Parcel(parcel));
                return;
            }

            this.noParcelsFound = true;
        } catch (error) {
            throw new Error('Failed to fetch parcels data');
        } finally {
            this.loading = false;
        }
    }
}
