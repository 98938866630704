import { assert } from 'helioscope/app/utilities/helpers';

export class TreeNode {
    constructor(name, children = []) {
        this.name = name;
        this.children = children;
    }

    addChild(child) {
        assert(child instanceof TreeNode, `child is not instance of TreeNode. child: ${child}`);
        this.children.push(child);
    }

    isLeaf() {
        return this.children.length === 0;
    }

    hasOneChild() {
        return this.children.length === 1;
    }
}

/*
If there are multiple leaf children with the same name, collapse them into
a single node i.e.:
a
  b
  c
  b
=>
a
  b x 2
  c
*/
export function collapseLeafNodes(node) {
    if (node.isLeaf()) {
        return node;
    }
    const nonLeafs = [];
    let leafs = [];
    for (const child of node.children) {
        if (child.isLeaf()) {
            leafs.push(child);
        } else {
            nonLeafs.push(collapseLeafNodes(child));
        }
    }
    if (leafs.length === 0) {
        return node;
    }
    leafs = collapseByName(leafs);
    node.children = leafs;
    return node;
}

/*
Collapses nodes with just one child i.e. given tree with 2 nodes:
a
 b
collapses into a single node:
a -> b
*/
export function collapseLinear(node) {
    if (node.isLeaf()) {
        return;
    }
    while (node.hasOneChild()) {
        const child = node.children[0];
        node.name = `${node.name} -> ${child.name}`;
        node.children = child.children;
    }
    for (const child of node.children) {
        collapseLinear(child);
    }
}

export function compactTree(tree) {
    tree = collapseLeafNodes(tree);
    collapseLinear(tree);
    // after collapsing we might expose new opportunites for collapsing leaf nodes
    return collapseLeafNodes(tree);
}

function dumpGenericTreeRec(tree, level) {
    const indent = _.repeat('  ', level);
    const lines = [`${indent}${tree.name}`];
    for (const child of tree.children) {
        const childLines = dumpGenericTreeRec(child, level + 1);
        lines.push(...childLines);
    }
    return lines;
}

export function dumpGenericTree(tree, compact) {
    if (compact) {
        tree = compactTree(tree);
    }
    const lines = dumpGenericTreeRec(tree, 0);
    console.log(lines.join('\n'));
}

/* component tree debug dumping */
function componentTreeToTreeNode(node) {
    const typ = node.component_type;
    const treeNode = new TreeNode(typ);
    // module is a terminal node with no children
    if (typ === 'module') {
        return treeNode;
    }
    const children = node.getChildren();
    for (const child of children) {
        const treeChild = componentTreeToTreeNode(child);
        treeNode.addChild(treeChild);
    }
    return treeNode;
}

// if there are repeated nodes with the same name, replace them with a
// single node with name "${count} x ${name}"
function collapseByName(nodes) {
    const nameToCount = {};
    for (const node of nodes) {
        const prevCount = nameToCount[node.name] || 0;
        nameToCount[node.name] = prevCount + 1;
    }
    // all names are unique, no changes
    if (nameToCount.length === nodes.length) {
        return nodes;
    }
    for (let i = 0; i < nodes.length; i++) {
        const name = nodes[i].name;
        if (name in nameToCount) {
            const count = nameToCount[name];
            if (name.includes(' ')) {
                nodes[i].name = `(${name}) x ${count}`;
            } else {
                nodes[i].name = `${name} x ${count}`;
            }
            nodes = _.filter(nodes, (node) => node.name !== name);
        }
    }
    return nodes;
}

function buildComponentTree(root) {
    if (!Array.isArray(root)) {
        return componentTreeToTreeNode(root);
    }
    if (root.length === 1) {
        return componentTreeToTreeNode(root[0]);
    }
    const children = root.map((child) => componentTreeToTreeNode(child));
    return new TreeNode('fake root', children);
}

export function dumpComponentTree(root) {
    const tree = buildComponentTree(root);
    dumpGenericTree(tree, true);
}

/* sld tree debug dumping */

function getSldNodeChildren(node) {
    return node.inputs.map((input) => input.source);
}

function sldNodeName(node) {
    const name = node.typeName;
    if (name === 'string') {
        return 'PVArray';
    }
    return name.replace(/\r?\n|\r/g, '.');
}

function sldTreeToTreeNode(node) {
    const name = sldNodeName(node);
    const treeNode = new TreeNode(name);
    const children = getSldNodeChildren(node);
    for (const child of children) {
        const treeChild = sldTreeToTreeNode(child);
        treeNode.addChild(treeChild);
    }
    return treeNode;
}

export function dumpSldTree(sldTree) {
    const tree = sldTreeToTreeNode(sldTree);
    dumpGenericTree(tree, true);
}
