import { Messager } from 'helioscope/app/utilities/ng';
import { BulkEntityChange } from './persistence/BulkEntityChange';
import { FieldSegment } from './field_segment';
import { Keepout } from './keepout';
import { EntityPremade } from './premade';

class BulkActionsMixin {
    changeConfig(resource, dispatcher) {
        const selectionSize = resource.length;

        const updateEntity = (entity, bulkEntityChange, undo = false) => {
            bulkEntityChange.applyEntityChanges(entity, undo);
            if (entity instanceof FieldSegment) {
                dispatcher.designManager.updateSurfaceGeometry(entity, { updateLayout: true });
            }
            if (entity instanceof Keepout) {
                dispatcher.designManager.updateSurfaceGeometry(entity);
            }
            if (entity instanceof EntityPremade) {
                dispatcher.designManager.updatePremadeGeometry(entity);
            }
        };

        return {
            delete: {
                text: `Remove group with ${selectionSize} objects`,
                preflight: (bulkEntityChange) => {
                    dispatcher.designManager.removeEntities(bulkEntityChange.entitiesArray);
                },
                // eslint-disable-next-line no-unused-vars
                onSuccess: (bulkEntityChange) => {
                    dispatcher.deselectEntity();
                    Messager.success(`Successfully deleted ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof FieldSegment) {
                            dispatcher.publish('entityFieldSegmentsChanged', { error: entity });
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.publish('entityKeepoutsChanged', { error: entity });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.publish('entityPremadesChanged', { error: entity });
                        }
                    });

                    Messager.error(`Error when deleting ${selectionSize} objects`);
                },
            },
            create: {
                text: `Create group with ${selectionSize} objects`,
                preflight: (bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        delete entity[entity._relationConfig.id];
                        if (entity instanceof FieldSegment) {
                            dispatcher.designManager.updateSurfaceGeometry(entity, {
                                updateLayout: true,
                                excludeFromUpdate: bulkEntityChange.entitiesArray,
                            });
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.designManager.updateSurfaceGeometry(entity, {
                                excludeFromUpdate: bulkEntityChange.entitiesArray,
                            });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.designManager.updatePremadeGeometry(entity, {
                                excludeFromUpdate: bulkEntityChange.entitiesArray,
                            });
                        }
                        dispatcher.renderer.renderEntity(entity);
                    });
                },
                onSuccess: (bulkEntityChange) => {
                    dispatcher.deselectEntity();
                    dispatcher.selectEntity(bulkEntityChange.entitiesArray, {}, true);

                    Messager.success(`Successfully created ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    dispatcher.designManager.removeEntities(bulkEntityChange.entitiesArray);
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof Keepout) {
                            dispatcher.publish('entityKeepoutsChanged', { error: entity });
                        }
                    });
                    Messager.error(`Error when creating ${selectionSize} objects`);
                },
            },
            update: {
                text: `Update group with ${selectionSize} objects`,
                preflight: (bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        updateEntity(entity, bulkEntityChange);
                    });
                    dispatcher.renderUpdater.updateArray();
                },
                onSuccess: (bulkEntityChange) => {
                    dispatcher.publish('bulkEntityChanged', { updated: bulkEntityChange });
                    Messager.success(`Successfully updated ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        updateEntity(entity, bulkEntityChange, true);
                    });
                    Messager.error(`Error when updating ${selectionSize} objects`);
                },
            },
        };
    }

    create(dispatcher, entities) {
        const bulkEntityChange = new BulkEntityChange(entities);
        const createdEntities = dispatcher.stateHandler.createObject(
            bulkEntityChange,
            this.changeConfig(bulkEntityChange, dispatcher),
        );
        return createdEntities;
    }

    delete(dispatcher, entities) {
        const bulkEntityChange = new BulkEntityChange(entities);
        dispatcher.stateHandler.deleteObject(bulkEntityChange, this.changeConfig(bulkEntityChange, dispatcher));
    }

    getStates(entities, propertyChanges) {
        const newState = new Map();
        const previousState = new Map();

        for (const entity of entities) {
            newState.set(entity, { ...propertyChanges });
            const currentProperties = {};
            // eslint-disable-next-line no-return-assign
            Object.keys(propertyChanges).forEach((key) => (currentProperties[key] = entity[key]));
            previousState.set(entity, currentProperties);
        }
        return { newState, previousState };
    }

    update(dispatcher, entities, propertyChanges, states = {}) {
        let newState;
        let previousState;

        if (states.newState && states.previousState) {
            ({ newState, previousState } = states);
        } else {
            ({ newState, previousState } = this.getStates(entities, propertyChanges));
        }

        const change = new BulkEntityChange(entities, newState, previousState);
        const undoChange = new BulkEntityChange(entities, previousState, newState);

        dispatcher.stateHandler.updateObject(change, undoChange, this.changeConfig(change, dispatcher));
    }

    /**
     * Schedules Bulk Update without adding to undo stack
     *
     * @param {Object} dispatcher - The dispatcher object.
     * @param {Array} entities - The entities to be updated.
     */
    scheduleBulkEntityUpdate(dispatcher, entities) {
        const change = new BulkEntityChange(entities);
        dispatcher.stateHandler.scheduleBulkUpdate(change, this.changeConfig(change, dispatcher));
    }
}

export const bulkActions = new BulkActionsMixin();
