export class CursorConfig {
    static DEFAULT = 'auto';
    static GRAB = 'grab';
    static GRABBING = 'grabbing';
    static MOVE = 'move';
    static PASTE = 'crosshair';
    // Custom duplicate cursor
    static DUPLICATE = `url(${require('helioscope/images/duplicate-cursor.svg')}), auto`;
    // Custom rotate cursor
    static ROTATE = `url(${require('helioscope/images/rotate-cursor.svg')}), auto`;
}

// These are SVG paths without the <svg> metadata tags. These are used for
// dynamically-rotated cursors, so that the paths can be grouped and rotated.
export class CursorSvgPaths {
    static MOVE = {
        // viewBox attribute needed because otherwise the cursor isn't
        // centered within its view box and it gets cut off when rotated
        dimensions: { height: 30, width: 30, viewBox: '-8 -8 32 32' },
        paths: `
            <path d="m9.97732426 4.98866213h.99773244v.99773243h-.99773244zm.99773244
                5.98639457h-.99773244v-.99773244h.99773244zm-4.98866214-4.98866214h-.99773243v-.99773243h.99773243zm0
                4.98866214h-.99773243v-.99773244h.99773243zm1.99546485-10.96208618-7.96689342 7.96988662
                5.21015873 5.20916096 2.75673469 2.7567347 7.96589569-7.96689339z" fill="#fff"/>
            <path d="m11.982 7.001v-1.825l2.551 2.803-2.552 2.802.001-1.804h-3.015v2.998h1.801l-2.799
                2.57-2.8-2.571 1.823.001v-2.998h-3.002l.001 1.804-2.566-2.802
                2.565-2.803v1.825h2.994v-3.011h-1.804l2.802-2.564 2.801 2.565-1.803-.001v3.011z" fill="#000"/>
        `,
    };

    // Similar to ew-resize cursor at https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
    static RESIZE = {
        dimensions: { width: 16, height: 16 },
        paths: `
            <path d="M0 7.999V8.004L5.997 14L5.998 10.001H7.997H10.017V14.001L15.997 8L10.017
                2.001L10.018 6.02L7.997 6.022H5.997L5.998 2L0 7.999ZM1.411 8.002L4.998 4.414L4.997
                7.001H8.497H11.018V4.416L14.583 8.002L11.019 11.587L11.018 9.002H8.497L4.998
                9.001L4.997 11.587L1.411 8.002Z" fill="white"/>
            <path d="M8.49689 9.00131H11.0179V11.5873L14.5829 8.00131L11.0179
                4.41631V7.02131H8.49689H4.99689V4.41431L1.41089 8.00131L4.99689 11.5873V9.00031L8.49689
                9.00131Z" fill="black"/>
        `,
    };
    static SCALE = {
        dimensions: { width: 24, height: 24 },
        paths: `
            <path d="m14.743 12.087-4.073 4.068 2.83 2.828-8.473-.013-.013-8.47 2.84 2.842 4.076-4.068
                1.414-1.414L10.5 5.017h8.486v8.484l-2.83-2.827z" fill="#fff"/>
            <path d="m13.683 11.733-4.427 4.424 1.828 1.828-5.056-.016-.014-5.054 1.842 1.841 4.428-4.422
                2.474-2.474-1.844-1.844h5.073v5.071l-1.83-1.828z" fill="#000"/>
        `,
    };
    static ROTATE = {
        // viewBox attribute needed because otherwise the cursor isn't
        // centered within its view box and it gets cut off when rotated
        dimensions: { width: 24, height: 24, viewBox: '-4 -4 30 30' },
        paths: `
            <path d="M.59 6.134 7.215.27l-.269 4.17a14.7 14.7 0 0 1 8.37 4.165 14.52 14.52 0 0 1 4.13
                8.135l3.981-.258-5.865 6.624-6.613-5.819 4.36-.28a10.6 10.6 0 0 0-2.908-5.485A10.8 10.8
                0 0 0 6.68 8.57l-.269 4.178z" fill="#fff"/>
            <path d="m16.227 17.986.263-.018A11.62 11.62 0 0 0 5.714 7.412l-.004.056-.174
                2.714-3.483-3.958 3.964-3.51-.172 2.655a13.66 13.66 0 0 1 12.687 12.468l2.449-.158-3.51
                3.964-3.958-3.483z" fill="#000"/>
        `,
    };
}
