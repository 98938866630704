import * as analytics from 'helioscope/app/utilities/analytics';
import { getBetaBillingURL } from 'helioscope/app/utilities/url';

const logo = require('helioscope/app/designer/paywall/static/lidar_paywall.png');

const lidarDataSetUrl =
    'https://help-center.helioscope.com/hc/en-us/articles/12096753757587-Where-is-LIDAR-available-in-HelioScope-';

const lidarModalDetails = {
    imageUrl: logo,
    heading: 'Level Up Your Designs With LIDAR',
};

export const mod = angular.module('helioscope.designer.paywall', []);

mod.factory('paywallDlg', ($modal) => {
    const opts = {
        templateUrl: require('helioscope/app/designer/paywall/partials/paywallModal.html'),
        windowClass: 'paywall-modal',
        controller: 'PaywallModalCtrl',
        resolve: {
            user: ['Authenticator', (Authenticator) => Authenticator.user()],
        },
    };

    return () => $modal.open(opts);
});

mod.controller('PaywallModalCtrl', ($scope, $modalInstance, user) => {
    $scope.imageUrl = lidarModalDetails.imageUrl;
    $scope.heading = lidarModalDetails.heading;
    $scope.lidarDataSetUrl = lidarDataSetUrl;

    $scope.close = () => $modalInstance.close();

    $scope.trackPrimaryCtaClick = () => {
        analytics.track('paywall.primaryCta', {
            modal_name: 'lidar',
            cta_action: 'contact_sales',
        });
    };

    $scope.trackLearnMoreCtaClick = () => {
        analytics.track('paywall.learnMore', {
            modal_name: 'lidar',
        });
    };

    const interval = user && user.subscription && user.subscription.plan_type ? user.subscription.plan_type : 'month';
    $scope.betaBillingURL = (product) =>
        getBetaBillingURL(user, '', {
            product,
            dialog: user.subscription ? 'change' : 'initial',
            interval,
            referrer: 'upsell_dialog',
        });
});
