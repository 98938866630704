import * as THREE from 'three';

import { clampRadians } from 'helioscope/app/utilities/geometry';

import { containerPoint } from './InteractHelpers';
import { CursorConfig } from '../designer/CursorConfig';

export class DragActionCameraPan {
    constructor(dRenderer, event) {
        this.dRenderer = dRenderer;

        const pt = containerPoint(this.dRenderer, event);
        this.downPoint = pt;
    }

    dragMouseMove(event) {
        this.dRenderer.setCursorStyle(CursorConfig.GRABBING);

        const pt = containerPoint(this.dRenderer, event);

        const delta = new THREE.Vector2().subVectors(pt, this.downPoint);
        const cameraDeltaX = new THREE.Vector3()
            .copy(this.dRenderer.cameraPanRight)
            .multiplyScalar(delta.x * this.dRenderer.viewportScale);
        const cameraDeltaY = new THREE.Vector3()
            .copy(this.dRenderer.cameraPanForward)
            .multiplyScalar(delta.y * this.dRenderer.viewportScale);
        const cameraDelta = new THREE.Vector3().addVectors(cameraDeltaY, cameraDeltaX);
        this.dRenderer.cameraCenter.add(cameraDelta);
        this.dRenderer.recomputePrimaryCamera();
        this.dRenderer.dirtyFrame();

        this.downPoint = pt;
    }

    dragMouseUp() {
        this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);
    }

    dragMouseOut() {}
}

export class DragActionCameraRotate {
    constructor(dRenderer, event) {
        this.dRenderer = dRenderer;

        const pt = new THREE.Vector2(event.clientX, event.clientY);
        this.downPoint = pt;
    }

    dragMouseMove(event) {
        this.dRenderer.setCursorStyle(CursorConfig.GRABBING);

        const rotateSpeed = 0.0025;
        if (this.downPoint) {
            const pt = new THREE.Vector2(event.clientX, event.clientY);
            const delta = new THREE.Vector2().subVectors(pt, this.downPoint);
            this.dRenderer.cameraPhi = clampRadians(this.dRenderer.cameraPhi - delta.x * rotateSpeed * Math.PI);
            this.dRenderer.cameraTheta = _.clamp(
                this.dRenderer.cameraTheta + delta.y * rotateSpeed * Math.PI,
                -Math.PI * 0.5,
                0,
            );
            this.dRenderer.recomputePrimaryCamera();
            this.dRenderer.broadcastCameraSettings();
            this.dRenderer.dirtyFrame();
            this.downPoint = pt;
        }
    }

    dragMouseUp() {
        this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);
    }

    dragMouseOut() {}
}
