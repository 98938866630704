import * as THREE from 'three';
import Logger from 'js-logger';
import * as GLH from '../GLHelpers';
import { PrimitiveMeshFill, PrimitiveMeshStroke } from '../Primitives';
import { WidgetImageOverlayCollection } from './WidgetImageOverlay';
import { getFourPoints, getRotationHandlePoint, MAX_OVERLAY_ORDER } from './OverlayHelpers';
import { BRIGHT_GREEN } from '../RendererOptions';

const logger = Logger.get('RenderableImageOverlay');

export class RenderableImageOverlay {
    constructor(renderer, overlay) {
        this.overlay = overlay;
        this.renderer = renderer;
        this.primitives = [];
        this.scene = null;
        this.resourceKey = `_RenderableImageOverlay_${overlay.overlay_id}`; // for cache retrieval
        this.renderPromise = this.loadRenderableTexture();
    }

    ready() {
        return this.renderPromise;
    }

    loadRenderableTexture() {
        return this.renderer
            .loadTexture(null, this.overlay.file.get_url)
            .then((texture) => {
                this.renderer.graphicResourceCache[this.resourceKey] = texture;
                this.texture = texture;
                this.texture.image = GLH.resizeImageIfNeeded(this.texture.image);
            })
            .catch((err) => {
                logger.warn(err);
            });
    }

    clearRenderable() {
        this.clearWidgets();

        this.primitives.forEach((prim) => {
            prim.clearInstances();
        });

        if (this.scene) {
            GLH.removeInstance(this.scene);
            this.scene = null;
        }
        this.primitives = [];
        this.renderer.dirtyFrame();
    }

    renderRenderable(renderOptions = {}) {
        this.ready()
            .then(() => {
                this.internalRenderRenderable(renderOptions);
            })
            .catch((err) => {
                logger.warn(err);
            });
    }

    internalRenderRenderable(renderOptions) {
        this.clearRenderable();

        if (!this.overlay.visible || !this.overlay.overlay_parameter) return;

        this.scene = new THREE.Scene();
        this.renderer.overlayLayer.add(this.scene);

        const texture = this.renderer.graphicResourceCache[this.resourceKey];
        if (!texture) return;

        let opacity = this.overlay.overlay_parameter.opacity;
        if (renderOptions.limitOpacity) {
            // Limit the opacity to no more than 50%
            // This helps the user to see both the image overlay and what is underneath it
            opacity = Math.min(opacity, 0.5);
        }

        const renderOrder = renderOptions.renderOnTop ? MAX_OVERLAY_ORDER : this.overlay.order;

        this.primitives.push(
            this.renderer.renderPrimitive(PrimitiveMeshFill, {
                fillColor: '#ffffff',
                geometry: GLH.makeQuadTexturedGeometryFromPoints(getFourPoints(this.overlay)),
                material: this.renderer.inlineShaderMaterial('vertexShaderTexture', 'fragmentShaderTexture'),
                opacity,
                renderOrder,
                scene: this.scene,
                selectionData: null,
                texture,
            }),
        );

        if (renderOptions.renderEditWidgets) {
            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                    geometry: GLH.makeWireGeometry(GLH.pathToPolygonPoints(getFourPoints(this.overlay))),
                    material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                    renderOrder,
                    scene: this.scene,
                    strokeColor: BRIGHT_GREEN,
                    strokeOpacity: 1.0,
                    strokeWeight: 1.0,
                }),
            );

            // Calculate rotationLinePoints, the points to draw the line between the rotation handle
            // and the east side edge handle.
            const fourPoints = getFourPoints(this.overlay);
            const rotationHandlePoint = getRotationHandlePoint(fourPoints);
            const sideMidPoint = new THREE.Vector3(
                (fourPoints[1].x + fourPoints[2].x) / 2.0,
                (fourPoints[1].y + fourPoints[2].y) / 2.0,
            );
            const rotationLinePoints = [rotationHandlePoint, sideMidPoint];

            // Draw the BRIGHT_GREEN line connecting the rotation handle to the east side edge handle.
            this.primitives.push(
                this.renderer.renderPrimitive(PrimitiveMeshStroke, {
                    geometry: GLH.makeWireGeometry(GLH.pathToLinePoints(rotationLinePoints)),
                    material: this.renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                    renderOrder,
                    scene: this.scene,
                    strokeColor: BRIGHT_GREEN,
                    strokeOpacity: 1.0,
                    strokeWeight: 1.0,
                }),
            );

            this.createWidgets();
        }

        this.scene.visible = this.overlay.visible;
    }

    createWidgets() {
        if (this.widgetCollection) return;
        const dragHandles = true;
        const edgeHandles = true;
        const vertexHandles = true;
        const rotationHandle = true;
        const options = { dragHandles, edgeHandles, vertexHandles, rotationHandle };

        this.widgetCollection = new WidgetImageOverlayCollection(this.renderer, this.overlay);
        this.widgetCollection.createWidget(options);
    }

    clearWidgets() {
        if (this.widgetCollection) {
            this.widgetCollection.clearWidgets();
            this.widgetCollection = null;
        }
    }
}
