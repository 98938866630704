import _ from 'lodash';

import { Vector, intersectPathsMultiOpen } from 'helioscope/app/utilities/geometry';
import * as THREE from 'three';

class OverlayPoint {
    constructor(point, ps) {
        this.point = new Vector(point.x, point.y);
        this.boundarySurface = ps;
        this.hidden = false;
    }
}

function computeOverlaySegment3D(pt1, pt2, surfaces) {
    // clipper numerical artifacts can produce small differences from original position
    const epsilon = 0.00001;

    if (pt1.subtract(pt2).lengthSq() < epsilon) {
        // for some reason the wiring engine can produce 0 length segments
        const singlePoint = computeOverlayPoint(pt1, surfaces);
        return [singlePoint, singlePoint.getCopy()];
    }

    const unsorted = [];
    unsorted.push(new OverlayPoint(pt1, null));
    unsorted.push(new OverlayPoint(pt2, null));

    for (const ps of surfaces) {
        if (ps.geometry.path.length < 3) {
            continue;
        }

        const clipped = intersectPathsMultiOpen([pt1, pt2], ps.geometry.path);
        for (const path of clipped) {
            for (const pt of path) {
                unsorted.push(new OverlayPoint(pt, ps));
            }
        }
    }

    _.each(unsorted, (pt) => {
        pt.distSq = pt.point.subtract(pt1).lengthSq();
    });
    const sorted = _.sortBy(unsorted, (pt) => pt.distSq);

    let enteredSurfaces = [];
    let boundarySurfacesPost = [];
    let boundarySurfacesPre = [];
    let currPt = sorted[0];
    let lastDistSq = currPt.distSq;
    const combined = [];

    const finalizePoint = () => {
        const otherPt = computeOverlayPoint(currPt.point, enteredSurfaces);
        const prePt = computeOverlayPoint(currPt.point, boundarySurfacesPre);
        const postPt = computeOverlayPoint(currPt.point, boundarySurfacesPost);
        if (otherPt.z > prePt.z && otherPt.z > postPt.z) {
            currPt.hidden = true;
        }
        if (otherPt.z > prePt.z) {
            prePt.z = otherPt.z;
        }
        if (otherPt.z > postPt.z) {
            postPt.z = otherPt.z;
        }

        enteredSurfaces = enteredSurfaces.concat(boundarySurfacesPost);
        boundarySurfacesPost = [];
        boundarySurfacesPre = [];

        currPt.postPt = postPt;
        currPt.prePt = prePt;
    };

    combined.push(currPt);
    for (const pt of sorted) {
        if (Math.abs(pt.distSq - lastDistSq) > epsilon) {
            finalizePoint();
            if (currPt.hidden) {
                combined.pop();
            }
            currPt = pt;
            lastDistSq = pt.distSq;
            combined.push(currPt);
        }

        if (pt.boundarySurface) {
            const removedSurfaces = _.remove(enteredSurfaces, (i) => i === pt.boundarySurface);
            if (removedSurfaces.length) {
                boundarySurfacesPre.push(pt.boundarySurface);
            } else {
                boundarySurfacesPost.push(pt.boundarySurface);
            }
        }
    }

    finalizePoint();

    const final = [];
    final.push(combined[0].postPt);
    for (let i = 1; i < combined.length - 1; i++) {
        final.push(combined[i].prePt);
        final.push(combined[i].postPt);
    }
    final.push(combined[combined.length - 1].prePt);
    return final;
}

export function computeOverlayPoint(point, surfaces) {
    const highestPt = new Vector(point.x, point.y, 0);
    for (const ps of surfaces) {
        const surfacePt = ps.pointOnSurface(point);
        if (surfacePt.z > highestPt.z) {
            highestPt.z = surfacePt.z;
        }
    }

    return highestPt;
}

export function computeOverlayPath3D(path, design) {
    // sometimes there's a z component in the path for some reason
    const path2d = _.map(path, (pt) => new Vector(pt.x, pt.y));
    const surfaces = design.physicalSurfaces();
    return path2d.slice(0, -1).map((point, i) => computeOverlaySegment3D(point, path2d[i + 1], surfaces));
}

export function getCenterPoint(fourPoints) {
    const p1 = new THREE.Vector3(fourPoints[0].x, fourPoints[0].y, 0);
    const p2 = new THREE.Vector3(fourPoints[2].x, fourPoints[2].y, 0);
    return new THREE.Vector3().addVectors(p1, p2).multiplyScalar(0.5);
}

export function applyRotationToFourPoints(fourPoints, angle) {
    const centerPoint = getCenterPoint(fourPoints);
    const transformMatrix = new THREE.Matrix3();
    transformMatrix.set(Math.cos(angle), -1 * Math.sin(angle), 0, Math.sin(angle), Math.cos(angle), 0, 0, 0, 0);

    for (const point of fourPoints) {
        point.sub(centerPoint).applyMatrix3(transformMatrix).add(centerPoint);
    }
    return fourPoints;
}
