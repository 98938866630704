import { RelationalBase, relationship } from 'helioscope/app/relational';
import { Vector } from 'helioscope/app/utilities/geometry';
import * as IOHelpers from 'helioscope/app/utilities/io';

import { createImageURL, getKMLData, isImageExtension, isKMLExtension, openZip } from './helpers';
import { KMLDocument } from './KMLDocument';

export class Overlay extends RelationalBase {
    static relationName = 'Overlay';

    isEmptyKML() {
        return !!this.kml && !this.kml.hasContent();
    }

    getEmptyKMLMessage() {
        return `${this.filename} contains no supported content (ground overlays or placemarks).`;
    }

    get opacity() {
        if (isKMLExtension(this.file.extension)) {
            return null;
        }

        return this.overlay_parameter.opacity != null ? this.overlay_parameter.opacity : 1;
    }

    set opacity(newValue) {
        this.overlay_parameter = {
            ...this.overlay_parameter,
            opacity: Number(newValue),
        };
    }

    async loadDocument() {
        const url = this.file ? this.file.get_url : this.download_url;
        const response = await fetch(url);

        if (this.file.extension === 'kmz') {
            const blob = await response.blob();
            const entries = await openZip(blob);
            let kmlEntry;
            const imageEntries = [];

            for (const entry of entries) {
                const fileExt = IOHelpers.getExtension(entry.filename);
                if (fileExt === 'kml') {
                    kmlEntry = entry;
                } else if (isImageExtension(fileExt)) {
                    imageEntries.push(entry);
                }
            }

            if (kmlEntry) {
                const data = await getKMLData(kmlEntry);
                this.kml = new KMLDocument(data);
            }

            if (imageEntries.length > 0) {
                if (!this.imageDict) {
                    this.imageDict = {};
                }

                const imagePromises = await Promise.all(
                    imageEntries.map(async (imageEntry) => createImageURL(imageEntry)),
                );
                imagePromises.forEach((image, index) => {
                    this.imageDict[imageEntries[index].filename] = image;
                });
            }
        } else if (this.file.extension === 'kml') {
            const data = await response.text();
            this.kml = new KMLDocument(data, { baseUrl: url });
        }
    }

    zoomPath() {
        if (this.overlay_parameter) {
            return _.map(this.overlay_parameter.quad_points, (i) => new Vector(i.x, i.y, 0.0));
        } else if (this.kml) {
            const doc = this.kml;

            const bounds = doc.getBounds();
            const projectCenter = this.project.location;

            return [
                projectCenter.gridOffsets({ latitude: bounds.maxY, longitude: bounds.maxX }),
                projectCenter.gridOffsets({ latitude: bounds.minY, longitude: bounds.minX }),
            ];
        } else {
            return [];
        }
    }

    toString() {
        return this.filename;
    }
}

Overlay.configureRelationships({
    project: relationship('Project', { backref: 'overlays' }),
});

Overlay.createEndpoint(
    '/api/overlays/:overlay_id',
    { overlay_id: '@overlay_id' },
    {
        update: { method: 'PUT', isArray: false },
        updateOrder: {
            method: 'POST',
            isArray: true,
            url: '/api/overlays/:overlay_id/update_order',
        },
    },
);
