/**
 * Functions for saving asynchronously from user actions.
 *
 */
import _ from 'lodash';
import Logger from 'js-logger';

import { Messager, $rootScope, $q } from 'helioscope/app/utilities/ng';
import { BulkEntityChange } from 'helioscope/app/designer/persistence/BulkEntityChange';

const logger = Logger.get('persistence');

/**
 * object to schedule Resource saves in the background with a basic
 * debounce rate to limit and combine multiple server calls
 *
 * TODO: need to send current timestamp with requests and have server
 * ensure only latest requests are processed
 */
export class DeferredResourceSaver {
    static PERSISTENCE_DEBOUNCE = 5000; // how long to debounce save calls for each resource

    constructor(obj) {
        this.resource = obj;
        this.resolved = false;
        this.deferred = $q.defer();
    }

    _debouncedSave = _.debounce(() => this.save(), DeferredResourceSaver.PERSISTENCE_DEBOUNCE);

    /**
     * stop the save from even happening
     */
    cancel() {
        this._debouncedSave.cancel();

        if (this.resource.$cancel) {
            // if the re source had an outstanding save request that
            // hasn't returned yet, cancel it before initiating a
            // new save, this cancels requests on the javascript
            // side, but doesn't guarantee the server won't
            // commit the change

            // we can make resources automatically cancel previously scheduled requests if desired
            // (see $cancel code in relational/resource.js);
            this.resource.$cancel('cancel');
        }

        // reject with cancel so other processes know this was deliberate
        this.deferred.reject('cancel');
        return this.deferred.promise;
    }

    /**
     * cancel debounce and save immediately
     */
    async save() {
        if (this.resolved) {
            // no issues if already resolved
            return this.deferred.promise;
        } else if (this.requestPromise && this.resource.$cancel) {
            // a request has already been sent, cancel it before sending a new one
            this.resource.$cancel('superceded');
        }

        // otherwise, no request, so cancel the debouncer and trigger a save
        this._debouncedSave.cancel();

        this.inFlight = true;
        this.requestPromise = this.resource
            .$update()
            .then((resource) => {
                logger.log(`Saved ${resource} successfully`);
                this.resolved = true;
                this.deferred.resolve(resource);
            })
            .catch((err) => {
                if (_.get(err, 'config.timeout.$$state.status') === 1) {
                    // this is a janky way to check that the request was manually canceled
                    logger.info('Cancelled', _.get(err, 'config.timeout.$$state.value'));

                    // don't need to do anything to the original promise, since it will now
                    // be resolved by the new request
                    return;
                }

                logger.warn(`Error saving ${this.resource}`);
                this.deferred.reject(err);
            })
            .finally(() => {
                this.inFlight = false;
            });

        return this.deferred.promise;
    }

    trigger() {
        this._debouncedSave();
        return this.deferred.promise;
    }
}

/**
 * wrap the resource saver in an object to handle the save state of groups of objects
 */
export class ResourceUpdateQueue {
    constructor() {
        this.updateQueue = new Map();
        this.createQueue = new Map();
        this.deleteQueue = new Map();

        this.resourcesWithErrors = new Set();

        this.bulkQueue = [];
    }

    /**
     * everything in the queue, and return a promise for when the saves are complete
     * @return {Promise} promise that resolves when all saves are complete
     */
    flush() {
        logger.info(`Flushing ${this.updateQueue.size} saves`);

        const promises = [...this.updateQueue.keys()]
            .map((resource) => this.schedule(resource, { now: true }))
            .concat([...this.resourcesWithErrors].map((resource) => this.schedule(resource, { now: true })))
            .concat([...this.createQueue.values()])
            .concat([...this.deleteQueue.values()]);

        return $q.all(promises);
    }

    /**
     * schedule a save for a given resource, if it already exists in the queue, trigger
     * the countdown
     * @param  {RelationalResource} resource the resource instance to persist
     * @return {Promise}            promsie that resolves when the resource is persisted
     */
    async schedule(resource, { now = false } = {}) {
        let resourceSaver = this.updateQueue.get(resource);
        const newResource = resourceSaver === undefined;

        if (newResource) {
            resourceSaver = new DeferredResourceSaver(resource);
            this.updateQueue.set(resource, resourceSaver);
        }

        try {
            await (now ? resourceSaver.save() : resourceSaver.trigger());
            this.resourcesWithErrors.delete(resource);
        } catch (err) {
            if (err !== 'cancel') {
                this.resourcesWithErrors.add(resource);
                if (newResource) {
                    Messager.error(`Warning: had a problem saving ${resource}`, { delay: 10000 });
                }
                logger.error('Could not save a resource', resource, err);
            } else {
                logger.log('Got a save rejection (cancel)', err);
            }
        }

        if (newResource) {
            // only schedule a delete on successful save the first
            // time a save is triggered
            this.updateQueue.delete(resource);

            // await/async hates the digest cycle
            $rootScope.$apply();
        }

        return resource;
    }

    /**
     * Makes an API call based on the specified action.
     *
     * @param {BulkEntityChange|any} resource - The resource object on which the action will be performed.
     * @param {string} action - The action to be performed. Can be 'create', 'delete', or 'update'.
     * @returns {Promise} - A promise that resolves when the action is completed.
     */
    makeAPICall(resource, action) {
        let promise;
        switch (action) {
            case 'create':
                promise = resource.$save();
                break;
            case 'delete':
                promise = resource.$delete();
                break;
            case 'update':
                promise = resource.$update();
                break;
            default:
                throw new Error(`Invalid action: ${action}`);
        }
        return promise;
    }

    /**
     * Schedules a bulk operation for a given resource and action.
     * Ensures that bulk operations  are executed sequentially.
     *
     * @param {BulkEntityChange} resource - The bulk object on which the action is to be performed.
     * @param {string} action - The action to be performed on the resource (create, delete, update).
     * @returns {Promise} - A promise that resolves when the operation is complete.
     */
    async scheduleBulkOperation(resource, action) {
        const bulkQueueElement = { action, resource, promise: null };
        this.bulkQueue.push(bulkQueueElement);

        while (this.bulkQueue[0].resource !== resource) {
            // eslint-disable-next-line no-await-in-loop
            await this.bulkQueue[0].promise;
        }

        // Any side effects for the bulk operation should be done here, before the API call

        const promise = this.makeAPICall(resource, action)
            .catch((err) => {
                Messager.error(`Could not ${action} ${resource}`, { delay: 10000 });
                logger.error(`Could not bulk ${action}`, resource, err);
                throw err;
            })
            .finally(() => {
                this.bulkQueue.shift();
            });

        bulkQueueElement.promise = promise;
        return promise;
    }

    /**
     * Retrieves the queue associated with the specified action.
     *
     * @param {string} action - The action type ('create', 'delete', 'update').
     * @returns {Map} The queue corresponding to the given action.
     */
    getQueue(action) {
        switch (action) {
            case 'create':
                return this.createQueue;
            case 'delete':
                return this.deleteQueue;
            case 'update':
                return this.updateQueue;
            default:
                throw new Error(`Invalid action: ${action}`);
        }
    }
    /**
     * Removes a resource from the appropriate queue based on the action and resource type.
     *
     * @param {Object} resource - The resource to be removed from the queue.
     * @param {string} action - The action type (create or delete) which determines the queue to remove from.
     */
    removeFromQueue(resource, action) {
        this.getQueue(action).delete(resource);
    }

    /**
     * Adds the ongoing promise to the appropriate queue based on the resource type.
     *
     * @param {Object} resource - The resource to be added to the queue.
     * @param {Promise} promise - The ongoing promise associated with the resource.
     * @param {string} action - The action type (create or delete) which determines the queue
     * to which the promise will be added.
     */
    addPromiseToQueue(resource, promise, action) {
        this.getQueue(action).set(resource, promise);
    }

    /**
     * Checks for duplicate entries in the specified action queue.
     *
     * @param {Object} resource - The resource to check for duplicates.
     * @param {string} action - The action type (create or delete) to check the queue for.
     * @returns {Object|null} - Returns the duplicate resource if found, otherwise null.
     */
    checkForDuplicates(resource, action) {
        const duplicate = this.getQueue(action).get(resource);
        if (duplicate) {
            logger.warn(`Already had ${action} scheduled`);
        }
        return duplicate;
    }

    /**
     * Creates a new resource (a single entity or a bulk entity change) using different queue mechanisms for each.
     * If it is a single entity, it checks for duplicates and cancels any ongoing updates.
     *
     * @async
     * @param {Object | BulkEntityChange} resource - The resource (single entity or bulk) to be created.
     * @returns {Promise} - A promise that resolves when the resource is successfully created,
     * or rejects if there is a duplicate.
     */
    async create(resource) {
        if (resource instanceof BulkEntityChange) {
            return this.scheduleBulkOperation(resource, 'create');
        } else {
            const preexistingCreate = this.checkForDuplicates(resource, 'create');
            if (preexistingCreate) {
                return $q.reject('duplicate');
            }
            const preexistingUpdate = this.updateQueue.get(resource);
            if (preexistingUpdate) {
                logger.warn(`Got create on object with outstanding update ${resource}`);
                preexistingUpdate.cancel();
            }
            delete resource[resource._idName];
            const newCreate = this.makeAPICall(resource, 'create').finally(() => {
                this.removeFromQueue(resource, 'create');
            });
            this.addPromiseToQueue(resource, newCreate, 'create');

            return newCreate;
        }
    }

    /**
     * Deletes a given resource (a single entity or a bulk entity change) using a different queue mechanism for each.
     *
     * Cancels any ongoing updates for the deleted resource.
     * Removes the common entitites between this resource and the bulk updates in queue.
     * Checks for duplicates in the delete queue for single entities and rejects the current one if found.
     *
     * @param {Object} resource - The resource to be deleted.
     * @returns {Promise} - A promise that resolves when the resource is deleted or rejects if a duplicate is found.
     */
    async delete(resource) {
        if (resource instanceof BulkEntityChange) {
            return this.scheduleBulkOperation(resource, 'delete');
        } else {
            const saver = this.updateQueue.get(resource);
            if (saver) {
                saver.cancel();
            }
            const preexistingDelete = this.checkForDuplicates(resource, 'delete');
            if (preexistingDelete) {
                return $q.reject('duplicate');
            }
            const newDelete = this.makeAPICall(resource, 'delete').finally(() => {
                this.removeFromQueue(resource, 'delete');
            });
            this.addPromiseToQueue(resource, newDelete, 'delete');

            return newDelete;
        }
    }

    /**
     * Updates a given Bulk Object by adding it to the bulk queue.
     * Unlike create and delete, this method is only called on bulk, and not single updates.
     * For single updates, use schedule method.
     *
     * @param {BulkEntityChange} resource - The resource to be updated.
     * @returns {Promise} - A promise that resolves when the update is complete.
     */
    async update(resource) {
        return this.scheduleBulkOperation(resource, 'update');
    }

    get saving() {
        return this.deleteQueue.size + this.createQueue.size + _.sumBy([...this.updateQueue.values()], 'inFlight');
    }

    get size() {
        return this.updateQueue.size + this.deleteQueue.size + this.createQueue.size;
    }

    get errors() {
        return this.resourcesWithErrors.size;
    }
}
