import Logger from 'js-logger';
import _ from 'lodash';

import * as analytics from 'helioscope/app/utilities/analytics';

import { user } from 'helioscope/app/users';
import { defaultAzimuth } from 'helioscope/app/utilities/geometry';

import { FieldCombiner, FieldInverter, AcPanel } from '../components';
import { FieldSegment } from '../field_segment/FieldSegment';
import { Keepout } from '../keepout/Keepout';
import { EntityPremade } from '../premade/Premade';
import { WiringZone } from '../wiring_zone/WiringZone';
import { Overlay } from '../overlays';
import { Design } from '../Design';
import { PremadePointSurface } from '../field_segment';
import { bulkActions } from '../BulkActionsMixin';

const logger = Logger.get('design_manager/callbacks');

export class DesignManager {
    constructor(dispatcher) {
        this.dispatcher = dispatcher;
        this.renderUpdater = dispatcher.renderUpdater;
        this.designScene = dispatcher.design.designScene();
    }

    getStateHandlerCallbacks() {
        return [
            [FieldCombiner, this.combinerCallback.bind(this)],
            [AcPanel, this.combinerCallback.bind(this)],
            [FieldInverter, this.inverterCallback.bind(this)],
            [FieldSegment, this.fieldSegmentCallback.bind(this)],
            [WiringZone, this.wiringZoneCallback.bind(this)],
            [Keepout, this.keepoutCallback.bind(this)],
            [Overlay, this.overlayCallback.bind(this)],
            [EntityPremade, this.premadeCallback.bind(this)],
            [Design, () => null],
        ];
    }

    updateFieldComponentLocation(fieldComponent, newLocation) {
        const { dispatcher } = this;
        const design = dispatcher.design;
        fieldComponent.setLocation(newLocation);
        this.dispatcher.designDirty = true;

        // this means that the tree is rerendered unnecessarily after every change
        dispatcher.renderer.renderWiringTree(design);

        const wiringZone = design.wiring_zones.find((wz) => wz.wiring_zone_id === fieldComponent.wiring_zone_id);
        wiringZone.generateWiringSummary();
    }

    combinerCallback(fieldCombiner, propertyPath, newVal, _oldVal) {
        if (propertyPath === 'location') {
            this.updateFieldComponentLocation(fieldCombiner, newVal);
        }
    }

    inverterCallback(fieldInverter, propertyPath, newVal, _oldVal) {
        if (propertyPath !== 'location') return;

        this.updateFieldComponentLocation(fieldInverter, newVal);
    }

    updateDesignShading() {
        this.designScene.shadowManager.initialize();

        const allEntities = [
            ...this.dispatcher.design.entity_premades.map((premade) => premade.proxyStackableSurface()),
            ...this.dispatcher.design.physicalSurfaces(),
        ];

        this.renderUpdater.scheduleUpdates(this.designScene.updateSurfaces(allEntities));
    }

    saveAffectedGeometries(affectedResources, excludeFromUpdate = []) {
        const getEntity = (resource) => (resource instanceof PremadePointSurface ? resource.entity : resource);

        const affectedEntities = affectedResources.map(getEntity);
        const affectedEntitiesSet = new Set(affectedEntities);

        const entitiesToExclude = excludeFromUpdate.map(getEntity);
        const entitiesToExcludeSet = new Set(entitiesToExclude);

        const entititesToUpdate = affectedEntitiesSet.difference(entitiesToExcludeSet);

        if (entititesToUpdate.size !== 0) {
            bulkActions.scheduleBulkEntityUpdate(this.dispatcher, [...entititesToUpdate]);
        }
    }

    getAffectedGeometries(updatedSurfaces) {
        const updatedGeometries = [];
        updatedSurfaces.forEach(([physicalSurface, opts]) => {
            if (opts.updateGeometry) {
                updatedGeometries.push(physicalSurface);
            }
        });
        return updatedGeometries;
    }

    removeEntities(entities, excludeFromUpdate = []) {
        const surfaces = entities.map((entity) =>
            entity instanceof EntityPremade ? entity.proxyStackableSurface() : entity,
        );
        const updatedSurfaces = this.designScene.updateSurfaces(surfaces, { remove: true });
        this.renderUpdater.scheduleUpdates(updatedSurfaces);

        for (const entity of entities) {
            this.removeEntityRender(entity);
        }

        const affectedGeometries = this.getAffectedGeometries(updatedSurfaces);

        this.saveAffectedGeometries(affectedGeometries, [...excludeFromUpdate, ...entities]);
    }

    removeEntityRender(entity) {
        this.dispatcher.highlightEntity(entity, false);

        if (entity instanceof EntityPremade) {
            const proxyPoint = entity.proxyStackableSurface();
            this.dispatcher.renderUpdater.dirtyEntities.delete(proxyPoint);
        } else {
            this.dispatcher.renderUpdater.dirtyEntities.delete(entity);
        }

        this.dispatcher.renderer.clearEntity(entity);

        if (entity instanceof EntityPremade || entity instanceof Keepout) {
            this.dispatcher.addOrRemoveEntityToSelection(entity, true);
        }

        if (entity instanceof FieldSegment) {
            this.dispatcher.renderUpdater.updateWiringDeferred();
        }
    }

    /**
     * schedule and propagate changes to a surface
     */
    updateSurfaceGeometry(surface, { updateLayout = false, excludeFromUpdate = [] } = {}) {
        // update the geometry immediately, this is fast and provides good feedback
        const affectedGeometries = [];

        for (const affected of this.designScene.updateGeometries([surface])) {
            const updateOptions = {
                updateGeometry: true,
                updateScene: true,
            };

            // only pass updateLayout to field segments
            if (affected instanceof FieldSegment) {
                Object.assign(updateOptions, { updateLayout });
            }

            this.renderUpdater.updateSurfaceDeferred(affected, updateOptions);

            if (affected !== surface) {
                affectedGeometries.push(affected);
            }
        }
        this.saveAffectedGeometries(affectedGeometries, excludeFromUpdate);
        // propagate any changes in shadows/layout after a delay
        this.renderUpdater.updateSurfaceDeferred(surface, {
            updateScene: true,
        });
    }

    updatePremadeGeometry(premade) {
        this.updateSurfaceGeometry(premade.proxyStackableSurface());
    }

    fieldSegmentCallback(fieldSegment, propertyPath, newVal, oldVal) {
        logger.debug(`FieldSegment property changed ${propertyPath}`);

        // TODO: MT: should this be moved to linkedPropertyCallback to make undo redo work properly?
        if (
            _.includes(
                [
                    'azimuth',
                    'rack_type',
                    'orientation',
                    'independent_tilt_enabled',
                    'independent_tilt_surface_azimuth',
                    'independent_tilt_surface_tilt',
                ],
                propertyPath,
            )
        ) {
            // if the core layout rules are unchanged, maintain the same project start
            //
            // Note, this behavior means that the undo/redo stuck is not fully bidirectional, since
            // this data will be lost
            delete fieldSegment.geometry.layout_start;
        }

        if (propertyPath === 'geometry.removed_module_locations') {
            let fastPath = false;
            if (Math.abs(newVal.length - oldVal.length) === 1) {
                const delta =
                    newVal.length > oldVal.length
                        ? _.differenceWith(newVal, oldVal, (a, b) => a.equals(b))
                        : _.differenceWith(oldVal, newVal, (a, b) => a.equals(b));

                if (delta.length === 1) {
                    fastPath = this.applyFastModuleToggle(fieldSegment, delta[0]);
                }
            }
            if (fastPath) {
                return;
            }
        }

        // any factors that can affect stacking or scene geometry
        if (
            _.includes(
                [
                    'geometry.path',
                    'reference_height',
                    'azimuth',
                    'tilt',
                    'geometry.height_reference',
                    'geometry',
                    'rack_type',
                    'shadow_caster',
                    'inner_setback',
                    'independent_tilt_enabled',
                    'independent_tilt_surface_tilt',
                    'independent_tilt_surface_azimuth',
                ],
                propertyPath,
            )
        ) {
            this.updateSurfaceGeometry(fieldSegment, { updateLayout: true });
        } else {
            // everything else should only affect module layout
            this.renderUpdater.updateSurfaceDeferred(fieldSegment, {
                updateLayout: true,
            });
        }

        if (
            propertyPath === 'independent_tilt_surface_azimuth' &&
            oldVal === defaultAzimuth(fieldSegment.design) &&
            !_.isNil(newVal)
        ) {
            analytics.track('designer.independent_tilt_surface_azimuth_changed', {
                project_id: fieldSegment.design.project_id,
                design_id: fieldSegment.design.design_id,
                team_id: user.team_id,
            });
        }

        if (propertyPath === 'independent_tilt_surface_tilt' && oldVal === 0 && !_.isNil(newVal)) {
            analytics.track('designer.independent_tilt_surface_tilt_changed', {
                project_id: fieldSegment.design.project_id,
                design_id: fieldSegment.design.design_id,
                team_id: user.team_id,
            });
        }
    }

    keepoutCallback(keepout, propertyPath, _newVal, _oldVal) {
        if (
            _.includes(
                ['geometry.path', 'reference_height', 'geometry.height_reference', 'geometry', 'outer_setback'],
                propertyPath,
            )
        ) {
            this.updateSurfaceGeometry(keepout);
        }
    }

    premadeCallback(premade, propertyPath, _newVal, _oldVal) {
        if (_.includes(propertyPath, 'geometry') || _.includes(propertyPath, 'proxyProperties')) {
            this.updatePremadeGeometry(premade);
        }
    }

    wiringZoneCallback(_wiringZone, _propertyPath, _newVal, _oldVal) {
        this.renderUpdater.updateWiringDeferred();
    }

    static findRemovedArg = { findRemoved: true };

    applyFastModuleToggle(fieldSegment, location) {
        const dispatcher = this.dispatcher;
        const layoutEngine = fieldSegment.layoutEngine();
        const racking = fieldSegment.getRacks();
        const surfaceLocation = fieldSegment.pointOnSurface(location);
        const rtn = layoutEngine.findModuleIntersections(racking, surfaceLocation, DesignManager.findRemovedArg);
        if (!rtn) {
            return false;
        }

        const { frame, module } = rtn;
        if (module.removed) {
            frame.restoreModule(module);
        } else {
            frame.removeModule(module);
        }

        fieldSegment.moduleFill(racking); // update the cache;
        dispatcher.renderer.renderModules(fieldSegment);
        dispatcher.renderUpdater.updateWiringDeferred();

        return true;
    }

    overlayCallback(overlay) {
        const isSelected = this.dispatcher.selectedEntity === overlay;
        this.dispatcher.renderer.renderOverlay({
            overlay,
            options: {
                renderEditWidgets: isSelected,
                renderOnTop: isSelected,
            },
            markDirty: true,
        });
    }
}
