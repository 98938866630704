import { FLError } from 'helioscope/app/utilities/helpers';

export class RendererError extends FLError {}

const QUEABLE_RENDERER_METHODS = [
    'clearDesign',
    'clearEntity',
    'clearSubpolygons',
    'highlightFieldSegment',
    'highlightKeepout',
    'fitProjectView',
    'renderDesign',
    'renderFieldSegment',
    'renderKeepout',
    'renderModules',
    'renderSurface',
    'renderWiringTree',
    'setRenderOverrides',
    'zoom',
];

export class BufferRenderer {
    constructor() {
        this._calls = [];
    }

    lidarPoints() {
        return [];
    }

    lidarAvailable() {
        return false;
    }

    saveCameraState() {
        return {};
    }

    replayCalls(renderer) {
        for (const [methodName, args] of this._calls) {
            renderer[methodName](...args);
        }

        this._calls.length = 0;
    }

    mapInput() {
        throw new RendererError('Renderer not ready initialized, cant use functions that return a value');
    }

    overrideRightClick() {
        throw new RendererError('Renderer not ready initialized, cant use functions that return a value');
    }
}

for (const methodName of QUEABLE_RENDERER_METHODS) {
    BufferRenderer.prototype[methodName] = function enqueue(...args) {
        this._calls.push([methodName, args]);
    };
}
