import { $log, $rootScope, Messager } from 'helioscope/app/utilities/ng';
import { defaultAzimuth, Vector, clampRadians } from 'helioscope/app/utilities/geometry';
import { calculateSolarAngle } from 'helioscope/app/utilities/solar';
import * as SolarTime from 'helioscope/app/utilities/solar/solar_time';

import { FieldSegment } from './FieldSegment';

function calculateInitialTilt(fieldSegment, profile) {
    if (profile.tilt_strategy === 'fixed') {
        return profile.tilt;
    } else if (profile.tilt_strategy === 'latitude') {
        return Math.abs(fieldSegment.design.project.location.latitude);
    }

    const lat = Math.abs(fieldSegment.design.project.location.latitude);

    if (lat <= 25) {
        return lat * 0.87;
    } else if (lat <= 50) {
        return lat * 0.76 + 3.1;
    }
    return 45;
}

export function setInitialRowSpacing(fieldSegment, profile) {
    let startTime;
    let endTime;
    switch (profile.row_spacing_strategy) {
        case 'fixed':
            fieldSegment.row_spacing = profile.row_spacing;
            break;
        case 'span_to_rise':
            fieldSegment.spanToRise = profile.span_to_rise;
            break;
        case 'gcr':
            fieldSegment.groundCoverageRatio = profile.gcr;
            break;
        case 'shading':
            ({ startTime, endTime } = getDefaultShadeTimes(fieldSegment.design, profile));

            fieldSegment.row_spacing = timeOfDayRowSpacingV2(fieldSegment, startTime, endTime);
            break;
        default:
            $log.warn('Unknown row spacing strategy', profile.row_spacing_strategy);
            fieldSegment.row_spacing = FieldSegment.DefaultPropertyValues.row_spacing;
    }
}

/**
 * Calculates the maximum distance between rows of panels needed
 * to minimize row-to-row shading losses within a given time window.
 *
 * @param {FieldSegment} fieldSegment - The field segment to calculate the row spacing for
 * @param {Date} startTime - The start time of the time window
 * @param {Date} endTime - The end time of the time window
 * @param {Date} baseDate - The base date to use for the time window. defaults to the start time
 * @returns {number} The maximum distance between rows of panels needed to minimize row-to-row shading losses
 */
export function timeOfDayRowSpacingV2(fieldSegment, startTime, endTime, baseDate = startTime) {
    if (fieldSegment.rack_type !== 'rack') {
        return FieldSegment.DefaultPropertyValues.row_spacing;
    }

    const solarStartTime = SolarTime.combineDateTime(baseDate, startTime);
    const solarEndTime = SolarTime.combineDateTime(baseDate, endTime);
    const timeHourDiff = new Date(solarEndTime - solarStartTime).getUTCHours();

    if (solarStartTime > solarEndTime) {
        Messager.error('Start time must be earlier than end time');
        return FieldSegment.DefaultPropertyValues.row_spacing;
    }
    if (timeHourDiff > 8) {
        Messager.error('Time of day row spacing is only available for a time window of 8 hours or less');
        return FieldSegment.DefaultPropertyValues.row_spacing;
    }

    const location = fieldSegment.design.project.location;
    const timeZone = fieldSegment.design.project.time_zone_offset;

    const { transformMatrix } = fieldSegment.layoutEngine().rackingSpaceTransforms();
    const originT = transformMatrix.transform(new Vector(0, 0, 0));

    const frameVector = fieldSegment.layoutEngine().frameVector();
    const frameHeight = frameVector.z;
    const rowLengths = [];

    // iterate over time range, incrementing by 1 hour
    for (let current = solarStartTime; current <= solarEndTime; current.setHours(current.getHours() + 1)) {
        const solarTime = SolarTime.utcDate(current, timeZone);
        const solarAngle = calculateSolarAngle(solarTime, location);
        const sunVector = Vector.createRay(solarAngle.apparentElevation, solarAngle.azimuth);

        // transform the sun ray vector to the field segment racking space
        const sunVectorT = transformMatrix.transform(sunVector).subtract(originT);
        // The elevation angle 𝜃 can be calculated using the z component of the sun vector
        // the negative sine of the elevation angle corresponds to the down projection of the sun ray
        const elevationAngleT = Math.asin(-sunVectorT.z);
        // The cosine of the azimuth angle corresponds to the northward component,
        // and the sine corresponds to the eastward component
        const azimuthAngleT = Math.atan2(-sunVectorT.x, -sunVectorT.y);
        // modules in racking space are oriented with the azimuth directly down
        // the y-axis, therefore the transformed module azimuth is 180°
        const azimuthCorrection = clampRadians(Math.PI - azimuthAngleT);
        // row spacing is the length of the shadow cast by the frame
        // onto the ground, adjusted for the difference in azimuth angles between
        // the sun and the module.
        const rowSpacing = (frameHeight * Math.cos(azimuthCorrection)) / Math.tan(elevationAngleT);

        if (_.isFinite(rowSpacing)) {
            rowLengths.push(Math.max(rowSpacing, 0));
        }
    }

    return _.max(rowLengths) || 0;
}

export function getDefaultShadeTimes(design, profile) {
    const startTime = SolarTime.userLocalDate(_.get(profile, 'row_spacing_start_time', design.shade_keepouts_start));
    const endTime = SolarTime.userLocalDate(_.get(profile, 'row_spacing_end_time', design.shade_keepouts_end));

    return { startTime, endTime };
}

export function makeFieldSegment(design, profile) {
    const fieldSegments = design.field_segments;
    const lastFS = _.clone(fieldSegments[fieldSegments.length - 1]);

    // defaults then preferences then most recent
    const settings = _.merge(
        {
            shadow_caster: true,
        },
        profile,
        {
            // set azimuth here so that the lastFS overwrites it
            azimuth: defaultAzimuth(design),
            geometry: { path: [] },
        },
        lastFS,
        {
            design_id: design.design_id,
            description: `Field Segment ${fieldSegments.length + 1}`,
            wiring_priority: fieldSegments.length,
            max_size: 0, // don't copy the max size when creating a new field segment,
            wiring_zone_id: (lastFS && lastFS.wiring_zone_id) || design.wiring_zones[0].wiring_zone_id,
            field_segment_id: null, // must be null to overrate any previous field segment ids when using _.merge
            data: {},
        },
    );

    // clear racking -- merge won't do what we want
    settings.racking = {};

    const fieldSegment = new FieldSegment(settings);
    if (lastFS === undefined && profile && profile.module) {
        fieldSegment.tilt = calculateInitialTilt(fieldSegment, profile);
        setInitialRowSpacing(fieldSegment, profile);
    }

    if (
        lastFS &&
        lastFS.rack_type === 'rack' &&
        lastFS.independent_tilt_enabled &&
        !$rootScope.user().hasIndependentTiltAccess
    ) {
        fieldSegment.independent_tilt_enabled = null;
        fieldSegment.independent_tilt_surface_azimuth = null;
        fieldSegment.independent_tilt_surface_tilt = null;
    }

    return fieldSegment;
}

export function notUsingDefaultCharacterization(fieldSegment) {
    return (
        fieldSegment.module_characterization &&
        fieldSegment.module_characterization_id !==
            fieldSegment.module_characterization.module.defaultCharacterizationId()
    );
}
